<div class="wrapper-statistic-content">
    <div class="app-container-nav has-border-bottom" style="min-width: unset;">
        <button mat-button class="options-dashboard" [matMenuTriggerFor]="menuPeriod"
            aria-label="Example icon-button with a menu">
            <span>{{store?.periodText ? store?.periodText : 'ជ្រើសរើសការបរិច្ឆេទ' }}</span>
            <i class="material-icons">arrow_drop_down</i>
        </button>
        <mat-menu #menuPeriod="matMenu">
            <div class="options-lags" (click)="$event.stopPropagation()">
                <!-- <app-report-filter-period reportType='passenger-listing' [fromDate]="store?.fromDateNum"
                    [toDate]="store?.toDateNum">
                </app-report-filter-period> -->
                <app-statistic-filter-period [fromDate]="store?.fromDateNum"
                [toDate]="store?.toDateNum">
            </app-statistic-filter-period>
            </div>
        </mat-menu>

        <button mat-button class="options-dashboard" [matMenuTriggerFor]="menuInspection"
            aria-label="Example icon-button with a menu">
            <span>{{store?.selectedPost?store?.selectedPost?.name:'ជ្រើសរើសប៉ុស្តិ៍'}}</span>
            <i class="material-icons">arrow_drop_down</i>
        </button>
        <mat-menu #menuInspection="matMenu">
            <div class="options-lags" (click)="$event.stopPropagation();">
                <app-statistic-filter-post></app-statistic-filter-post>
                <!-- <app-report-filter-post reportType='passenger-listing'>
                </app-report-filter-post> -->
                
            </div>
        </mat-menu>

        <button mat-button class="options-dashboard" [matMenuTriggerFor]="menuTravel"
            aria-label="Example icon-button with a menu">
            <span>{{store?.selectedTravel?store?.selectedTravel?.text:'ជ្រើសរើសដំណើរចេញចូល'}}</span>
            <i class="material-icons">arrow_drop_down</i>
        </button>
        <mat-menu #menuTravel="matMenu">
            <div class="options-lags" (click)="$event.stopPropagation();">
                <app-statistic-filter-travel-type></app-statistic-filter-travel-type>
                <!-- <app-report-filter-travel-type reportType='passenger-listing'>
                </app-report-filter-travel-type> -->
            </div>
        </mat-menu>

        <div class="flex1"></div>



    </div>
    <ng-container >
        <div class="chart-divider-title">
            <h3>ស្ថិតិអ្នកបើកបរនិងអ្នករួមដំណើរខ្មែរ</h3>
        </div>
        <div class="wrapper-chart">
            <div class="cotent-chart">
                <div class="top-chart" *ngIf="store?.statisticKhmer">
                    <div class="chart">
                        <h4>ស្ថិតិអ្នកដំណើរចូលសរុប</h4>
                        <div class="wrapper-content">
                            <div class="chart-icon">
                                <mat-icon>directions_run</mat-icon>
                            </div>
                            <span class="chart-label">អ្នកដំណើរចូលសរុប</span>
                            <div class="chart-text">
                                <strong>{{store?.statisticKhmer?.statistic?.totalIn}}</strong><span>នាក់</span>
                            </div>
                        </div>
                        <div class="chart-divider"></div>
                        <div class="wrapper-content">
                            <div class="chart-icon">
                                <mat-icon>groups</mat-icon>
                            </div>
                            <span class="chart-label">អ្នកដំណើរចូលសរុបស្រី</span>
                            <div class="chart-text">
                                <strong>{{store?.statisticKhmer?.statistic?.totalInF}}</strong><span>នាក់</span>
                            </div>
                        </div>
                        <div class="chart-divider"></div>
                        <ng-container *ngFor="let item of store?.statisticKhmer?.reportStatisticIn; first as isFirst ; last as isLast">
                            <div class="wrapper-content">
                                <div class="chart-icon">
                                    <mat-icon *ngIf="isFirst">time_to_leave</mat-icon>
                                    <mat-icon *ngIf="isLast">accessible</mat-icon>
                                </div>
                                <span class="chart-label">{{item?.name}}</span>
                                <div class="chart-text">
                                    <strong>{{item?.total}}</strong><span>នាក់</span>
                                </div>
                            </div>
                            <div class="chart-divider"></div>
                        </ng-container>
                       
                    </div>
                    <div class="chart-pie" *ngIf="store?.statisticKhmer">
                        <app-pie-chart [data]="store?.statisticKhmer?.reportStatisticIn"></app-pie-chart>
                    </div>
                </div>
    
                <div class="top-chart">
                    <div class="chart">
                        <h4>ស្ថិតិអ្នកបើកបរនិងអ្នករួមដំណើរ ចូលតាមប៉ុស្តិ៍</h4>
                       
                        <div class="chart-divider"></div>
                    </div>
                    <div class="chart-grouped-vericalbar" *ngIf="store?.statisticKhmer">
                        <app-grouped-vertical-bar-chart [data]="store?.statisticKhmer?.reportTravalIn" ></app-grouped-vertical-bar-chart>
                      
                        
                    </div>
                </div>
                <div class="top-chart">
                    <div class="chart">
                        <h4>ស្ថិតិប្រភេទរថយន្ត  ចូលតាមប៉ុស្តិ៍</h4>
                       
                        <div class="chart-divider"></div>
                    </div>
                    <div class="chart-grouped-vericalbar" *ngIf="store?.statisticKhmer && store?.statisticKhmer?.reportVehicleTypeIn && store?.statisticKhmer?.reportVehicleTypeIn?.length > 0">
                        <app-second-grouped-vertical-bar-chart [secondData]="store?.statisticKhmer?.reportVehicleTypeIn"></app-second-grouped-vertical-bar-chart>
                        
                    </div>
                </div>
                <div class="top-chart">
                    <div class="chart">
                        <h4>ស្ថិតិអ្នកដំណើរចេញសរុប</h4>
                        <div class="wrapper-content">
                            <div class="chart-icon">
                                <mat-icon>directions_run</mat-icon>
                            </div>
                            <span class="chart-label">អ្នកដំណើរចេញសរុប</span>
                            <div class="chart-text">
                                <strong>{{store?.statisticKhmer?.statistic?.totalOut}}</strong><span>នាក់</span>
                            </div>
                        </div>
                        <div class="chart-divider"></div>
                        <div class="wrapper-content">
                            <div class="chart-icon">
                                <mat-icon>groups</mat-icon>
                            </div>
                            <span class="chart-label">អ្នកដំណើរចេញសរុបស្រី</span>
                            <div class="chart-text">
                                <strong>{{store?.statisticKhmer?.statistic?.totalOutF}}</strong><span>នាក់</span>
                            </div>
                        </div>
                        <div class="chart-divider"></div>
                        <ng-container *ngFor="let item of store?.statisticKhmer?.reportStatisticOut; first as isFirst ; last as isLast">
                            <div class="wrapper-content">
                                <div class="chart-icon">
                                    <mat-icon *ngIf="isFirst">time_to_leave</mat-icon>
                                    <mat-icon *ngIf="isLast">accessible</mat-icon>
                                </div>
                                <span class="chart-label">{{item?.name}}</span>
                                <div class="chart-text">
                                    <strong>{{item?.total}}</strong><span>នាក់</span>
                                </div>
                            </div>
                            <div class="chart-divider"></div>
                        </ng-container>
                        
                    </div>
                    <div class="chart-pie" *ngIf="store?.statisticKhmer">
                        <app-pie-chart [data]="store?.statisticKhmer?.reportStatisticOut"></app-pie-chart>
                    </div>
                </div>
    
                <div class="top-chart">
                    <div class="chart">
                        <h4>ស្ថិតិអ្នកបើកបរនិងអ្នករួមដំណើរ ចេញតាមប៉ុស្តិ៍</h4>
                       
                        <div class="chart-divider"></div>
                    </div>
                    <div class="chart-grouped-vericalbar"  *ngIf="store?.statisticKhmer">
                        <app-grouped-vertical-bar-chart [data]="store?.statisticKhmer?.reportTravalOut"></app-grouped-vertical-bar-chart>
                       
                    </div>
                </div>
                <div class="top-chart">
                    <div class="chart">
                        <h4>ស្ថិតិប្រភេទរថយន្ត ចេញតាមប៉ុស្តិ៍</h4>
                       
                        <div class="chart-divider"></div>
                    </div>
                    <div class="chart-grouped-vericalbar" *ngIf="store?.statisticKhmer">
                        
                        <app-second-grouped-vertical-bar-chart [secondData]="store?.statisticKhmer?.reportVehicleTypeOut"></app-second-grouped-vertical-bar-chart>
                        
                    </div>
                </div>
                <div class="top-chart-merge"*ngIf="store?.statisticKhmer">
                    <div class="chart">
                        <h4>ស្ថិតិអ្នកដំណើរចេញចូលសរុប</h4>
                        <div class="wrapper-content">
                            <div class="chart-icon">
                                <mat-icon>local_shipping</mat-icon>
                            </div>
                            <span class="chart-label">អ្នកដំណើរសរុប</span>
                            <div class="chart-text">
                                <strong>{{store?.statisticKhmer?.statistic?.total}}</strong><span>នាក់</span>
                            </div>
                        </div>
                        <div class="chart-divider"></div>
                        <div class="wrapper-content">
                            <div class="chart-icon">
                                <mat-icon>directions_walk</mat-icon>
                            </div>
                            <span class="chart-label">អ្នកដំណើរស្រី</span>
                            <div class="chart-text">
                                <strong>{{store?.statisticKhmer?.statistic?.totalF}}</strong><span>នាក់</span>
                            </div>
                        </div>
                        
                    </div>
                    <div class="chart-pie" *ngIf="store?.statisticKhmer">
                      
                        <app-pie-chart [data]="store?.statisticKhmer?.reportStatistic"></app-pie-chart>
                    </div>
                </div>
            </div>
        </div>
    
        <div class="chart-divider-title">
            <h3>ស្ថិតិអ្នកបើកបរនិងអ្នករួមដំណើរបរទេស</h3>
        </div>
        <div class="wrapper-chart">
            <div class="cotent-chart">
                <div class="top-chart" *ngIf="store?.statisticThai">
                    <div class="chart">
                        <h4>ស្ថិតិអ្នកដំណើរចូលសរុប</h4>
                        <div class="wrapper-content">
                            <div class="chart-icon">
                                <mat-icon>directions_run</mat-icon>
                            </div>
                            <span class="chart-label">អ្នកដំណើរចូលសរុប</span>
                            <div class="chart-text">
                                <strong>{{store?.statisticThai?.statistic?.totalIn}}</strong><span>នាក់</span>
                            </div>
                        </div>
                        <div class="chart-divider"></div>
                        <div class="wrapper-content">
                            <div class="chart-icon">
                                <mat-icon>groups</mat-icon>
                            </div>
                            <span class="chart-label">អ្នកដំណើរចូលសរុបស្រី</span>
                            <div class="chart-text">
                                <strong>{{store?.statisticThai?.statistic?.totalInF}}</strong><span>នាក់</span>
                            </div>
                        </div>
                        <div class="chart-divider"></div>
                        <ng-container *ngFor="let item of store?.statisticThai?.reportStatisticIn; first as isFirst ; last as isLast">
                            <div class="wrapper-content">
                                <div class="chart-icon">
                                    <mat-icon *ngIf="isFirst">time_to_leave</mat-icon>
                                    <mat-icon *ngIf="isLast">accessible</mat-icon>
                                </div>
                                <span class="chart-label">{{item?.name}}</span>
                                <div class="chart-text">
                                    <strong>{{item?.total}}</strong><span>នាក់</span>
                                </div>
                            </div>
                            <div class="chart-divider"></div>
                        </ng-container>
                       
                    </div>
                    <div class="chart-pie" *ngIf="store?.statisticThai">
                        <app-pie-chart [data]="store?.statisticThai?.reportStatisticIn"></app-pie-chart>
                    </div>
                </div>
    
                <div class="top-chart">
                    <div class="chart">
                        <h4>ស្ថិតិអ្នកបើកបរនិងអ្នករួមដំណើរ ចូលតាមប៉ុស្តិ៍</h4>
                       
                        <div class="chart-divider"></div>
                    </div>
                    <div class="chart-grouped-vericalbar" *ngIf="store?.statisticThai">
                        <app-grouped-vertical-bar-chart [data]="store?.statisticThai?.reportTravalIn" ></app-grouped-vertical-bar-chart>
                      
                        
                    </div>
                </div>
                <div class="top-chart">
                    <div class="chart">
                        <h4>ស្ថិតិប្រភេទរថយន្ត  ចូលតាមប៉ុស្តិ៍</h4>
                       
                        <div class="chart-divider"></div>
                    </div>
                    <div class="chart-grouped-vericalbar" *ngIf="store?.statisticThai && store?.statisticThai?.reportVehicleTypeIn && store?.statisticThai?.reportVehicleTypeIn?.length > 0">
                        <app-second-grouped-vertical-bar-chart [secondData]="store?.statisticThai?.reportVehicleTypeIn"></app-second-grouped-vertical-bar-chart>
                        
                    </div>
                </div>
                <div class="top-chart">
                    <div class="chart">
                        <h4>ស្ថិតិអ្នកដំណើរចេញសរុប</h4>
                        <div class="wrapper-content">
                            <div class="chart-icon">
                                <mat-icon>directions_run</mat-icon>
                            </div>
                            <span class="chart-label">អ្នកដំណើរចេញសរុប</span>
                            <div class="chart-text">
                                <strong>{{store?.statisticThai?.statistic?.totalOut}}</strong><span>នាក់</span>
                            </div>
                        </div>
                        <div class="chart-divider"></div>
                        <div class="wrapper-content">
                            <div class="chart-icon">
                                <mat-icon>groups</mat-icon>
                            </div>
                            <span class="chart-label">អ្នកដំណើរចេញសរុបស្រី</span>
                            <div class="chart-text">
                                <strong>{{store?.statisticThai?.statistic?.totalOutF}}</strong><span>នាក់</span>
                            </div>
                        </div>
                        <div class="chart-divider"></div>
                        <ng-container *ngFor="let item of store?.statisticThai?.reportStatisticOut; first as isFirst ; last as isLast">
                            <div class="wrapper-content">
                                <div class="chart-icon">
                                    <mat-icon *ngIf="isFirst">time_to_leave</mat-icon>
                                    <mat-icon *ngIf="isLast">accessible</mat-icon>
                                </div>
                                <span class="chart-label">{{item?.name}}</span>
                                <div class="chart-text">
                                    <strong>{{item?.total}}</strong><span>នាក់</span>
                                </div>
                            </div>
                            <div class="chart-divider"></div>
                        </ng-container>
                       
                    </div>
                    <div class="chart-pie" *ngIf="store?.statisticThai">
                        <app-pie-chart [data]="store?.statisticThai?.reportStatisticOut"></app-pie-chart>
                    </div>
                </div>
    
                <div class="top-chart">
                    <div class="chart">
                        <h4>ស្ថិតិអ្នកបើកបរនិងអ្នករួមដំណើរ ចេញតាមប៉ុស្តិ៍</h4>
                       
                        <div class="chart-divider"></div>
                    </div>
                    <div class="chart-grouped-vericalbar"  *ngIf="store?.statisticThai">
                        <app-grouped-vertical-bar-chart [data]="store?.statisticThai?.reportTravalOut"></app-grouped-vertical-bar-chart>
                       
                    </div>
                </div>
                <div class="top-chart">
                    <div class="chart">
                        <h4>ស្ថិតិប្រភេទរថយន្ត ចេញតាមប៉ុស្តិ៍</h4>
                       
                        <div class="chart-divider"></div>
                    </div>
                    <div class="chart-grouped-vericalbar" *ngIf="store?.statisticThai">
                        
                        <app-second-grouped-vertical-bar-chart [secondData]="store?.statisticThai?.reportVehicleTypeOut"></app-second-grouped-vertical-bar-chart>
                        
                    </div>
                </div>
                <div class="top-chart-merge"*ngIf="store?.statisticThai">
                    <div class="chart">
                        <h4>ស្ថិតិអ្នកដំណើរចេញចូលសរុប</h4>
                        <div class="wrapper-content">
                            <div class="chart-icon">
                                <mat-icon>local_shipping</mat-icon>
                            </div>
                            <span class="chart-label">អ្នកដំណើរសរុប</span>
                            <div class="chart-text">
                                <strong>{{store?.statisticThai?.statistic?.total}}</strong><span>នាក់</span>
                            </div>
                        </div>
                        <div class="chart-divider"></div>
                        <div class="wrapper-content">
                            <div class="chart-icon">
                                <mat-icon>directions_walk</mat-icon>
                            </div>
                            <span class="chart-label">អ្នកដំណើរស្រី</span>
                            <div class="chart-text">
                                <strong>{{store?.statisticThai?.statistic?.totalF}}</strong><span>នាក់</span>
                            </div>
                        </div>
                        
                    </div>
                    <div class="chart-pie" *ngIf="store?.statisticThai">
                      
                        <app-pie-chart [data]="store?.statisticThai?.reportStatistic"></app-pie-chart>
                    </div>
                </div>
            </div>
            
        </div>
        <app-spinner *ngIf="store?.loading"></app-spinner>
    </ng-container>
    
    
</div>