import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { firestore } from 'firebase/app';
import { AbstractControl } from '@angular/forms';
import { startWith, map } from 'rxjs/operators';
import * as moment from 'moment';
import * as firebase from 'firebase/app'

export const months = {
    "01": { key: '01', name: "មករា" },
    "02": { key: '02', name: "កុម្ភៈ" },
    "03": { key: '03', name: "មីនា" },
    "04": { key: '04', name: "មេសា" },
    "05": { key: '05', name: "ឧសភា" },
    "06": { key: '06', name: "មិថុនា" },
    "07": { key: '07', name: "កក្កដា" },
    "08": { key: '08', name: "សីហា" },
    "09": { key: '09', name: "កញ្ញា" },
    "10": { key: '10', name: "តុលា" },
    "11": { key: '11', name: "វិច្ឆិកា" },
    "12": { key: '12', name: "ធ្នូ" },
}

@Injectable({
    providedIn: 'root'
})
export class MappingService {

    constructor() { }


    static toLocalDateString(date: Date) {
        const format = moment(date).localeData().longDateFormat('L');
        return moment(date).format(format);
      }
      
    static toDateKey(date?: Date) {
        if (date) {
            return toNumber(moment(date).format(`YYYYMMDD`))
        }
        return toNumber(moment().format(`YYYYMMDD`))
    }

    static filter(data: Array<any>, field: string, keyword: string) {
        return _.filter(data, [field, keyword])
    }

    static sumCreditAttempted(data: Array<any>) {
        return data.reduce((acc, cur) => acc + Number(cur.credits_attempted), 0)
    }

    static sumCreditCalculator(data: Array<any>) {
        return data.reduce((acc, cur) => acc + Number(cur.credits_calculator), 0)
    }

    static sumTotalPoint(data: Array<any>) {
        return data.reduce((acc, cur) => acc + cur.total, 0)
    }

    static sumCreditEarned(data: Array<any>) {
        return data.reduce((acc, cur) => acc + cur.credits_earned, 0)
    }

    static pushToArrayWithId(data: firestore.QuerySnapshot) {
        const result: any = data.docs.map(m => { return { ...m.data(), docId: m.id } })
        return result;
    }

    static orderBy(data: Array<any>, field: string) {
        return _.orderBy(data, [field])
    }
    static orderByDesc(data: Array<any>, field: string) {
        return _.orderBy(data, [field], ['desc'])
    }

    static uniqOnTop(data: Array<any>, field: string, filedOrderBy: string) {
        const rows = _.orderBy(data, [filedOrderBy], ['desc'])
        return _.uniqBy(rows, field)
    }

    static groupBy(data: Array<any>, field: string, filedOrderBy: string) {
        const rows = _.uniqBy(data, field)
        return _.orderBy(rows, [filedOrderBy])
    }

    static groupByOnly(data: Array<any>, field: string) {
        const rows = _.uniqBy(data, field)
        return rows;
    }

    static groupByDesc(data: Array<any>, field: string, filedOrderBy: string) {
        const rows = _.uniqBy(data, field)
        return _.orderBy(rows, [filedOrderBy], ['desc'])
    }

    static toParaKey(name: string) {
        return name.toLowerCase().replace(" ", "_");
    }

    static pushToArray(data: firestore.QuerySnapshot) {
        if (data.empty) return null;
        return data.docs.map(m => { return { ...m.data() } })
    }

    static pushToObject(data: firestore.DocumentSnapshot) {
        if (!data.exists) return null;
        return { ...data.data(), id: data.id };
    }

    static genCrimeIndex(index: number) {
        let value = index.toString();
        if (value.length == 1) value = "00" + value;
        else if (value.length == 2) value = "0" + value;
        else if (value.length == 3) value = value;
        return value;
    }

    static toArray(value: any) {
        if (value === undefined || value === null) {
            return [];
        }
        return value;
    }

    static fieldArrayValues(data: any, item: any) {
        const row = item.key;
        if (this.toArray(data).length === 0) {
            return [row];
        }
        else {
            const list = data.filter((m: any) => m === item.key);
            if (list.length === 0) {
                return data.push(row);
            }
            else {
                return data.filter((m: any) => m !== item.key);
            }
        }
    }

    static updatedArrayItem(data: Array<any>, newItem) {
        const exist = data.filter(m => m.key === newItem.key);
        if (exist && exist.length > 0) {
            const index = data.findIndex((obj => obj.key == newItem.key));
            data[index] = newItem;
        } else {
            data.push(newItem);
        }
        return data;
    }

    static yearNumberKey(date: Date) {
        return Number(moment(date).format('YYYY'))
    }

    static monthNumberKey(date: Date) {
        return Number(moment(date).format('MM'))
    }

    static calAge(dob: Date) {
        let age = null;
        const year = this.yearNumberKey(new Date()) - this.yearNumberKey(dob);
        const month = this.monthNumberKey(dob) - this.monthNumberKey(new Date());
        if (month >= 0) {
            age = year
        } else {
            age = year - 1;
        }
        return age;
    }

    static toNumber(value: any) {
        if (value === null || value === undefined || value === "") {
            return 0;
        }
        if (Number(value) === NaN) return 0;
        return Number(value);
    }

    static calScholarship(amount, arg: any): any {
        let value = 0;
        let { percentage, cash, loan } = arg;
        const cashAmount = this.toNumber(cash);
        const percentAmount = this.toNumber(percentage);
        const loanAmount = this.toNumber(loan);
        let deduction = (percentAmount + loanAmount) >= 100 ? 0 : 10;
        let balanceDue = amount - deduction;
        let totalScholarship = 0;
        let totalLoan = 0;
        if (cashAmount !== 0) {
            balanceDue = balanceDue - cashAmount;
            totalScholarship = totalScholarship + cashAmount;
        }
        if (percentAmount !== 0) {
            const totalDisP = balanceDue * percentAmount / 100;
            balanceDue = balanceDue - totalDisP;
            totalScholarship = totalScholarship + totalDisP;
        }
        if (loanAmount !== 0) {
            totalLoan = balanceDue * loanAmount / 100;
            balanceDue = balanceDue - totalLoan;
        }
        value = balanceDue + deduction;
        const result = {
            totalLoan: totalLoan,
            totalScholarship: totalScholarship,
            balanceDue: value,
            deductAmount: deduction
        }
        return result;
    }

    static testingTab(tabs: Array<any>, key: string, iKey: string) {
        const tab = [];
        let i = 0;
        tabs.forEach(m => {
            tab.push({
                path: m.path + "/" + key + "/" + iKey + "/" + m.key,
                label: m.label
            });
            i++;
        });
        return tab;
    }

    static autoComplete(stateCtrl: AbstractControl, data: any, type) {
        return stateCtrl.valueChanges.pipe(startWith(''),
            map(state => state ? this.filterStates(data, stateCtrl.value, type) : data.slice())
        );
    }

    static filterStates(data: any[], value: any, type: any): any[] {
        if (value && data.length > 0) {
            if (value.key) {
                return data.filter(state => state.key === value.key);
            }
            return data.filter(state => state[type].replace(/\s/g, '').toLowerCase().indexOf(value.replace(/\s/g, '').toLowerCase()) > -1);
        }
        return [];
    }

    public static validSelected(control: AbstractControl): { [s: string]: boolean } {
        const value = control.value;
        if (value !== undefined && value !== null && value !== '') {
            if (!value.key && !value.id) {
                return { validKey: true }
            }
        }
    }

    public static toNull(value) {
        if (value === "" || value === undefined || value === null) {
            return null;
        }
        return value;
    }

    static caseObj(item: any) {
        const obj = this.toNull(item)
        if (obj) {
            return {
                key: item.key,
                id: item.id,
                name: item.name,
                order: item.order,
            };
        } else return null;
    }

    static courtObj(item: any) {
        const obj = this.toNull(item)
        if (obj) {
            return {
                key: item.key,
                name: item.name,
                en_name: item.en_name,
            };
        } else return null;
    }

    static dataColObj(item: any) {
        const obj = this.toNull(item)
        if (obj) {
            return {
                key: item.key,
                id: item.id,
                name: item.name,
            };
        } else return null;
    }

    static jailColObj(item: any) {
        const obj = this.toNull(item)
        if (obj) {
            return {
                key: item.key,
                name: item.name,
                en_name: item.en_name,
            };
        } else return null;
    }

    static itemCategoryObj(item: any) {
        const obj = this.toNull(item)
        if (obj) {
            return {
                key: item.key,
                id: item.id,
                name: item.name,
                type: item.type,
            };
        } else return null;
    }

    static userObj(item: any) {
        const obj = this.toNull(item)
        if (obj) {
            return {
                key: item.key,
                displayName: this.toNull(item.displayName),
                email: this.toNull(item.email),
                role: this.toNull(item.role),
            };
        } else return null;
    }

    static provinceObj(item: any) {
        const obj = this.toNull(item)
        if (obj) {
            return {
                key: item.key,
                name: this.toNull(item.name),
                id: this.toNull(item.id),
                en_name: this.toNull(item.en_name),
            };
        } else return null;
    }


    static districtObj(item: any) {
        const obj = this.toNull(item)
        if (obj) {
            return {
                key: item.key,
                name: this.toNull(item.name),
                id: this.toNull(item.id),
                en_name: this.toNull(item.en_name),
            };
        } else return null;
    }

    static communeObj(item: any) {
        const obj = this.toNull(item)
        if (obj) {
            return {
                key: item.key,
                name: this.toNull(item.name),
                id: this.toNull(item.id),
                en_name: this.toNull(item.en_name),
            };
        } else return null;
    }

    static subCategoryObj(item: any) {
        const obj = this.toNull(item)
        if (obj) {
            return {
                key: item.key,
                name: this.toNull(item.name),
            };
        } else return null;
    }


}

export function geoPrivilege(user: any) {
    const { level_id, province_id, district_id, commune_id, commune_name, district_name, province_name } = user;
    switch (level_id) {
        case "4":
            return {
                provinceId: toNumber(province_id),
                districtId: toNumber(district_id),
                communeId: toNumber(commune_id),
                commune_name,
                district_name,
                province_name
            }
        case "3":
            return {
                provinceId: toNumber(province_id),
                districtId: toNumber(district_id),
                communeId: 0,
                district_name,
                province_name,
                commune_name
            }
        case "2":
            return {
                provinceId: toNumber(province_id),
                districtId: 0,
                communeId: 0,
                district_name,
                province_name,
                commune_name
            }
        case "1":
            return {
                provinceId: 0,
                districtId: 0,
                communeId: 0,
                district_name,
                province_name,
                commune_name
            }
        default:
            break;
    }
}

export function toKhmerNumber(number: any) {
    const khNumber = [`០`, `១`, `២`, `៣`, `៤`, `៥`, `៦`, `៧`, `៨`, `៩`];
    const array = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
    const chars = Array.from(`${number}`);
    const items = chars.map(m => {
      for (let index = 0; index < array.length; index++) {
        const element = array[index];
        if (`${element}` === `${m}`) return khNumber[index]
      }
      return m;
    })
    let result = '';
    items.map(m => {
      result = result + `${m}`
    })
    return result;
  }

export function sortBy(data: any[], fields: any[]) {
    const list = _.sortBy(data, fields);
    return list;
  }

export function toNumber(value: any) {
    if (value === null || value === undefined || value === "") {
        return 0;
    }
    if (Number(value) === NaN) return 0;
    return Number(value);
}

export function toNull(value) {
    if (value === "" || value === undefined || value === null) {
        return null;
    }
    return value;
}

export function removeObjArray(data: Array<any>, key: string): any {
    let value = [];
    if (data && data.length > 0) {
        value = data.filter(m => m.key !== key);
    }
    return value;
}


export function removeStingArray(data: Array<any>, key: string): any {
    let value = [];
    if (data && data.length > 0) {
        value = data.filter(m => m !== key);
    }
    return value;
}

export function pushObjArray(data: Array<any>, newItem: any, field: any = "key") {
    let value = [];
    if (data && data.length > 0) {
        value = data;
        const exist = value.filter(m => m[field] === newItem.key);
        if (exist && exist.length > 0) {
            const index = value.findIndex((obj => obj[field] == newItem.key));
            value[index] = newItem;
        } else {
            value.push(newItem);
        }
    } else {
        value.push(newItem);
    }

    return value;
}

export function pushStringArray(data: Array<any>, key: string) {
    let value = [];
    if (data && data.length > 0) {
        value = data;
        const exist = value.filter(m => m === key);
        if (exist && exist.length > 0) {
            const index = value.findIndex((obj => obj == key));
            value[index] = key;
        } else {
            value.push(key);
        }
    } else {
        value.push(key);
    }

    return value;
}


export function fileSize(size: any) {
    if (size) {
        const fSExt = new Array('Bytes', 'KB', 'MB', 'GB');
        let j = 0;
        while (size > 900) { size /= 1024; j++; };
        const exactSize = (Math.round(size * 100) / 100) + ' ' + fSExt[j];
        return exactSize
    }
    return null
}

export function getIndicesOf(searchStr: any, str) {
    var searchStrLen = searchStr.length;
    if (searchStrLen == 0) {
        return [];
    }
    const searchArray = `${searchStr}`.split(' ');
    let totalIndex: number = 0;
    searchArray.forEach(text => {
        if (text) {
            const index = `${str}`.toLocaleLowerCase().indexOf(text.toLocaleLowerCase())
            totalIndex = totalIndex + (index >= 0 ? 1 : 0)

        }
    })
    return totalIndex;
}

export function toDateValue(date: any) {
    return moment(date, 'YYYY-MM-DD').toDate()
}

export function getCurrentYear() {
    return moment().format('YYYY')
}


export function checkFileSize(size: number) {
    const totalSize = (size / 1024) / 1024;
    if (totalSize > 300) return true;
    return false;
}

export function pageKey() {
    return Number(moment().format('YYYYMMDDHHmmss'))
}

export function toMonthKh(date: any) {
    const index = moment(date).format("MM");
    return months[index].name
}

export function toKhCalendar(date: Date) {
    const day = moment(date).format("D");
    const years = moment(date).format("YYYY");
    return `ថ្ងៃ${toDayKh(date)} ទី${day} ខែ${toMonthKh(date)} ឆ្នាំ${years}`
}

export function toKhDate(date: Date) {
    const day = moment(date).format("D");
    const years = moment(date).format("YYYY");
    return `ថ្ងៃទី${day} ខែ${toMonthKh(date)} ឆ្នាំ${years}`
}

export function toDayKh(date: Date) {
    const m = moment(date);
    const day = m.format("dddd");
    let dayName: string = "";
    switch (day) {
        case "Monday": dayName = "ច័ន្ទ";
            break;
        case "Tuesday": dayName = "អង្គារ";
            break;
        case "Wednesday":
            dayName = "ពុធ"
            break;
        case "Thursday":
            dayName = "ព្រហស្បតិ៍"
            break;
        case "Friday":
            dayName = "សុក្រ"
            break;
        case "Saturday":
            dayName = "សៅរ៍"
            break;
        case "Sunday":
            dayName = "អាទិត្យ"
            break;
        default:
            break;
    }
    return dayName
}

export function dayRemaining(startDate: Date, endDate: Date) {
    return moment(endDate).diff(moment(startDate), 'days')
}

export function geoObj(item: any) {
    const obj = toNull(item)
    if (obj) {
        return {
            key: item.key,
            id: toNull(item.id),
            name: toNull(item.name),
            en_name: toNull(item.en_name),
        };
    } else return null;
}

export function geoOtherObj(item: any) {
    const obj = toNull(item)
    if (obj) {
        return {
            key: item.key,
            id: toNull(item.id),
            name: toNull(item.name),
            en_name: toNull(item.en_name),
            thai_name: toNull(item.thai_name),
        };
    } else return null;
}

export function departmentObj(item: any) {
    const obj = toNull(item)
    if (obj) {
        return {
            key: item.key,
            name: toNull(item.name),
            order: toNull(item.order),
        };
    } else return null;
}

export function postObj(item: any) {
    const obj = toNull(item)
    if (obj) {
        return {
            key: item.key,
            name: toNull(item.name),
        };
    } else return null;
}

export function setLocalStorageItem(itemName: string, itemValue: any) {
    localStorage.setItem(itemName, JSON.stringify(itemValue));
  }
  
  export function getLocalStorageItem(itemKey: string): any {
    let value = localStorage.getItem(itemKey);
    if (value) {
      return JSON.parse(value)
    }
    return null;
  }

  export function pushToObject(data: firestore.DocumentSnapshot):any {
    if (!data.exists) return null;
    return { ...data.data(), id: data.id };
}

export function geoPoint(lat, long) {
  return new firebase.firestore.GeoPoint(lat, long)
}