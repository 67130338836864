import { observable, action } from "mobx";
import { MappingService } from 'src/app/shared/services/mapping.service';
import { MapViewService } from "../services/mapView.service";
import { Injectable } from "@angular/core";
import { pushToArray } from 'src/app/shared/services/convert.service';
import { STATUS_OBJ } from 'src/app/constant/status.constant';

@Injectable({ providedIn: 'root' })

export class MapViewStore {
    @observable data = [];
    @observable laborBusinessdata = [];
    @observable laborDocumentsdata = [];
    @observable laborCardsdata = [];

    @observable businessOwnerdata = [];
    @observable businessLocationsdata = [];
    @observable businessLaborInspectiondata = [];
    
    @observable loading = false;
    @observable empty = false;
    @observable process = false;

    @observable selectedProvince = null;
    @observable selectedDistrict = null;
    @observable selectedCommune = null;


    constructor(
        private ds: MapViewService
    ) { }


    @action
    displayBusinessType(item: any): string {
        return item ? `${item.name}` : item;
    }


    @observable fetchMissionRef = null;
    @action
    fetchMission(inspectionkey, callback) {
        this.loading = true;
        this.fetchMissionRef = this.ds.inspectMissionRef(inspectionkey)
            .valueChanges().subscribe(docs => {
                this.data = docs;
                callback(docs);
                this.empty = docs.length === 0;
                this.loading = false;
            });

    }

    @action
    async fetchLaborDetail(key) {
        this.loading = true;
        const business = pushToArray(await this.ds.laborBusinessRef(key).get().toPromise());
        const documents = pushToArray(await this.ds.laborDocumentsRef(key).get().toPromise())
        const cards = pushToArray(await this.ds.laborCardsRef(key).get().toPromise())
        this.laborBusinessdata = business;
        this.laborDocumentsdata = documents;
        this.laborCardsdata = cards;
        this.loading = false
    }


    @action
    async fetchBusinessDetail(key) {
        this.loading = true;
        const businessOwner = pushToArray(await this.ds.businessOwnersRef(key).get().toPromise());
        const businessLocations = pushToArray(await this.ds.businessLocationsRef(key).get().toPromise())
        const businessLaborInspection = pushToArray(await this.ds.businessLaborInspectionRef(key).get().toPromise())
        this.businessOwnerdata = businessOwner;
        this.businessLocationsdata = businessLocations;
        this.businessLaborInspectiondata = businessLaborInspection;
        this.loading = false
    }


    @action
    async fetchMissionDetail(inspectionKey, key) {
        this.loading = true;
        const businessOwner = pushToArray(await this.ds.businessOwnersRef(key).get().toPromise());
        this.businessOwnerdata = businessOwner;
        // this.businessLaborInspectiondata = businessLaborInspection;
        this.loading = false
    }


    @action
    async fetchEnviroment(callback) {
        this.loading = true;
        const data = MappingService.pushToObject(await this.ds.sys_environmentRef().get().toPromise())
        this.loading = false
        callback(data)
    }

    // @observable fetchMissionRef = null;

    @action
    async filterListing(keyword, type, inspectionKey, callback) {
        if (keyword) {
            switch (type.key) {
                case 1:
                    if (!inspectionKey) return
                    this.data = [];
                    const docs1 = await this.ds.firestore().collection("inspections").doc(inspectionKey).collection('missions')
                        .where('status.key', '==', STATUS_OBJ.ACTIVE.key)
                        .where("keyword",'array-contains', `${keyword}`.toUpperCase())
                        .orderBy('keyword')
                        .limit(50).get();
                    this.data = pushToArray(docs1)
                    callback(this.data)
                    break;
                case 2:
                    this.data = [];
                    const docs2 = await this.ds.firestore().collection("business")
                        .where('status.key', '==', STATUS_OBJ.ACTIVE.key)
                        .where("keyword", "array-contains", `${keyword}`.toUpperCase())
                        .orderBy('keyword')
                        .limit(50).get();
                    this.data = pushToArray(docs2)
                    callback(this.data)
                    break;
                case 3:
                    this.data = [];
                    const docs3 = await this.ds.firestore().collection("labors")
                        .where('status.key', '==', STATUS_OBJ.ACTIVE.key)
                        .where("keyword", "array-contains", `${keyword}`.toUpperCase())
                        .orderBy('keyword')
                        .limit(50).get();
                    this.data = pushToArray(docs3)
                    callback(this.data)
                    break;
                default:
                    break;
            }
        } else {
            switch (type.key) {
                case 1:
                    if (!inspectionKey) return
                    this.data = [];
                    const docs1 = await this.ds.firestore().collection("inspections").doc(inspectionKey).collection('missions')
                        .where('status.key', '==', STATUS_OBJ.ACTIVE.key)
                        .limit(10).get();
                    this.data = pushToArray(docs1)
                    callback(this.data)
                    break;
                case 2:
                    this.data = [];
                    const docs2 = await this.ds.firestore().collection("business")
                        .where('status.key', '==', STATUS_OBJ.ACTIVE.key)
                        .limit(10).get();
                    this.data = pushToArray(docs2)
                    callback(this.data)
                    break;
                case 3:
                    this.data = [];
                    const docs3 = await this.ds.firestore().collection("labors")
                        .where('status.key', '==', STATUS_OBJ.ACTIVE.key)
                        .limit(10).get();
                    this.data = pushToArray(docs3)
                    callback(this.data)
                    break;
                default:
                    break;
            }
        }
    }


    @action
    async filterListingDoc(key, type, inspectionKey, callback) {
        if (key) {
            switch (type.key) {
                case 1:
                    if (!inspectionKey) return
                    const docs1 = await this.ds.firestore().collection("inspections").doc(inspectionKey).collection('missions').doc(key).get();
                    const data = MappingService.pushToObject(docs1)
                    callback(data)
                    break;
                case 2:
                    const docs2 = await this.ds.firestore().collection("business").doc(key).get();
                    const data1 = MappingService.pushToObject(docs2)
                    callback(data1)
                    break;
                case 3:
                    const docs3 = await this.ds.firestore().collection("labors").doc(key).get();
                    const data2 = MappingService.pushToObject(docs3)
                    callback(data2)

                    break;
                default:
                    break;
            }
        } else {
            callback(null)
        }
    }

}
