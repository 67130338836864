
<div class="db-LoginBackground Box-root Box-background--white Padding-top--64" style="background-color: #fff;">
    <style>
      .db-LoginBackground {
        min-height: 690px;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        top: 0;
        z-index: 0;
        overflow: hidden;
        display: grid;
      }
  
      .db-LoginBackground-gridContainer {
        display: -ms-grid;
        display: grid;
        grid-template-columns: [start] 1fr [left-gutter] repeat(16, 86.6px) [left-gutter] 1fr [end];
        grid-template-rows: [top] 1fr [top-gutter] repeat(8, 64px) [bottom-gutter] 1fr [bottom];
        justify-content: center;
        margin: 0 -4%;
        transform: rotate(-23deg) skew(-22deg);
      }
  
      .Box-root {
        box-sizing: border-box;
        border-radius: 30px;
      }
  
      .Flex-flex {
        display: -ms-flexbox;
        display: flex;
      }
    </style>
    <div class="db-LoginBackground-gridContainer">
      <div class="Box-root Flex-flex" style="grid-area: top / start / 8 / end;">
        <div class="Box-root" style="background-image: linear-gradient(white 0%, rgb(247, 250, 252) 33%); flex-grow: 1;">
        </div>
      </div>
      <div class="Box-root Flex-flex" style="grid-area: 4 / 2 / auto / 5;">
        <div class="Box-root Box-divider--light-all-2" style="flex-grow: 1;"></div>
      </div>
      <div class="Box-root Flex-flex" style="grid-area: 6 / start / auto / 2;">
        <div class="Box-root Box-background--blue800" style="flex-grow: 1;"></div>
      </div>
      <div class="Box-root Flex-flex" style="grid-area: 7 / start / auto / 4;">
        <div class="Box-root Box-background--blue" style="flex-grow: 1;"></div>
      </div>
      <div class="Box-root Flex-flex" style="grid-area: 8 / 4 / auto / 6;">
        <div class="Box-root Box-background--gray100" style="flex-grow: 1;"></div>
      </div>
      <div class="Box-root Flex-flex" style="grid-area: 2 / 15 / auto / end;">
        <div class="Box-root Box-background--cyan200" style="flex-grow: 1;"></div>
      </div>
      <div class="Box-root Flex-flex" style="grid-area: 3 / 14 / auto / end;">
        <div class="Box-root Box-background--blue" style="flex-grow: 1;"></div>
      </div>
      <div class="Box-root Flex-flex" style="grid-area: 4 / 17 / auto / 20;">
        <div class="Box-root Box-background--gray100" style="flex-grow: 1;"></div>
      </div>
      <div class="Box-root Flex-flex" style="grid-area: 5 / 14 / auto / 17;">
        <div class="Box-root Box-divider--light-all-2" style="flex-grow: 1;"></div>
      </div>
    </div>
  </div>
  <main class="auth-container">
    <router-outlet></router-outlet>
  </main>