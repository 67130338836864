import { AngularFirestore, QueryFn } from "@angular/fire/firestore";
import { Injectable } from "@angular/core";
import { APP_CONFIG, STATUS_OBJ } from '../../../constant/status.constant'
import { LIST_COVID_TYPE, RESULT_QUICK_TEST, TEST_TYPE } from "src/app/core/dummy/status";
import { ConvertService } from "src/app/shared/services/convert.service";
import { toNumber } from "src/app/shared/services/mapping.service";
@Injectable({
    providedIn: "root"
})
export class ReportService {
    constructor(private db: AngularFirestore) { }

    reportTestConditionRef(postKey: any, type: any, selectedDate: any) {

        return this.db.collection('test_covid', ref => {

            let condition = ref
                .where("status.key", "==", STATUS_OBJ.ACTIVE.key)
            const dateKey = ConvertService.toDateKey(selectedDate)

            if (postKey) {
                condition = condition.where("postKey", "==", postKey)
            }

            if (type.key === LIST_COVID_TYPE.tested_today.key) {
                condition = condition.where("testedDateKey", "==", dateKey).where("has_take_test", "==", true)
            } else if (type.key === LIST_COVID_TYPE.need_test_today.key) {
                condition = condition.where("next_testedDateKey", "==", dateKey).where("isAllocated", "==", false)
            } else if (type.key === LIST_COVID_TYPE.need_research_today.key) {
                condition = condition.where("isAllocated", "==", false).where("expired_testedDateKey", "==", dateKey)
            } else if (type.key === LIST_COVID_TYPE.need_test_72h) {
                const next72Hrs = ConvertService.addDateDay(selectedDate, 3)
                const next72HrsDateKey = ConvertService.toDateKey(next72Hrs)
                condition = condition.where("next_testedDateKey", "==", next72HrsDateKey).where("isAllocated", "==", false)
            } else if (type.key === LIST_COVID_TYPE.first_tested_positive.key) {
                condition = condition.where("test_type.key", "==", TEST_TYPE.first.key).where("result_test.key", "==", RESULT_QUICK_TEST.POSITIVE.key)
            } else if (type.key === LIST_COVID_TYPE.second_tested_positive.key) {
                condition = condition.where("test_type.key", "==", TEST_TYPE.second.key).where("result_test.key", "==", RESULT_QUICK_TEST.POSITIVE.key)
            } else if (type.key === LIST_COVID_TYPE.second_tested_negative.key) {
                condition = condition.where("test_type.key", "==", TEST_TYPE.second.key).where("result_test.key", "==", RESULT_QUICK_TEST.NEGATIVE.key)
            } else if (type.key === LIST_COVID_TYPE.second_no_tested.key) {
                condition = condition.where("test_type.key", "==", TEST_TYPE.second.key).where("has_take_test", "==", false)
            } else if (type.key === LIST_COVID_TYPE.result_research_today.key) {
                condition = condition.where("call_date_key", "==", dateKey).where("has_called", "==", true)
            }

            return condition
        })
    }

    reportTestResearchLeftRef(postKey: any, fromDate: any, toDate: any) {

        return this.db.collection('test_covid', ref => {

            let condition = ref
                .where("status.key", "==", STATUS_OBJ.ACTIVE.key)
            const fromDateKey = ConvertService.toDateKey(fromDate)
            const toDateKey = ConvertService.toDateKey(toDate)

            if (postKey) {
                condition = condition.where("postKey", "==", postKey)
            }

            condition = condition.where("isAllocated", "==", false)
                .where("expired_testedDateKey", ">=", fromDateKey)
                .where("expired_testedDateKey", "<=", toDateKey)
                .orderBy("expired_testedDateKey", "desc")

            return condition
        })
    }

    reportFetchForeignerTravelerRef(travelKey, postKey: any, fromDate: any, toDate: any) {
        return this.db.collection('foreigner_traveler', ref => {
            let condition = ref
                .where("status.key", "==", 1)
                .where("travel_type.key", "==", travelKey)
                .where("date_of_arrival_key", ">=", toNumber(fromDate))
                .where("date_of_arrival_key", "<=", toNumber(toDate))
                .orderBy("date_of_arrival_key", "desc")

            if (postKey) {
                condition = condition.where("postKey", "==", postKey)
            }

            return condition
        })
    }

    reportFetchUserRef(postKey: any) {
        return this.db.collection('users', ref => {
            let condition = ref
                .where("status.key", "==", 1)

            // if (postKey) {
            //     condition = condition.where("postKey", "==", postKey)
            // }

            condition = condition.where("department.key", "==", "TWui1szIfZhXGqRLuxko") //hfputQTs2SrlB5VmrIbb TWui1szIfZhXGqRLuxko

            return condition
        })
    }

    inspectionsRef() {
        return this.db.collection("inspections");
    }

    vehiclesRef(ref?: QueryFn) {
        return this.db.collection("vehicles", ref);
    }
    passengersRef(ref?: QueryFn) {
        return this.db.collection("passengers", ref);
    }

    businessRef() {
        return this.db.collection("business", ref => ref.limit(10));
    }

    sys_environmentRef() {
        return this.db.collection("sys_environment").doc('general');
    }

    dbRef() {
        return this.db
    }

    fireRef() {
        return this.db.firestore
    }

    docCollectionRef(collection) {
        return this.db.collection(collection);
    }

    docRef(collection, key) {
        return this.db.collection(collection).doc(key);
    }

    collectionRef(collection) {
        return this.db.collection(collection, ref => ref);
    }

    autoFilter(collection, keyword) {
        return this.db.collection(collection, ref => ref
            .where("keyword", 'array-contains', `${keyword}`.toUpperCase()).orderBy('keyword')
            .limit(20));
    }

    reportFetchReportVoaMovementRef(travelKey, postKey: any, fromDate: any, toDate: any) {
        return this.db.collection('voa_scan_movements', ref => {
            let condition = ref
                .where("status.key", "==", 1)
                .where("travel_type.key", "==", travelKey)
                .where("date_of_arrival_key", ">=", toNumber(fromDate))
                .where("date_of_arrival_key", "<=", toNumber(toDate))
                .orderBy("date_of_arrival_key", "desc")

            if (postKey) {
                condition = condition.where("postKey", "==", postKey)
            }

            return condition
        })
    }


    voaMovementSearchRef(post: any) {
        return this.db.collection("voa_scan_movements", ref => {
            let condition = ref
                .where('status.key', '==', 1)
                .orderBy('createdDate', 'desc')
                .limit(APP_CONFIG.SIZE)

            return condition
        })
    }

    searchvoaMovementRef(post: any, ref: string, search: any) {
        return this.db.collection(ref, ref => {
            if (search) {
                if (search.key) {
                    let condition = ref
                        .where('key', '==', search.key)
                        .where('status.key', '==', 1)
                        .orderBy('createdDate', 'desc')
                        .limit(APP_CONFIG.SIZE)

                    return condition
                }

                let condition = ref
                    .where('keywords', 'array-contains', ConvertService.toCapitalize(search))
                    .where('status.key', '==', 1)
                    .orderBy('createdDate', 'desc')
                    .limit(APP_CONFIG.SIZE)
                return condition
            }

            let condition = ref
                .where('status.key', '==', 1)
                .orderBy('createdDate', 'desc')
                .limit(APP_CONFIG.SIZE)

            return condition
        })
    }

    voaScanMovementsRef(ref?: QueryFn) {
        return this.db.collection('voa_scan_movements', ref)
    }

}
export const searchVOAmovementFilterBy = [
    { key: "voa_scan_movements", text: "អ្នកដំណើរ", placeholder: 'ស្វែងរកអ្នកមានVOA', ref: 'voa_scan_movements' },
    // { key: "foreigner_passenger", text: "អ្នកបើកបរឬអ្នករួមដំណើរ", placeholder: 'ស្វែងរកអ្នកបើកបរឬអ្នករួមដំណើរ', ref: 'foreigner_passengers' },
]
