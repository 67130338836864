import { AngularFirestore } from "@angular/fire/firestore";
import { Injectable } from "@angular/core";
import {STATUS_OBJ} from '../../../constant/status.constant'
@Injectable({
  providedIn: "root"
})
export class MapViewService {
  constructor(private db: AngularFirestore) { }
  firestore(){
    return this.db.firestore
  }
  sys_environmentRef(){
    return this.db.collection("sys_environment").doc('general');
  }

  inspectMissionRef(inspectkey){
    return this.db.collection("inspections").doc(inspectkey).collection('missions',ref =>ref.where('status.key', '==', STATUS_OBJ.ACTIVE.key));
  }

  laborBusinessRef(key){
    return this.db.collection("labors").doc(key).collection('business',ref =>ref.where('status.key', '==', STATUS_OBJ.ACTIVE.key));
  }
  laborDocumentsRef(key){
    return this.db.collection("labors").doc(key).collection('documents',ref =>ref.where('status.key', '==', STATUS_OBJ.ACTIVE.key));
  }
  laborCardsRef(key){
    return this.db.collection("labors").doc(key).collection('labor_cards',ref =>ref.where('status.key', '==', STATUS_OBJ.ACTIVE.key));
  }

  businessOwnersRef(key){
    return this.db.collection("business").doc(key).collection('businessOwners',ref =>ref.where('status.key', '==', STATUS_OBJ.ACTIVE.key));
  }
  businessLaborInspectionRef(key){
    return this.db.collection("business").doc(key).collection('labor_inspection',ref =>ref.where('status.key', '==', STATUS_OBJ.ACTIVE.key));
  }
  businessLocationsRef(key){
    return this.db.collection("business").doc(key).collection('locations',ref =>ref.where('status.key', '==', STATUS_OBJ.ACTIVE.key));
  }

  
}

