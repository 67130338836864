import { Pipe, PipeTransform } from '@angular/core';
import { dayRemaining } from '../services/mapping.service';

@Pipe({
  name: 'dayRemaining'
})
export class DayRemainingPipe implements PipeTransform {

  transform(startDate: Date, ...args: unknown[]): unknown {
    return `${dayRemaining(new Date(), startDate)} ថ្ងៃ`;
  }

}
