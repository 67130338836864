import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'dateTime'
})
export class DateTimePipe implements PipeTransform {

  transform(value: Date, args?: any): any {
    return moment(value).format('DD-MM-YYYY, hh:mm:ss a');
  }
}