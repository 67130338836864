import { AngularFirestore, QueryFn } from "@angular/fire/firestore";
import { Injectable } from "@angular/core";
import { STATUS_OBJ } from '../../../constant/status.constant'
import { ConvertService } from "src/app/shared/services/convert.service";
import { Pages } from "src/app/core/dummy/app";
import { toJS } from "mobx";
@Injectable({
  providedIn: "root"
})
export class UserAccountService {
  constructor(private db: AngularFirestore) { }

  lazyDataRef(profile, field, search, lastVisible: any) {
    return this.db.collection(`users`, ref => {
      let condition = ref.limit(Pages.size)
      
      if (profile?.role?.key === 5) {
        condition = condition.where('department.key', "==", profile?.department?.key)
      }

      if (search) {
        condition = condition.where(field, ">=", search.toUpperCase()).orderBy(field)
      } else {
        condition = condition.orderBy('updated_at', "desc")
      }

      if (lastVisible) {
        condition = condition.startAfter(lastVisible)
      }

      return condition
    })
  }

  batch() {
    return this.db.firestore.batch()
  }
  fireRef() {
    return this.db.firestore
  }
  dbRef() {
    return this.db
  }
  createId() {
    return this.db.createId();
  }
  displayName(item: any): string {
    return item ? item.name : item;
  }

  displayKhName(item: any): string {
    return item ? item.khName : item;
  }
  roleRef() {
    return this.db.collection("roles", ref => ref.where("status.key", ">", STATUS_OBJ.DELETED.key).orderBy("status.key"));
  }
  roleCollectionRef() {
    return this.db.collection("roles");
  }

  vihecleTypeRef() {
    return this.db.collection("vehicle_type", ref => ref.orderBy("name"));
  }

  vietnamProvinceRef(ref?) {
    return this.db.collection("vietnam_provinces", ref);
  }

  vietnamDistrictRef(ref?) {
    return this.db.collection("vietnam_districts", ref);
  }

  groupVehicleRef(ref?: QueryFn) {
    return this.db.collection("group_vehicles", ref || (ref => ref.orderBy("order")));
  }

  departmentRef(ref?: QueryFn) {
    return this.db.collection("departments", ref || (ref => ref.orderBy("order")));
  }

  visaTypesRef(ref?: QueryFn) {
    return this.db.collection("visa_types", ref || (ref => ref.orderBy("order")));
  }

  prefixVehicleRef(ref?: QueryFn) {
    return this.db.collection("prefix_vehicles", ref || (ref => ref.orderBy("group.order")));
  }

  reasonRef(ref?: QueryFn) {
    return this.db.collection("reason_testing", ref || (ref => ref.orderBy("name")));
  }

  postOfficeRef() {
    return this.db.collection("posts", ref => ref.orderBy("province.id"));
    // const fromDate = ConvertService.numberToStartDay(20210723)
    // const toDate = ConvertService.numberToEndDay(20210723)
    // return this.db.collection("passenger_profile", ref => ref
    //   .where("status.key", "==", 1)
    //   .where("type_of_passenger.key", "==", 2)
    //   .where("date_of_arrival", ">=", fromDate)
    //   .where("date_of_arrival", "<=", toDate)
    //   .orderBy("date_of_arrival", "desc")
    //   .limit(5)
    // );
  }

  usersRef(field: string) {
    return this.db.collection("users", ref =>
      ref
        .orderBy(field)
        .limit(Pages.size)
    );
  }

  userFetchRef(field: string, search: string, lastVisible: any) {
    if (search) {
      return this.db.collection("users", ref =>
        ref
          .where(field, ">=", ConvertService.toCapitalize(search))
          .orderBy("page_key", "desc")
          .orderBy(field)
          .startAfter(lastVisible.page_key)
          .limit(Pages.size)
      );
    } else {
      return this.db.collection("students", ref =>
        ref
          // .where("campus.key", "==", campus.key)
          .orderBy(field)
          .startAfter(lastVisible.page_key)
          .limit(Pages.size)
      );
    }
  }

  userRef() {
    return this.db.collection("users", ref => ref.orderBy("updated_at", "desc"));
  }
  userCollectionRef() {
    return this.db.collection("users");
  }
  userFireCollectionRef() {
    return this.db.firestore.collection("users");
  }

  employeeRef() {
    return this.db.collection("employees", ref => ref.where("status.key", ">", STATUS_OBJ.DELETED.key).orderBy("status.key"));
  }
  employeeCollectionRef() {
    return this.db.collection("employees");
  }
  employeeFireCollectionRef() {
    return this.db.firestore.collection("employees");
  }

}

