import { Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';

@Injectable({
  providedIn: 'root'
})
export class FireStorageService {
  uploadPercent: any = null;
  fileUrl:any=null;
  constructor(private storage: AngularFireStorage) { }

  uploadFile(event, filePath) {
    const file = event.target.files[0];
    const ref = this.storage.ref(filePath);
    const task = ref.put(file);
    return task;
  }

  uploadSelectedFile(file, filePath) {
    const ref = this.storage.ref(filePath);
    const task = ref.put(file);
    return task
  }

  async uploadFileGetDownloadURL(file, filePath) {
    const ref = this.storage.ref(filePath);
    const task = ref.put(file);
    const fileUrl =await ref.getDownloadURL().toPromise();
    return {
      uploadTask:task,
      fileUrl:fileUrl,
    };
  }

  storageRef(filePath) {
    return this.storage.ref(filePath);
  }

}
