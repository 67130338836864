// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    // apiKey: "AIzaSyCApsQuFEBEWJCpBXzaRIoFPitmVfQ6GG0",
    // authDomain: "flis-gdi.firebaseapp.com",
    // databaseURL: "https://flis-gdi.firebaseio.com",
    // projectId: "flis-gdi",
    // storageBucket: "flis-gdi.appspot.com",
    // messagingSenderId: "880146746916",
    // appId: "1:880146746916:web:5e5b8b764cf4438a52d2b1",
    // measurementId: "G-ESKSNFK5W0"

    // apiKey: "AIzaSyCrfjZ2C9O0-6GOUoNkSHTWLejhoOf9PJw",
    // authDomain: "cbmsplus-5ea3b.firebaseapp.com",
    // databaseURL: "https://cbmsplus-5ea3b.firebaseio.com",
    // projectId: "cbmsplus-5ea3b",
    // storageBucket: "cbmsplus-5ea3b.appspot.com",
    // messagingSenderId: "920566167739",
    // appId: "1:920566167739:web:2195204a915082fe9f919e",
    // measurementId: "G-8NQQ4PKYWZ"
    

    apiKey: "AIzaSyD3n1dO33fGkHshomKB8k_OTeE7yS3s7Fc",
    authDomain: "passenger-cloud.firebaseapp.com",
    databaseURL: "https://passenger-cloud.firebaseio.com",
    projectId: "passenger-cloud",
    storageBucket: "passenger-cloud.appspot.com",
    messagingSenderId: "915359673863",
    appId: "1:915359673863:web:b4fdee47c5702c3442c4a2",
    measurementId: "G-GT79729T76"

    // apiKey: "AIzaSyBAs5Eh4h1rRuHuRbRKUkbZWxPKmJlMf1M",
    // authDomain: "cbms-mobile.firebaseapp.com",
    // databaseURL: "https://cbms-mobile.firebaseio.com",
    // projectId: "cbms-mobile",
    // storageBucket: "cbms-mobile.appspot.com",
    // messagingSenderId: "823857271260",
    // appId: "1:823857271260:web:37d5bd3afebf42730a7f19",
    // measurementId: "G-Q8GY82HPJ9"

    // apiKey: "AIzaSyCalbKqvm4e5teZeFY5Cb4ZzHIYaAfEueo",
    // authDomain: "bltms-v2.firebaseapp.com",
    // databaseURL: "https://bltms-v2-default-rtdb.asia-southeast1.firebasedatabase.app",
    // projectId: "bltms-v2",
    // storageBucket: "bltms-v2.appspot.com",
    // messagingSenderId: "18728178347",
    // appId: "1:18728178347:web:4683bb4f4ce3d7bec09dee",
    // measurementId: "G-PVHQ4H72X3"
    
  },
  mapKey: 'AIzaSyAc6s59GVe1RD2KlgAyktYVJMDoA4rlIFo'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
