import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, AbstractControl, FormBuilder } from '@angular/forms';
import { MatMenuTrigger } from '@angular/material/menu';
import { ActivatedRoute, Router } from '@angular/router';
import { debounceTime, switchMap, tap } from 'rxjs/operators';
import { TRAVEL_TYPE } from 'src/app/core/dummy/status';
import { AuthStore } from 'src/app/core/stores/auth.store';
import { MappingService } from 'src/app/shared/services/mapping.service';
import { ReportStore } from '../../stores/report.store';

@Component({
  selector: 'app-report-filter-travel-type',
  templateUrl: './report-filter-travel-type.component.html',
  styleUrls: ['./report-filter-travel-type.component.scss']
})
export class ReportFilterTravelTypeComponent implements OnInit {
  
  @Output() menuTravelMenuTrigger = new EventEmitter();
  @Input() reportType: any;

  form: FormGroup;
  search: AbstractControl
  data;
  constructor(
    public store: ReportStore,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  travelTypeList = TRAVEL_TYPE
  async ngOnInit(): Promise<void> {
    this.form = this.fb.group({
      search: [null]
    });
    this.search = this.form.controls["search"];

    // this.route.queryParams.subscribe(async queryParams => {
    //   this.store.fetchData('posts', data => {
    //     this.data = data.filter(m => m.status.key === 1)

    //     this.data = MappingService.orderBy(this.data, "province.id")
    //   });
    // })

    // this.search.valueChanges
    //   .pipe(
    //     debounceTime(300),
    //     tap(() => (false)),
    //     switchMap(value => this.store.autofilter('posts', value, data => {
    //       this.data = data.filter(m => m.status.key === 1)
    //       this.data = MappingService.orderBy(this.data, "province.id")
    //     }))
    //   ).subscribe(res => {
    //   });
  }

  displayItem(item: any): string {
    return item ? item.text : item;
  }

  _onPress(filterAll) {
    const sparams = this.route.snapshot.queryParams
    this.router.navigate(['/reports/' + this.reportType], {
      queryParams:
      {
        travelKey: filterAll ? null : this.search.value.key,
        postKey: sparams.postKey ? sparams.postKey : null,
        fromdate: sparams.fromdate ? sparams.fromdate : null,
        todate: sparams.todate ? sparams.todate : null,
      }
    });
    this.menuTravelMenuTrigger.emit('asd');
  }

}
