import { observable, computed, action, autorun, toJS } from "mobx";
import { Injectable } from "@angular/core";
import { ApiService } from '../../../shared/services/api.service';
import { periodData } from 'src/app/core/dummy/report';
import { ConvertService, toDayOfMonth, toKhDay, toKhMonth, toKhYear, toMonthKh, toYearOfPeriod } from 'src/app/shared/services/convert.service';
import { MappingService, sortBy, toNumber } from 'src/app/shared/services/mapping.service';
import { ReportService } from '../services/report.service'
import { PASSENGER_TYPE, RESULT_QUICK_TEST, TRAVEL_TYPE } from "src/app/core/dummy/status";
import { Subscription } from "rxjs";
@Injectable({ providedIn: "root" })
export class ReportStore {
    @observable public data = [];
    @observable public region = null;
    @observable public category = null;
    @observable public subCategory = null;
    @observable public victim = null;
    @observable public suspect = null;
    @observable public groupPerson = null;
    @observable public loading = false;
    @observable public process = false;
    @observable public loadingRecent = false;
    @observable public empty = false;
    @observable public statisticByCategory: any = [];
    @observable public statisticBySubCategory: any = [];

    @observable public statisticLabor: any = null;
    @observable public statisticByZone: any = [];
    @observable public statisticLaborByCard: any = [];
    @observable public statisticLaborByNationality: any = [];
    @observable public statisticLaborByVisa: any = [];
    @observable public statisticLaborByGender: any = [];

    @observable public statisticBusiness: any = null;
    @observable public statisticBusinessByNationality: any = [];
    @observable public statisticBusinessByZone: any = [];
    @observable public statisticBusinessByMission: any = [];
    @observable public statisticLaborByBusiness: any = [];

    @observable public statisticByVictim: any = [];
    @observable public statisticBySuspect: any = [];
    @observable public statisticByJustice: any = [];
    @observable public statisticByAllJustice: any = [];
    @observable public statisticByAllCrime: any = [];
    @observable public provinces: any = [];


    @observable public totalJustice: number = 0;
    @observable public totalCategory: number = 0;
    @observable public totalVictim: number = 0;
    @observable public totalSuspect: number = 0;
    @observable public periodOption = periodData[0];
    @observable public fromDate: any = null;
    @observable public toDate: any = null;
    @observable public fromDateKey: any = null;
    @observable public toDateKey: any = null;
    @observable public periodText: string = null;
    @observable public periodTextDaily: string = null;
    @observable public periodMonthDaily: string = null;
    @observable public provinceText = "គ្រប់ខេត្តក្រុងទាំងអស់";
    @observable public categoryText = "បទល្មើសទាំងអស់";
    @observable public subCategoryText = "ប្រភេទបទល្មើសទាំងអស់";

    @observable public periodType: number = 1;
    @observable public provinceFilter = null;
    @observable public districtFilter = null;
    @observable public communeFilter = null;
    @observable public categoryFilter = null;
    @observable public subCategoryFilter = null;
    @observable public fromDateFilter: any = null;
    @observable public toDateFilter: any = null;

    @observable public selectedProvince: any = null;
    @observable public selectedDistrict: any = null;
    @observable public selectedCommune: any = null;
    @observable public selectedCategory: any = null;
    @observable public selectedSubCategory: any = null;

    @observable public selectedInspection: any = null;
    @observable public selectedBusiness: any = null;

    @observable public totalAllJustice: number = 0;
    @observable public totalAllJusticePending: number = 0;
    @observable public totalAllJusticeCompleted: number = 0;
    @observable public totalAllCrime: number = 0;

    @observable public statisticSolveCrime: any = [];
    @observable public totalSolveCrime: number = 0;

    @observable public statisticCategory: any = [];
    @observable public statisticSubCategory: any = [];

    @observable public statisticDiakaHaveCase: any = [];
    @observable public statisticDiakaNoCase: any = [];
    @observable public totalDiakaHaveCase: number = 0;
    @observable public totalDiakaNoCase: number = 0;

    @observable public checkFirstLoan: boolean = false;


    @observable public businessData: any = [];
    @observable public autoBusinessData: any = [];
    @observable public report2Data: any = [];
    @observable public report1Data: any = [];
    @observable public report3Data: any = [];
    @observable public report4Data: any = [];

    @observable public fromDateNum = null;
    @observable public toDateNum = null;

    @observable public selectedPost = null;
    @observable public selectedTravel = null;

    @observable public reportPassengerStatistic: any = [];
    @observable public reportTravelInOut: any = [];
    @observable public reportTravelInOutReport1: any = [];
    @observable public reportTravelInOutReport2: any = [];
    @observable public reportThaiTravelInOutReport1: any = [];
    @observable public reportThaiTravelInOutReport2: any = [];
    @observable public reportThaiTravelInOut: any = [];
    @observable public reportPassengerHealth: any = [];
    @observable public reportPassengerListing: any = [];
    @observable public reportThaiPassengerListing: any = [];


    @observable public reportTestCovid: any = [];

    @observable public reportTestCovidTotay: any = [];
    @observable public reportTestCovidTotayS: any = null;

    @observable public reportTestCovidNeedResearch: any = [];
    @observable public reportTestCovidNeedResearchS: any = null;

    @observable public reportTestCovidResultResearch: any = [];
    @observable public reportTestCovidResultResearchS: any = null;

    @observable public reportTestCovidResearchLeft: any = [];
    @observable public reportTestCovidResearchLeftS: any = null;
    @observable fetchSearchRef: Subscription;

    constructor(private ds: ReportService, public api: ApiService) { }

    @action
    async fetchStatistic(key) {
        const doc = await this.ds.docRef("statistic", key).get().toPromise()
        return MappingService.pushToObject(doc)
    }

    @action
    async setStatistic(key, item: any, profile: any, callback) {
        this.process = true
        this.ds.docRef("statistic", key).set({
            key: key,

            totalNotQuickTest: 0,
            totalNotQuickTestF: 0,

            totalQuickTest: item.totalQuickTest || 0,
            totalQuickTestF: item.totalQuickTestF || 0,

            totalQuickTestP: item.totalQuickTestP || 0,
            totalQuickTestPF: item.totalQuickTestPF || 0,

            totalQuickTestN: item.totalQuickTestN || 0,
            totalQuickTestNF: item.totalQuickTestNF || 0,

            totalSolvPositiveAtN: 0,
            totalSolvPositiveAtNF: 0,

            totalSolvPositiveAtT: item.totalSolvPositiveAtT || 0,
            totalSolvPositiveAtTF: item.totalSolvPositiveAtTF || 0,

            totalSolvPositiveAtI: item.totalSolvPositiveAtI || 0,
            totalSolvPositiveAtIF: item.totalSolvPositiveAtIF || 0,

            totalSendToProvinve: item.totalSendToProvinve || 0,
            totalSendToProvinveF: item.totalSendToProvinveF || 0,

            totalTakeTestSample: item.totalTakeTestSample || 0,
            totalTakeTestSampleF: item.totalTakeTestSampleF || 0,

            totalNotYet72H: item.totalNotYet72H || 0,
            totalNotYet72HF: item.totalNotYet72HF || 0,

            updated_by: profile.key,
            updated_at: new Date(),

        }, { merge: true })
            .then(() => {
                this.process = false
                callback(true, null)
            }).catch(error => {
                this.process = false
                callback(false, error)
            })
    }

    @action
    async fetchEnviroment(callback) {
        this.loading = true;
        const data: any = MappingService.pushToObject(await this.ds.sys_environmentRef().get().toPromise())
        this.loading = false
        const inspectionDoc = await this.ds.inspectionsRef().doc(data.inspectionKey).get().toPromise();
        const inspectionData = MappingService.pushToObject(inspectionDoc);
        this.selectedInspection = inspectionData;
        callback(data)
    }
    @action
    async fetchData(collection, callback) {
        this.loading = true;
        this.ds.collectionRef(collection).valueChanges().subscribe(data => {
            callback(data)
            this.loading = false;
        })
    }
    @action
    async autofilter(collection, keyword, callback) {
        // this.loading = true;
        if (keyword) {
            this.ds.autoFilter(collection, keyword).valueChanges().subscribe(data => {
                callback(data)
                // this.loading = false;
            })
        }
    }

    @action
    async autofilterBusiness(collection, keyword, callback) {
        // this.loading = true;
        if (keyword) {
            this.ds.autoFilter(collection, keyword).valueChanges().subscribe(data => {
                this.autoBusinessData = data
                callback(data)
                // this.loading = false;
            })
        }
    }

    getPeriod(fromDate, toDate) {
        this.fromDate = ConvertService.numberToDate(fromDate);
        this.toDate = ConvertService.numberToDate(toDate);
        this.periodText = `ចាប់ពីថ្ងៃទី${toKhDay(this.fromDate)} ខែ${toKhMonth(this.fromDate)} ឆ្នាំ${toKhYear(this.fromDate)} ដល់ថ្ងៃទី ${toKhDay(this.toDate)} ខែ${toKhMonth(this.toDate)} ឆ្នាំ${toKhYear(this.toDate)}`
        this.periodTextDaily = `ថ្ងៃទី${toKhDay(this.fromDate)} ខែ${toKhMonth(this.fromDate)} ឆ្នាំ${toKhYear(this.fromDate)}`
        this.periodMonthDaily = `ចាប់ពីខែ${toKhMonth(this.fromDate)} ឆ្នាំ${toKhYear(this.fromDate)} ដល់ខែ${toKhMonth(this.toDate)} ឆ្នាំ${toKhYear(this.toDate)}`
    }

    @action
    async fetchConfig(queryParams) {
        // const { inspection, business, fromdate, todate, province } = queryParams;
        // const businessData = pushToArray(await this.ds.businessRef().get().toPromise())
        // this.autoBusinessData = businessData;
        // this.selectedBusiness = businessData[0];
        // const envDoc = await this.ds.sys_environmentRef().get().toPromise();
        // const envData: any = MappingService.pushToObject(envDoc);
        // const inspectionDoc = await this.ds.inspectionsRef().doc(inspection ? inspection : envData.inspectionKey).get().toPromise();
        // const inspectionData = MappingService.pushToObject(inspectionDoc);
        // this.selectedInspection = inspectionData;
        // const provinceDoc = await this.ds.docCollectionRef('geo_provinces').doc(province ? province : 'Z4WBUBqOiTC5aUjViznL').get().toPromise();
        // const provinceData = MappingService.pushToObject(provinceDoc);
        // this.selectedProvince = provinceData;
        // this.fromDateKey = ConvertService.toDateKey(this.selectedInspection.startDate.toDate());
        // this.toDateKey = ConvertService.toDateKey(this.selectedInspection.endDate.toDate());
        // this.getPeriod(fromdate ? fromdate : this.fromDateKey, todate ? todate : this.toDateKey)
        // if (business) {
        //     const businessDoc = await this.ds.docCollectionRef('business').doc(business).get().toPromise();
        //     const businessData = MappingService.pushToObject(businessDoc);
        //     this.selectedBusiness = businessData;
        // }
    }

    @action
    async fetchReport1(queryParams) {
        this.loading = true;
        const { inspection, business, fromdate, todate } = queryParams;
        let link = `${this.api.baseUri}fetchReporting1?inspectionKey=${inspection ? inspection : this.selectedInspection.key}&businessKey=${business ? business : this.selectedBusiness.key}&fromDate=${fromdate ? fromdate : this.fromDateKey}&toDate=${todate ? todate : this.toDateKey}`;
        this.api.get(link).then(async (res) => {
            if (res && res.data) {
                const data = res.data
                this.report1Data = data.data;
            }
            this.loading = false;
        });
    }

    @action
    async fetchReport2(queryParams) {
        this.loading = true;
        const { inspection, business } = queryParams;
        let link = `${this.api.baseUri}fetchReporting2?inspectionKey=${inspection ? inspection : this.selectedInspection.key}&businessKey=${business ? business : this.selectedBusiness.key}`;
        this.api.get(link).then(async (res) => {
            if (res && res.data) {
                const data = res.data
                this.report2Data = data
            }
            this.loading = false;
        });
    }

    @action
    async fetchReport3(queryParams) {
        this.loading = true;
        const { inspection, fromdate, todate, province } = queryParams;
        let link = `${this.api.baseUri}fetchReporting3?inspectionKey=${inspection ? inspection : this.selectedInspection.key}&provinceKey=${province ? province : this.selectedProvince.key}&fromDate=${fromdate ? fromdate : this.fromDateKey}&toDate=${todate ? todate : this.toDateKey}`;
        this.api.get(link).then(async (res) => {
            if (res && res.data) {
                const data = res.data
                this.report3Data = data
            }
            this.loading = false;
        });
    }

    @action
    async fetchReport4(queryParams) {
        this.loading = true;
        const { inspection, fromdate, todate } = queryParams;
        let link = `${this.api.baseUri}fetchReporting4?inspectionKey=${inspection ? inspection : this.selectedInspection.key}&fromDate=${fromdate ? fromdate : this.fromDateKey}&toDate=${todate ? todate : this.toDateKey}`;
        this.api.get(link).then(async (res) => {
            if (res && res.data) {
                const data = res.data
                this.report4Data = data
            }
            this.loading = false;
        });
    }

    @action
    async fetchTestCovidReport(type: any, queryParams) {
        this.loading = true;
        this.selectedPost = null
        this.reportTestCovidTotay = []
        this.reportTestCovidTotayS = null
        const { postKey, fromdate, todate } = queryParams;

        this.fromDateNum = fromdate ? fromdate : `${ConvertService.dateKey()}`
        this.toDateNum = todate ? todate : `${ConvertService.dateKey()}`
        this.getPeriod(this.fromDateNum, this.toDateNum)

        if (postKey) {
            const postData = await this.ds.docRef("posts", postKey).get().toPromise()
            this.selectedPost = MappingService.pushToObject(postData)
        }

        const filterDate = ConvertService.numberToDate(this.fromDateNum)
        const reportDoc = await this.ds.reportTestConditionRef(postKey, type, filterDate).get().toPromise()
        this.reportTestCovidTotay = MappingService.pushToArray(reportDoc)
        this.reportTestCovidTotay = sortBy(this.reportTestCovidTotay, ["result_test.key", "full_name"])

        if (this.reportTestCovidTotay && this.reportTestCovidTotay.length > 0) {

            const total = this.reportTestCovidTotay.length
            const totalF = this.reportTestCovidTotay.filter(m => m.gender && m.gender.key === 0).length

            const totalN = this.reportTestCovidTotay.filter(m => m.result_test && m.result_test.key === RESULT_QUICK_TEST.NEGATIVE.key).length
            const totalNF = this.reportTestCovidTotay.filter(m => m.result_test && m.result_test.key === RESULT_QUICK_TEST.NEGATIVE.key && m.gender && m.gender.key === 0).length

            const totalP = this.reportTestCovidTotay.filter(m => m.result_test && m.result_test.key === RESULT_QUICK_TEST.POSITIVE.key).length
            const totalPF = this.reportTestCovidTotay.filter(m => m.result_test && m.result_test.key === RESULT_QUICK_TEST.POSITIVE.key && m.gender && m.gender.key === 0).length

            this.reportTestCovidTotayS = {
                total: total,
                totalF: totalF,
                totalN: totalN,
                totalNF: totalNF,
                totalP: totalP,
                totalPF: totalPF,
            }
        }
        this.loading = false;
    }


    @action
    async fetchTestCovidNeedResearch(type: any, queryParams) {
        this.loading = true;
        this.selectedPost = null
        this.reportTestCovidNeedResearch = []
        this.reportTestCovidNeedResearchS = null
        const { postKey, fromdate, todate } = queryParams;

        this.fromDateNum = fromdate ? fromdate : `${ConvertService.dateKey()}`
        this.toDateNum = todate ? todate : `${ConvertService.dateKey()}`
        this.getPeriod(this.fromDateNum, this.toDateNum)

        if (postKey) {
            const postData = await this.ds.docRef("posts", postKey).get().toPromise()
            this.selectedPost = MappingService.pushToObject(postData)
        }

        const filterDate = ConvertService.numberToDate(this.fromDateNum)
        const reportDoc = await this.ds.reportTestConditionRef(postKey, type, filterDate).get().toPromise()
        this.reportTestCovidNeedResearch = MappingService.pushToArray(reportDoc)
        this.reportTestCovidNeedResearch = sortBy(this.reportTestCovidNeedResearch, ["province.id", "district.id", "commune.id"])

        if (this.reportTestCovidNeedResearch && this.reportTestCovidNeedResearch.length > 0) {

            const total = this.reportTestCovidNeedResearch.length
            const totalF = this.reportTestCovidNeedResearch.filter(m => m.gender && m.gender.key === 0).length

            const driver = this.reportTestCovidNeedResearch.filter(m => m.type_of_passenger && m.type_of_passenger.key === PASSENGER_TYPE.DRIVER.key).length
            const driverF = this.reportTestCovidNeedResearch.filter(m => m.type_of_passenger && m.type_of_passenger.key === PASSENGER_TYPE.DRIVER.key && m.gender && m.gender.key === 0).length

            const passenger = this.reportTestCovidNeedResearch.filter(m => m.type_of_passenger && m.type_of_passenger.key === PASSENGER_TYPE.PASSENGER.key).length
            const passengerF = this.reportTestCovidNeedResearch.filter(m => m.type_of_passenger && m.type_of_passenger.key === PASSENGER_TYPE.PASSENGER.key && m.gender && m.gender.key === 0).length

            this.reportTestCovidNeedResearchS = {
                total: total,
                totalF: totalF,
                driver: driver,
                driverF: driverF,
                passenger: passenger,
                passengerF: passengerF,
            }

        }

        this.loading = false;
    }

    @action
    async fetchTestCovidResearchLeft(queryParams) {
        this.loading = true;
        this.selectedPost = null
        this.reportTestCovidResearchLeft = []
        this.reportTestCovidResearchLeftS = null
        const { postKey, fromdate, todate } = queryParams;

        this.fromDateNum = fromdate ? fromdate : `${ConvertService.dateKey()}`
        this.toDateNum = todate ? todate : `${ConvertService.dateKey()}`

        this.getPeriod(this.fromDateNum, this.toDateNum)

        if (postKey) {
            const postData = await this.ds.docRef("posts", postKey).get().toPromise()
            this.selectedPost = MappingService.pushToObject(postData)
        }

        const reportDoc = await this.ds.reportTestResearchLeftRef(postKey, this.fromDateNum, this.toDateNum).get().toPromise()
        this.reportTestCovidResearchLeft = MappingService.pushToArray(reportDoc)
        this.reportTestCovidResearchLeft = sortBy(this.reportTestCovidResearchLeft, ["testedDateKey", "full_name"])

        if (this.reportTestCovidResearchLeft && this.reportTestCovidResearchLeft.length > 0) {

            const total = this.reportTestCovidResearchLeft.length
            const totalF = this.reportTestCovidResearchLeft.filter(m => m.gender && m.gender.key === 0).length

            const driver = this.reportTestCovidResearchLeft.filter(m => m.type_of_passenger && m.type_of_passenger.key === PASSENGER_TYPE.DRIVER.key).length
            const driverF = this.reportTestCovidResearchLeft.filter(m => m.type_of_passenger && m.type_of_passenger.key === PASSENGER_TYPE.DRIVER.key && m.gender && m.gender.key === 0).length

            const passenger = this.reportTestCovidResearchLeft.filter(m => m.type_of_passenger && m.type_of_passenger.key === PASSENGER_TYPE.PASSENGER.key).length
            const passengerF = this.reportTestCovidResearchLeft.filter(m => m.type_of_passenger && m.type_of_passenger.key === PASSENGER_TYPE.PASSENGER.key && m.gender && m.gender.key === 0).length

            this.reportTestCovidResearchLeftS = {
                total: total,
                totalF: totalF,
                driver: driver,
                driverF: driverF,
                passenger: passenger,
                passengerF: passengerF,
            }

        }

        this.loading = false;
    }

    @action
    async fetchTestCovidResultResearch(type: any, queryParams) {
        this.loading = true;
        this.selectedPost = null
        this.reportTestCovidResultResearch = []
        this.reportTestCovidResultResearchS = null
        const { postKey, fromdate, todate } = queryParams;

        this.fromDateNum = fromdate ? fromdate : `${ConvertService.dateKey()}`
        this.toDateNum = todate ? todate : `${ConvertService.dateKey()}`
        this.getPeriod(this.fromDateNum, this.toDateNum)

        if (postKey) {
            const postData = await this.ds.docRef("posts", postKey).get().toPromise()
            this.selectedPost = MappingService.pushToObject(postData)
        }

        const filterDate = ConvertService.numberToDate(this.fromDateNum)
        const reportDoc = await this.ds.reportTestConditionRef(postKey, type, filterDate).get().toPromise()
        this.reportTestCovidResultResearch = MappingService.pushToArray(reportDoc)
        this.reportTestCovidResultResearch = sortBy(this.reportTestCovidResultResearch, ["result_test.key", "full_name"])

        if (this.reportTestCovidResultResearch && this.reportTestCovidResultResearch.length > 0) {

            const total = this.reportTestCovidResultResearch.length
            const totalF = this.reportTestCovidResultResearch.filter(m => m.gender && m.gender.key === 0).length

            const totalN = this.reportTestCovidResultResearch.filter(m => m.result_test && m.result_test.key === RESULT_QUICK_TEST.NEGATIVE.key).length
            const totalNF = this.reportTestCovidResultResearch.filter(m => m.result_test && m.result_test.key === RESULT_QUICK_TEST.NEGATIVE.key && m.gender && m.gender.key === 0).length

            const totalP = this.reportTestCovidResultResearch.filter(m => m.result_test && m.result_test.key === RESULT_QUICK_TEST.POSITIVE.key).length
            const totalPF = this.reportTestCovidResultResearch.filter(m => m.result_test && m.result_test.key === RESULT_QUICK_TEST.POSITIVE.key && m.gender && m.gender.key === 0).length

            this.reportTestCovidResultResearchS = {
                total: total,
                totalF: totalF,
                totalN: totalN,
                totalNF: totalNF,
                totalP: totalP,
                totalPF: totalPF,
            }
        }

        this.loading = false;
    }

    @action
    async fetchPassengerListing(queryParams) {
        this.loading = true;
        this.selectedPost = null
        const { postKey, travelKey, fromdate, todate } = queryParams;

        this.selectedTravel = travelKey ? TRAVEL_TYPE.find(m => m.key === toNumber(travelKey)) : TRAVEL_TYPE[0]
        this.fromDateNum = fromdate ? fromdate : ConvertService.dateKey()
        this.toDateNum = todate ? todate : ConvertService.dateKey()
        this.getPeriod(this.fromDateNum, this.toDateNum)
        let link = `${this.api.baseUri}fetchPessengerListing?fromDate=${this.fromDateNum}&toDate=${this.toDateNum}`

        if (this.selectedTravel) {
            link = `${link}&travelKey=${this.selectedTravel.key}`
        }

        if (postKey) {
            link = `${link}&postKey=${postKey}`
            const postData = await this.ds.docRef("posts", postKey).get().toPromise()
            this.selectedPost = MappingService.pushToObject(postData)
        }

        this.api.get(link).then(async (res) => {
            if (res && res.data) {
                const data = res.data
                this.reportPassengerListing = data

                // this.reportPassengerListing.data = MappingService.orderBy(data.data.filter(m=>m.id_number === "021212467"),"id_number")
                // this.reportPassengerListing.data = MappingService.orderBy(data.data,"id_number")
            }
            this.loading = false;
        });
    }

    @action
    async fetchPassengerStatistic(queryParams) {
        this.loading = true;
        this.selectedPost = null
        const { postKey, travelKey, fromdate, todate } = queryParams;
        this.selectedTravel = travelKey ? TRAVEL_TYPE.find(m => m.key === toNumber(travelKey)) : TRAVEL_TYPE[0]
        this.fromDateNum = fromdate ? fromdate : ConvertService.dateKey()
        this.toDateNum = todate ? todate : ConvertService.dateKey()
        this.getPeriod(this.fromDateNum, this.toDateNum)
        let link = `${this.api.baseUri}fetchPessengerStatistic?fromDate=${this.fromDateNum}&toDate=${this.toDateNum}`

        if (this.selectedTravel) {
            link = `${link}&travelKey=${this.selectedTravel.key}`
        }
        if (postKey) {
            link = `${link}&postKey=${postKey}`
            const postData = await this.ds.docRef("posts", postKey).get().toPromise()
            this.selectedPost = MappingService.pushToObject(postData)
        }
        this.api.get(link).then(async (res) => {
            if (res && res.data) {
                const data = res.data
                this.reportPassengerStatistic = data
            }
            this.loading = false;
        });
    }

    @action
    async fetchStatisticTravelInOut(queryParams) {
        this.loading = true;
        this.selectedPost = null
        const { postKey, travelKey, fromdate, todate } = queryParams;
        this.selectedTravel = travelKey ? TRAVEL_TYPE.find(m => m.key === toNumber(travelKey)) : TRAVEL_TYPE[0]
        this.fromDateNum = fromdate ? fromdate : ConvertService.dateKey()
        this.toDateNum = todate ? todate : ConvertService.dateKey()
        this.getPeriod(this.fromDateNum, this.toDateNum)
        let link = `${this.api.baseUri}fetchStatisticInOut?fromDate=${this.fromDateNum}&toDate=${this.toDateNum}`

        if (this.selectedTravel) {
            link = `${link}&travelKey=${this.selectedTravel.key}`
        }
        if (postKey) {
            link = `${link}&postKey=${postKey}`
            const postData = await this.ds.docRef("posts", postKey).get().toPromise()
            this.selectedPost = MappingService.pushToObject(postData)
        }
        this.api.get(link).then(async (res) => {
            if (res && res.data) {
                const data = res.data
                this.reportTravelInOut = data
            }
            this.loading = false;
        });
    }

    @action
    async fetchPassengerStatisticDaily(queryParams) {
        this.loading = true;
        this.selectedPost = null
        const { postKey, travelKey, fromdate, todate } = queryParams;

        this.selectedTravel = travelKey ? TRAVEL_TYPE.find(m => m.key === toNumber(travelKey)) : TRAVEL_TYPE[0]
        this.fromDateNum = fromdate ? fromdate : ConvertService.dateKey()
        this.toDateNum = todate ? todate : ConvertService.dateKey()
        this.getPeriod(this.fromDateNum, this.toDateNum)
        let link = `${this.api.baseUri}fetchPessengerStatistic?fromDate=${this.fromDateNum}&toDate=${this.fromDateNum}`

        if (this.selectedTravel) {
            link = `${link}&travelKey=${this.selectedTravel.key}`
        }

        if (postKey) {
            link = `${link}&postKey=${postKey}`
            const postData = await this.ds.docRef("posts", postKey).get().toPromise()
            this.selectedPost = MappingService.pushToObject(postData)
        }

        this.api.get(link).then(async (res) => {
            if (res && res.data) {
                const data = res.data
                this.reportPassengerHealth = data
            }
            this.loading = false;
        });
    }


    @action
    async fetchThaiPassengerListing(queryParams) {
        this.loading = true;
        this.selectedPost = null
        const { postKey, travelKey, fromdate, todate } = queryParams;

        this.selectedTravel = travelKey ? TRAVEL_TYPE.find(m => m.key === toNumber(travelKey)) : TRAVEL_TYPE[0]
        this.fromDateNum = fromdate ? fromdate : ConvertService.dateKey()
        this.toDateNum = todate ? todate : ConvertService.dateKey()
        this.getPeriod(this.fromDateNum, this.toDateNum)
        let link = `${this.api.baseUri}fetchThaiPassengerListing?fromDate=${this.fromDateNum}&toDate=${this.toDateNum}`

        if (this.selectedTravel) {
            link = `${link}&travelKey=${this.selectedTravel.key}`
        }

        if (postKey) {
            link = `${link}&postKey=${postKey}`
            const postData = await this.ds.docRef("posts", postKey).get().toPromise()
            this.selectedPost = MappingService.pushToObject(postData)
        }

        this.api.get(link).then(async (res) => {
            if (res && res.data) {
                const data = res.data
                this.reportThaiPassengerListing = data
            }
            this.loading = false;
        });
    }

    @action
    async fetchThaiStatisticTravelInOut(queryParams) {
        this.loading = true;
        this.selectedPost = null
        const { postKey, travelKey, fromdate, todate } = queryParams;
        this.selectedTravel = travelKey ? TRAVEL_TYPE.find(m => m.key === toNumber(travelKey)) : TRAVEL_TYPE[0]
        this.fromDateNum = fromdate ? fromdate : ConvertService.dateKey()
        this.toDateNum = todate ? todate : ConvertService.dateKey()
        this.getPeriod(this.fromDateNum, this.toDateNum)
        let link = `${this.api.baseUri}fetchThaiStatisticInOut?fromDate=${this.fromDateNum}&toDate=${this.toDateNum}`

        if (this.selectedTravel) {
            link = `${link}&travelKey=${this.selectedTravel.key}`
        }
        if (postKey) {
            link = `${link}&postKey=${postKey}`
            const postData = await this.ds.docRef("posts", postKey).get().toPromise()
            this.selectedPost = MappingService.pushToObject(postData)
        }
        this.api.get(link).then(async (res) => {
            if (res && res.data) {
                const data = res.data
                this.reportThaiTravelInOut = data
            }
            this.loading = false;
        });
    }

    @action
    async fetchStatisticTravelInOutReport1(queryParams) {
        this.loading = true;
        this.selectedPost = null
        this.reportTravelInOutReport1 = []
        const { postKey, travelKey, fromdate, todate } = queryParams;
        this.selectedTravel = travelKey ? TRAVEL_TYPE.find(m => m.key === toNumber(travelKey)) : TRAVEL_TYPE[0]
        this.fromDateNum = fromdate ? fromdate : ConvertService.dateKey()
        this.toDateNum = todate ? todate : ConvertService.dateKey()
        this.getPeriod(this.fromDateNum, this.toDateNum)
        let link = `${this.api.baseUri}fetchStatisticInOutReport1?fromDate=${this.fromDateNum}&toDate=${this.toDateNum}`

        if (this.selectedTravel) {
            link = `${link}&travelKey=${this.selectedTravel.key}`
        }
        if (postKey) {
            link = `${link}&postKey=${postKey}`
            const postData = await this.ds.docRef("posts", postKey).get().toPromise()
            this.selectedPost = MappingService.pushToObject(postData)

            this.api.get(link).then(async (res) => {
                if (res && res.data) {
                    const data = res.data
                    this.reportTravelInOutReport1 = data
                }
                this.loading = false;
            });
        } else {
            this.reportTravelInOutReport1 = []
            this.loading = false;
        }
    }

    @action
    async fetchStatisticTravelInOutReport2(queryParams) {
        this.loading = true;
        this.selectedPost = null
        this.reportTravelInOutReport2 = []
        const { postKey, travelKey, fromdate, todate } = queryParams;
        this.selectedTravel = travelKey ? TRAVEL_TYPE.find(m => m.key === toNumber(travelKey)) : TRAVEL_TYPE[0]
        this.fromDateNum = fromdate ? fromdate : ConvertService.dateKey()
        this.toDateNum = todate ? todate : ConvertService.dateKey()
        this.getPeriod(this.fromDateNum, this.toDateNum)
        let link = `${this.api.baseUri}fetchStatisticInOutReport2?fromDate=${this.fromDateNum}&toDate=${this.toDateNum}`

        if (this.selectedTravel) {
            link = `${link}&travelKey=${this.selectedTravel.key}`
        }
        if (postKey) {
            link = `${link}&postKey=${postKey}`

            const postData = await this.ds.docRef("posts", postKey).get().toPromise()
            this.selectedPost = MappingService.pushToObject(postData)

            this.api.get(link).then(async (res) => {
                if (res && res.data) {
                    const data = res.data
                    this.reportTravelInOutReport2 = data
                }
                this.loading = false;
            });
        } else {
            this.reportTravelInOutReport2 = []
            this.loading = false;
        }
    }

    @action
    async fetchThaiStatisticTravelInOutReport1(queryParams) {
        this.loading = true;
        this.selectedPost = null
        this.reportThaiTravelInOutReport1 = []
        const { postKey, travelKey, fromdate, todate } = queryParams;
        this.selectedTravel = travelKey ? TRAVEL_TYPE.find(m => m.key === toNumber(travelKey)) : TRAVEL_TYPE[0]
        this.fromDateNum = fromdate ? fromdate : ConvertService.dateKey()
        this.toDateNum = todate ? todate : ConvertService.dateKey()
        this.getPeriod(this.fromDateNum, this.toDateNum)
        let link = `${this.api.baseUri}fetchThaiStatisticInOutReport1?fromDate=${this.fromDateNum}&toDate=${this.toDateNum}`

        if (this.selectedTravel) {
            link = `${link}&travelKey=${this.selectedTravel.key}`
        }
        if (postKey) {
            link = `${link}&postKey=${postKey}`
            const postData = await this.ds.docRef("posts", postKey).get().toPromise()
            this.selectedPost = MappingService.pushToObject(postData)

            this.api.get(link).then(async (res) => {
                if (res && res.data) {
                    const data = res.data
                    this.reportThaiTravelInOutReport1 = data
                }
                this.loading = false;
            });
        } else {
            this.reportThaiTravelInOutReport1 = []
            this.loading = false;
        }
    }

    @action
    async fetchThaiStatisticTravelInOutReport2(queryParams) {
        this.loading = true;
        this.selectedPost = null
        this.reportThaiTravelInOutReport2 = []
        const { postKey, travelKey, fromdate, todate } = queryParams;
        this.selectedTravel = travelKey ? TRAVEL_TYPE.find(m => m.key === toNumber(travelKey)) : TRAVEL_TYPE[0]
        this.fromDateNum = fromdate ? fromdate : ConvertService.dateKey()
        this.toDateNum = todate ? todate : ConvertService.dateKey()
        this.getPeriod(this.fromDateNum, this.toDateNum)
        let link = `${this.api.baseUri}fetchThaiStatisticInOutReport2?fromDate=${this.fromDateNum}&toDate=${this.toDateNum}`

        if (this.selectedTravel) {
            link = `${link}&travelKey=${this.selectedTravel.key}`
        }
        if (postKey) {
            link = `${link}&postKey=${postKey}`

            const postData = await this.ds.docRef("posts", postKey).get().toPromise()
            this.selectedPost = MappingService.pushToObject(postData)

            this.api.get(link).then(async (res) => {
                if (res && res.data) {
                    const data = res.data
                    this.reportThaiTravelInOutReport2 = data
                }
                this.loading = false;
            });
        } else {
            this.reportThaiTravelInOutReport2 = []
            this.loading = false;
        }
    }
}

