export const roleObj = {
  administrator: { key: 1, text: 'រដ្ឋបាលថ្នាក់ជាតិ', name: "Administrator" },
  readOnly: { key: 2, text: 'រដ្ឋបាលថ្នាក់ខេត្ត', name: "Read only" },
  readWrite: { key: 3, text: 'រដ្ឋបាលទូទៅ', name: "Read/Write" },
};

export const roleArray = [
  { key: 1, text: 'រដ្ឋបាលថ្នាក់ជាតិ', name: "Administrator" },
  { key: 2, text: 'រដ្ឋបាលថ្នាក់ខេត្ត', name: "Read only" },
  { key: 3, text: 'រដ្ឋបាលទូទៅ', name: "Read/Write" },
];


export const userRoleArray = [
  { key: 1, text: 'ថ្នាក់ជាតិ', },
  { key: 2, text: 'ថ្នាក់ស្នងការ' },
  { key: 3, text: 'ថ្នាក់នាយកដ្ឋាន', },
  { key: 4, text: 'ថ្នាក់មន្ត្រីនគរបាល', },
];

export const userRoleObject = {
  NationalLevel: { key: 1, text: 'ថ្នាក់ជាតិ', },
  CommissionerLevel: { key: 2, text: 'ថ្នាក់ស្នងការ' },
  DepartmentLevel: { key: 3, text: 'ថ្នាក់នាយកដ្ឋាន', },
  PoliceLevel: { key: 4, text: 'ថ្នាក់មន្ត្រីនគរបាល', },
};
