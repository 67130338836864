import { Component, Input, OnInit } from '@angular/core';
import { toJS } from 'mobx';
import { multi } from 'src/app/core/dummy/app';

@Component({
  selector: 'app-grouped-vertical-bar-chart',
  templateUrl: './grouped-vertical-bar-chart.component.html',
  styleUrls: ['./grouped-vertical-bar-chart.component.scss']
})
export class GroupedVerticalBarChartComponent implements OnInit {

  @Input() data: any;
  
  multi: any[]=[];
  view: [number,number] = [2500, 610];

  // options
  showXAxis: boolean = true;
  showYAxis: boolean = true;
  gradient: boolean = false;
  showLegend: boolean = true;
  showXAxisLabel: boolean = true;
  // xAxisLabel: string = 'Country';
  showYAxisLabel: boolean = true;
  yAxisLabel: string = '';
  legendTitle: string = 'Years';
  

  colorScheme = {
    domain: ['#00a6ed', '#7fb800', '#ffb400','#7209b7']
  };

  constructor() {
    
  }
  result = []
  ngOnInit() {
    Object.assign(this, { multi })
    
    if(this.data && this.data.length){
      this.result = this.data.map(m=>{
        return{
          name: `${m.postName} `,
          series: [
            {
              name: "សរុប",
              value: m.total
            },
            {
              name: "ស្រី",
              value: m.totalF
            },
            {
              name: "អ្នកបើកបរ",
              value: m.driver
            },
            {
              name: "អ្នករួមដំណើរ",
              value: m.passenger
            }
          ]
        }
      })

      
    }
  }
  
  onSelect(data): void {
    console.log('Item clicked', JSON.parse(JSON.stringify(data)));
  }

  onActivate(data): void {
    console.log('Activate', JSON.parse(JSON.stringify(data)));
  }

  onDeactivate(data): void {
    console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }
}
