import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAnalyticsModule } from '@angular/fire/analytics';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { environment } from '../environments/environment';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { DashboardLayoutComponent } from './layouts/dashboard-layout/dashboard-layout.component';
import { HomeLayoutComponent } from './layouts/home-layout/home-layout.component';
import { FooterComponent } from './layouts/footer/footer.component'
import { HeaderComponent } from './layouts/header/header.component';
import { SharedModule } from './shared/shared.module'
import { CoreModule } from './core/core.module';
import { DashboardNavComponent } from './layouts/dashboard-nav/dashboard-nav.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { ReportLayoutComponent } from './layouts/report-layout/report-layout.component';
import { AgmCoreModule } from '@agm/core';
import { MapViewComponent } from './modules/map-view/pages/map-view/map-view.component';
import { AgmOverlays } from "agm-overlays"
// import { AgmJsMarkerClustererModule } from '@agm/js-marker-clusterer';
import { ApiService } from '../../src/app/shared/services/api.service';
import { HttpClientModule } from '@angular/common/http';
import { HttpModule } from '@angular/http';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { StatisticPassengerComponent } from './modules/statistic/pages/statistic-passenger/statistic-passenger.component';
import { PieChartComponent } from './modules/statistic/components/pie-chart/pie-chart.component';
import { GroupedVerticalBarChartComponent } from './modules/statistic/components/grouped-vertical-bar-chart/grouped-vertical-bar-chart.component';
import { SecondGroupedVerticalBarChartComponent } from './modules/statistic/components/second-grouped-vertical-bar-chart/second-grouped-vertical-bar-chart.component';
import { StatisticFilterPeriodComponent } from './modules/statistic/components/statistic-filter-period/statistic-filter-period.component';
import { StatisticFilterPostComponent } from './modules/statistic/components/statistic-filter-post/statistic-filter-post.component';
import { StatisticFilterTravelTypeComponent } from './modules/statistic/components/statistic-filter-travel-type/statistic-filter-travel-type.component';


@NgModule({
  declarations: [
    AppComponent,
    AuthLayoutComponent,
    DashboardLayoutComponent,
    HomeLayoutComponent,
    HeaderComponent,
    FooterComponent,
    DashboardNavComponent,
    ReportLayoutComponent,
    MapViewComponent,
    StatisticPassengerComponent,
    PieChartComponent,
    GroupedVerticalBarChartComponent,
    SecondGroupedVerticalBarChartComponent,
    StatisticFilterPeriodComponent,
    StatisticFilterPostComponent,
    StatisticFilterTravelTypeComponent,
   
  ],
  imports: [
    HttpClientModule,
    HttpModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AngularFireModule.initializeApp(environment.firebase),
    // AngularFireAnalyticsModule,
    AngularFirestoreModule,
    CoreModule,
    SharedModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAc6s59GVe1RD2KlgAyktYVJMDoA4rlIFo'
    }),
    AgmOverlays,
    // AgmJsMarkerClustererModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    // MapViewModule
    InfiniteScrollModule,
  ],
  providers: [ApiService],
  bootstrap: [AppComponent]
})
export class AppModule { }
