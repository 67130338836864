import { Pipe, PipeTransform } from '@angular/core';
import { sumArray } from '../services/convert.service';
import { toNumber } from '../services/mapping.service';

@Pipe({
  name: 'filterSumArray'
})
export class FilterSumArrayPipe implements PipeTransform {

  transform(data: Array<any>, field: string): any {
    const result = sumArray(data, field);
    return toNumber(result) !== 0 ? toNumber(result) : '';
  }

}
