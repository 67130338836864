
export const CONFIGS = {
    YEAR: '2017',
    FROM_YEAR: '201706',
    TO_YEAR: '201710',
    FROM_DATE: 20170600,
    TO_DATE: 20171100,
    PERIOD: 'ចាប់ពីថ្ងៃទី 1 ខែមិថុនា, 2017 មកត្រឹម ថ្ងៃទី 31 ខែតុលា, 2017'
}

export const Help = [
    { key: 1, name: "របៀបប្រើប្រាស់" },
    { key: 2, name: "ទំនាក់ទំនងជួយគាំទ្រ" },
    { key: 3, name: "Android Link" },
    { key: 3, name: "iOS Link" },
]

export const TRAVEL_TYPE = [
    { key: 1, text: "ដំណើរចូល", en_name: "Arrival", name: "ចូល" },
    { key: 2, text: "ដំណើរចេញ", en_name: "Departure", name: "ចេញ" }
]

export const PASSENGER_DOC_TYPE_LIST = [
    { type: "PASSENGER-1", key: 1, name: 'រូបថត', en_name: "Photo", icon: 'address-book' },
    { type: "PASSENGER-2", key: 2, name: 'រូបអត្តសញ្ញាណប័ណ្ណ ឬ លិខិតឆ្លងដែន', en_name: "Photo ID Card", icon: 'id-card' },
    { type: "PASSENGER-3", key: 3, name: 'រូបឯកសារផ្សេងៗ', en_name: "Home Photo", icon: 'book-reader' },
]

export const VEHICLE_DOC_TYPE_LIST = [
    { type: "VEHICLE-1", key: 1, name: 'រូបរថយន្ត', en_name: "Photo", icon: 'address-book' },
    { type: "VEHICLE-2", key: 2, name: 'រូបស្លាកលេខរថយន្ត', en_name: "Photo ID Card", icon: 'id-card' },
    { type: "VEHICLE-3", key: 3, name: 'រូបប័ណ្ណបើកបរ', en_name: "Home Photo", icon: 'book-reader' },
    { type: "VEHICLE-4", key: 4, name: 'រូបឯកសារផ្សេងៗ', en_name: "Home Photo", icon: 'book-reader' },
]

export const LIST_COVID_TYPE = {
    tested_today: { key: 1, name: "បញ្ជីអ្នកបានធ្វើតេស្តរហ័សថ្ងៃនេះ", docKey: "tested_today" },
    need_test_today: { key: 2, name: "អ្នកដំណើរត្រូវធ្វើតេស្តលើកទី២នៅថ្ងៃនេះ", docKey: "need_test_today" },
    need_research_today: { key: 3, name: "បញ្ជីត្រូវស្រាវជ្រាវបន្ទាប់72ម៉ោង", docKey: "need_research_today" },
    need_test_72h: { key: 4, name: "អ្នកដំណើរត្រូវធ្វើតេស្តលើកទី២នៅ72ម៉ោងទៀត", docKey: "need_test_72h" },
    first_tested_positive: { key: 5, name: "អ្នកដំណើរធ្វើតេស្តវិជ្ជមានលើកទី១", docKey: "first_tested_positive" },
    second_tested_positive: { key: 6, name: "អ្នកដំណើរធ្វើតេស្តវិជ្ជមានលើកទី២", docKey: "second_tested_positive" },
    second_tested_negative: { key: 7, name: "អ្នកដំណើរធ្វើតេស្តអវិជ្ជមានលើកទី២", docKey: "second_tested_negative" },
    second_no_tested: { key: 8, name: "អ្នកដំណើមិនបានយកសំណាកលើកទី២", docKey: "second_no_tested" },
    result_research_today: { key: 9, name: "បញ្ជីលទ្ធផលអ្នកបានស្រាវជ្រាវនៅថ្ងៃនេះ", docKey: "result_research_today" },
}

export const LIST_COVID_TYPE_ARRAY = [
    { key: 1, name: "បញ្ជីអ្នកបានធ្វើតេស្តរហ័សថ្ងៃនេះ", docKey: "tested_today" },
    // { key: 2, name: "អ្នកដំណើរត្រូវធ្វើតេស្តលើកទី២នៅថ្ងៃនេះ", docKey: "need_test_today" },
    { key: 3, name: "បញ្ជីត្រូវស្រាវជ្រាវបន្ទាប់72ម៉ោង", docKey: "need_research_today" },
    // { key: 4, name: "អ្នកដំណើរត្រូវធ្វើតេស្តលើកទី២នៅ72ម៉ោងទៀត", docKey: "need_test_72h" },
    // { key: 5, name: "អ្នកដំណើរធ្វើតេស្តវិជ្ជមានលើកទី១", docKey: "first_tested_positive" },
    // { key: 6, name: "អ្នកដំណើរធ្វើតេស្តវិជ្ជមានលើកទី២", docKey: "second_tested_positive" },
    // { key: 7, name: "អ្នកដំណើរធ្វើតេស្តអវិជ្ជមានលើកទី២", docKey: "second_tested_negative" },
    // { key: 8, name: "អ្នកដំណើមិនបានយកសំណាកលើកទី២", docKey: "second_no_tested" },
    { key: 9, name: "បញ្ជីលទ្ធផលអ្នកបានស្រាវជ្រាវនៅថ្ងៃនេះ", docKey: "result_research_today" },

]

export const RESULT_QUICK_TEST = {
    POSITIVE: { key: 1, text: "វិជ្ជមាន", en_name: 'Positive' },
    NEGATIVE: { key: 2, text: "អវិជ្ជមាន", en_name: 'Negative' },
}

export const PASSENGER_TYPE = {
    DRIVER: { key: 1, text: "អ្នកបើកបរ", en_name: 'Driver' },
    PASSENGER: { key: 2, text: "អ្នកដំណើរ", en_name: 'Passenger' }
}

export const TEST_TYPE = {
    first: { key: 1, text: " First test", khText: "ធ្វើតេស្តលើកទី១" },
    second: { key: 2, text: " Second test", khText: "ធ្វើតេស្តលើកទី២" },
}

export const STAY_OR_LEAVE_ARRAY = [
    { key: 1, name: "Stay", khName: "កំពុងស្ថិតក្នុងប្រទេស" },
    { key: 2, name: "Leave", khName: "បានចាកចេញ" },
]

export const STAY_OR_LEAVE = {
    STAY: { key: 1, name: "Stay", khName: "កំពុងស្ថិតក្នុងប្រទេស" },
    LEAVE: { key: 2, name: "Leave", khName: "បានចាកចេញ" },
}

export const BORDER_PASS_STATUS = {
    HOLD: { key: 1, name: "Hold", khName: "កំពុងត្រួតពិនិត្យ" },
    SUCCESS: { key: 2, name: "Success", khName: "បានពិនិត្យ" },
}

export const DATE_TYPE_ARRAY = [
    { key: 2, name: "ឆ្លងខែ" },
    { key: 1, name: "ដើមខែចុងខែ" },
]