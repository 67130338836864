import { observable, action, } from 'mobx';
import { Injectable } from '@angular/core';
import { FirebaseService } from '../services/firebase.service';
import { IOfficer } from 'src/app/modules/officer/interfaces/iofficer';
import { IInspection, IMission } from 'src/app/modules/inspection/interfaces/interface';
import { toKhCalendar } from 'src/app/shared/services/mapping.service';
import { pushToArray } from 'src/app/shared/services/convert.service';
import { STATUS_OBJ } from 'src/app/constant/status.constant';

@Injectable({ providedIn: 'root' })
export class ChartStore {

  @observable inspect: IInspection = null;
  @observable process: boolean = false;
  @observable officers: Array<IOfficer> = [];
  @observable missions: Array<IMission> = [];
  @observable searchData = [];
  @observable loading = true;
  @observable loadingMission = true;
  
  @observable notifications = [];

  @observable empty = false;

  @observable optionSearch: any = null;

  constructor(private db: FirebaseService) { }

  @observable fetchOfficerMissionRef: any = null;
  @action
  fetchOfficerMission(levelKey: number, provinceKey, inspectKey) {
    this.fetchOfficerMissionRef = this.db.officerMissionByLevelProvinceRef(levelKey, provinceKey, inspectKey)
      .valueChanges()
      .subscribe((docs: Array<IMission>) => {
        this.missions = docs;
        this.loadingMission = false;
      })
  }

  @observable fetchOfficerRef: any = null;
  @action
  fetchOfficer(levelKey: number, provinceKey) {
    this.fetchOfficerRef = this.db.officerByLevelProvinceRef(levelKey, provinceKey)
      .valueChanges()
      .subscribe((docs: Array<IOfficer>) => {
        this.officers = docs;
        this.loading = false;
        this.empty = docs.length === 0;
      })
  }

  @observable fetchSelectedInspectRef: any = null;
  @action
  fetchSelectedInspect(inspectKey) {
    if (!inspectKey) return;
    this.fetchSelectedInspectRef = this.db.inspectRef().doc(inspectKey)
      .valueChanges()
      .subscribe((doc: IInspection) => {
        this.inspect = { ...doc, startDateKh: toKhCalendar(doc.startDate.toDate()) };
      })
  }

  @action
  fetchNotifications(){
    this.db.notificationsRef().valueChanges().subscribe(data=>{
      this.notifications = data
    })
  }

  @action
  async filterListing(keyword, type, callback) {
    if (keyword) {
      switch (type.key) {
        case 'business':
          this.searchData = [];
          const docs2 = await this.db.firestore().collection("business")
            .where('status.key', '==', STATUS_OBJ.ACTIVE.key)
            .where("keyword", "array-contains", `${keyword}`.toUpperCase())
            .orderBy('keyword')
            .limit(50).get();
          this.searchData = pushToArray(docs2)
          callback(this.searchData)
          break;
        case 'labors':
          this.searchData = [];
          const docs3 = await this.db.firestore().collection("labors")
            .where('status.key', '==', STATUS_OBJ.ACTIVE.key)
            .where("keyword", "array-contains", `${keyword}`.toUpperCase())
            .orderBy('keyword')
            .limit(50).get();
          this.searchData = pushToArray(docs3)
          callback(this.searchData)
          break;
        default:
          break;
      }
    } else {
      switch (type.key) {
        case 'business':
          this.searchData = [];
          const docs2 = await this.db.firestore().collection("business")
            .where('status.key', '==', STATUS_OBJ.ACTIVE.key)
            .limit(10).get();
          this.searchData = pushToArray(docs2)
          callback(this.searchData)
          break;
        case 'labors':
          this.searchData = [];
          const docs3 = await this.db.firestore().collection("labors")
            .where('status.key', '==', STATUS_OBJ.ACTIVE.key)
            .limit(10).get();
          this.searchData = pushToArray(docs3)
          callback(this.searchData)
          break;
        default:
          break;
      }
    }
  }


}
