import { Component, Input, OnInit } from '@angular/core';
import { PresenceService } from '../../services/presence.service';

@Component({
  selector: 'app-presence-status',
  templateUrl: './presence-status.component.html',
  styleUrls: ['./presence-status.component.scss']
})
export class PresenceStatusComponent implements OnInit {
  @Input() uid: any;
  presence$: any;
  constructor(public presence: PresenceService) { }

  ngOnInit(): void {
    if (this.uid) {
      this.presence$ = this.presence.getPresence(this.uid);
    }
  }

}
