import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { ActivatedRoute, Router } from '@angular/router';
import { multi, multi_2, single } from 'src/app/core/dummy/app';
import { AuthStore } from 'src/app/core/stores/auth.store';
import { StatisticPassenger } from 'src/app/modules/statistic/stores/StatisticPassenger.store';

@Component({
  selector: 'app-statistic-passenger',
  templateUrl: './statistic-passenger.component.html',
  styleUrls: ['./statistic-passenger.component.scss']
})
export class StatisticPassengerComponent implements OnInit {

  

  @ViewChild('table') table: ElementRef;
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  single: any[] =[];
  view: [number,number] = [800, 300];

  // options
  gradient: boolean = true;
  showLegend: boolean = true;
  showLabels: boolean = true;
  isDoughnut: boolean = false;
  legendPosition: string = 'below';

  colorScheme = {
    domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA']
  };


  multi: any[]=[];
  view1: [number,number] = [600, 610];

  // options
  showXAxis: boolean = true;
  showYAxis: boolean = true;
  gradient1: boolean = true;
  showLegend1: boolean = true;
  showXAxisLabel: boolean = true;
  // xAxisLabel: string = 'Country';
  showYAxisLabel: boolean = true;
  yAxisLabel: string = '';
  legendTitle: string = 'Years';

  colorScheme1 = {
    domain: ['#0096c7', '#00b4d8', '#0077b6']
  };


  multi_2: any[]=[];
  view2: [number,number] = [600, 610];

  // options
  showXAxis2: boolean = true;
  showYAxis2: boolean = true;
  gradient2: boolean = true;
  showLegend2: boolean = true;
  showXAxisLabel2: boolean = true;
  // xAxisLabel: string = 'Country';
  showYAxisLabel2: boolean = true;
  yAxisLabel2: string = '';
  legendTitle2: string = 'Years';

  colorScheme2 = {
    domain: ['#7f4f24', '#b6ad90', '#a68a64']
  };

  constructor(
    public store: StatisticPassenger,
   
    private route: ActivatedRoute,
    
    public auth: AuthStore,
    private router: Router,
  ) {
    // Object.assign(this, { single });
    // Object.assign(this, { multi });
    // Object.assign(this, { multi_2 });
    // this.data_outside_1 = multi;
    
   }

  async ngOnInit() {
    await this.auth.fetchUserDoc()

    this.route.queryParams.subscribe(async queryParams => {
      const { postKey, fromdate, todate } = queryParams
      const { profile } = this.auth
      const { post, role } = profile

      if (!postKey && (role.key === 2 || role.key === 3)) {
        this.router.navigate(['/reports/travel-in-out'], {
          queryParams:
          {
            postKey: post.key,
            fromdate: fromdate,
            todate: todate,
          }
        });

      } else {

        this.store.fetchStatisticKhmer(queryParams)
        this.store.fetchStatisticThai(queryParams)
      }
    })
  }
  onSelect(data): void {
    console.log('Item clicked', JSON.parse(JSON.stringify(data)));
  }

  onActivate(data): void {
    console.log('Activate', JSON.parse(JSON.stringify(data)));
  }

  onDeactivate(data): void {
    console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }

}
