import { Pipe, PipeTransform } from '@angular/core';
import { toKhDate } from '../services/mapping.service';

@Pipe({
  name: 'khCalendar'
})
export class KhCalendarPipe implements PipeTransform {

  transform(value: Date, ...args: unknown[]): unknown {
    if(!value) return null;
    return toKhDate(value);
  }

}
