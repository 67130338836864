export const Pages = {
    size: 30,
    debounceTime: 300,
    // logo: '../../../assets/images/cnp-logo.png',
    defaultPeriod: 4,
}

export const CONFIG = {
    V1: { key: "6tXWA9yBYN9IDcozToXj", name: "ដឹកទំនិញ" },
    V2: { key: "Ngrz9Bce1s6rStVw8Ca4", name: "អ្នកដំណើរ" },
    V3: { key: "vSsciN5tZqKeFoyhZXpA", name: "ម៉ូតូ" },

    P_PPKey: "tAlCxZbzNDir6fgLiR4o",
    P_LaosKey: "p5zJymxIAR1Jb4PtDqUU",

    PP: {
      key: "tAlCxZbzNDir6fgLiR4o",
      name: "ភ្នំពេញ",
      name_en: "Phnom Penh",
    },

    AIR_BORDER: [
      "tAlCxZbzNDir6fgLiR4o",
      "i2XbfJ6JU9QHe6DsTv24",
      "w0xR7rGZaUtX7eBq301w",
  ],

  N2_KEY: "hfputQTs2SrlB5VmrIbb",
  VOA_KEY: "hfputQTs2SrlB5VmrIbb"
}
export var single = [
    {
      "name": "អ្នកបើកបរ",
      "value": 8940000
    },
    {
      "name": "អ្នករួមដំណើរ",
      "value": 5000000
    }
  ];

  export var multi = [
    {
      "name": "បន្ទាយមានជ័យ,ប៉ោយប៉ែត",
      "series": [
        {
          "name": "សរុប",
          "value": 73
        },
        {
          "name": "ស្រី",
          "value": 15
        },
        {
          "name": "អ្នកបើកបរ",
          "value": 21
        },
        {
          "name": "អ្នករួមដំណើរ",
          "value": 52
        }
      ]
    },
  
    {
      "name": "បាត់ដំបង",
      "series": [
        {
          "name": "សរុប",
          "value": 100
        },
        {
          "name": "ស្រី",
          "value": 25
        },
        {
          "name": "អ្នកបើកបរ",
          "value": 21
        },
        {
          "name": "អ្នករួមដំណើរ",
          "value": 52
        }
        
      ]
    },
  
    {
      "name": "កោះកុង",
      "series": [
        {
          "name": "សរុប",
          "value": 150
        },
        {
          "name": "ស្រី",
          "value": 44
        },
        {
          "name": "អ្នកបើកបរ",
          "value": 50
        },
        {
          "name": "អ្នករួមដំណើរ",
          "value": 100
        }
      ]
    }
  ];
  export var multi_2 = [
    {
      "name": "បន្ទាយមានជ័យ,ប៉ោយប៉ែត",
      "series": [
        {
          "name": "រថយន្តសរុប",
          "value": 60
        },
        {
          "name": "ដឺកទំនិញ",
          "value": 100
        },
        {
          "name": "អ្នកដំណើរ",
          "value": 250
        }
      ]
    },
  
    {
      "name": "បាត់ដំបង",
      "series": [
        {
          "name": "រថយន្តសរុប",
          "value": 20
        },
        {
          "name": "ដឺកទំនិញ",
          "value": 60
        },
        {
          "name": "អ្នកដំណើរ",
          "value": 25
        }
      ]
    },
  
    {
      "name": "កោះកុង",
      "series": [
        {
          "name": "រថយន្តសរុប",
          "value": 70
        },
        {
          "name": "ដឺកទំនិញ",
          "value": 250
        },
        {
          "name": "អ្នកដំណើរ",
          "value": 33
        }
      ]
    }
  ];