<div class="filter-penal" [formGroup]="form" style="width: 360px;">
    <h4>ជ្រើសរើសដំណើរចេញចូល</h4>
    <!-- <div class="filter-box">
        <button mat-button (click)="_onPress(true)" class="filter-panel-item">
            ដំណើរចេញចូលទាំងអស់
        </button>
    </div> -->
    <div class="form-wrapper" style="padding: 0;">
        <div class="form-group">
            <mat-form-field appearance="outline">
                <mat-label>ដំណើរចេញចូល</mat-label>
                <input matInput placeholder="ដំណើរចេញចូល" type="text" formControlName="search" [matAutocomplete]="auto">
                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayItem">
                    <mat-option *ngFor="let item of travelTypeList" [value]="item">
                        {{ item?.text}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </div>
    </div>

    <div class="price-footer">
        <button mat-button (click)="_onPress(false)" class="btn-apply">បង្ហាញទិន្នន័យ</button>
    </div>
    <app-spinner *ngIf="store?.process"></app-spinner>
</div>