import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import * as firebase from 'firebase/app';
import { STATUS_OBJ } from 'src/app/constant/status.constant';
import { Pages } from '../dummy/app';
import { userRoleObject } from '../dummy/roles';

@Injectable({
    providedIn: 'root'
})

export class FirebaseService {
    constructor(
        public db: AngularFirestore,
    ) { }

    underTakingRef(inspectionKey,lastVisible?: any) {
        return this.db.collection('inspections').doc(inspectionKey).collection('undertaking_signees', ref => {
          let condition = ref.orderBy('dateReceiveDocument').limit(Pages.size);
          if (lastVisible) {
            condition = condition.startAfter(lastVisible['dateReceiveDocument'])
          }
          return condition;
        })
      }
    dbRef() {
        return this.db;
    }
    firestore() {
        return this.db.firestore;
    }
    batch() {
        return this.db.firestore.batch();
    }
    collectionRef(collection) {
        return this.db.collection(collection);
    }
    createId() {
        return this.db.createId();
    }

    serverTimestamp() {
        return firebase.firestore.FieldValue.serverTimestamp();
    }

    displayName(item: any): string {
        return item ? item.name : item;
    }

    displayFullName(item: any): string {
        return item ? `${item.fullName}` : item;
    }

    compareWith(a: any, b: any): boolean {
        if (b) return a.key === b.key;
    }

    geoPoint(latitude, longitude) {
        return new firebase.firestore.GeoPoint(latitude, longitude);
    }
    notificationsRef(){
        return this.db.collection('notifications',ref => ref.orderBy('create_date','desc').limit(100))
    }

    configRef() {
        return this.db.collection('sys_environment').doc('general');
    }

    configFireRef() {
        return this.db.firestore.collection('sys_environment').doc('general');
    }

    envRef() {
        return this.db.collection('environment_config').doc('app_version');
    }

    officerByLevelProvinceRef(levelKey, provinceKey) {
        return this.db.collection('users', ref => {
            let condition = ref.where('status.key', '>', STATUS_OBJ.DELETED.key).orderBy('status.key')
            switch (levelKey) {
                case userRoleObject.CommissionerLevel.key:
                    condition = condition.where('provinceKey', '==', provinceKey)
                    break;
                case userRoleObject.PoliceLevel.key:
                    condition = condition.where('provinceKey', '==', provinceKey)
                    break;
                default:
                    break;
            }
            return condition;
        })
    }

    officerMissionByLevelProvinceRef(levelKey, provinceKey, inspectKey) {
        return this.db.collection('inspections').doc(inspectKey)
            .collection('missions', ref => {
                let condition = ref.where('status.key', '==', STATUS_OBJ.ACTIVE.key)
                switch (levelKey) {
                    case userRoleObject.CommissionerLevel.key:
                        condition = condition.where('provinceKey', '==', provinceKey)
                        break;
                    case userRoleObject.PoliceLevel.key:
                        condition = condition.where('provinceKey', '==', provinceKey)
                        break;
                    default:
                        break;
                }
                return condition;
            })
    }

    inspectMissionRef(levelKey, provinceKey, inspectKey) {
        return this.db.collection('inspections').doc(inspectKey)
            .collection('missions', ref => {
                let condition = ref.where('status.key', '>', STATUS_OBJ.DELETED.key).orderBy('status.key')
                if (provinceKey) {
                    condition = condition.where('provinceKey', '==', provinceKey)
                }
                return condition;
            })
    }

    inspectRef() {
        return this.db.collection('inspections')
    }

    inspectFireRef() {
        return this.db.firestore.collection('inspections')
    }

    laborsFireRef() {
        return this.db.firestore.collection('labors')
    }

    businessRef() {
        return this.db.collection('business')
    }

    businessByProvinceRef(provinceKey, inspectKey) {
        return this.db.collection('inspections').doc(inspectKey)
            .collection('business', ref => {
                let condition = ref.where('status.key', '>', STATUS_OBJ.DELETED.key).orderBy('status.key')
                if (provinceKey) {
                    condition = condition.where('provinceData.key', '==', provinceKey)
                }
                return condition;
            })
    }

    sys_environmentRef() {
        return this.db.collection("sys_environment").doc('general');
    }

    usersRef() {
        return this.db.collection('users')
    }

}