import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { CONFIG } from 'src/app/core/dummy/app';
import { LIST_COVID_TYPE, LIST_COVID_TYPE_ARRAY } from 'src/app/core/dummy/status';
import { AuthStore } from 'src/app/core/stores/auth.store';

@Component({
  selector: 'app-report-layout',
  templateUrl: './report-layout.component.html',
  styleUrls: ['./report-layout.component.scss'],
})
export class ReportLayoutComponent implements OnInit {

  expansions = {
    'one': true,
    'two': false,
    'three': false,
    'four': false,
    'five': false,
    'six': false,
    'seven': false,
    'eight': false,
    'nine': false,
    'ten': false,
  };

  routeUrl = [

    { key: 1, url: "/reports/travel-in-out" },
    { key: 1, url: "/reports/passenger-listing" },
    { key: 1, url: "/reports/print-qr-code" },

    { key: 3, url: "/reports/report-test-covid-totay" },
    { key: 3, url: "/reports/report-test-covid-need-research-totay" },
    { key: 3, url: "/reports/report-test-covid-result-research-totay" },
    { key: 3, url: "/reports/report-test-covid-research-left" },

    { key: 2, url: "/reports/thai-travel-in-out" },
    { key: 2, url: "/reports/thai-passenger-listing" },
    { key: 2, url: "/reports/thai-print-qr-code" },

  ]

  reportTestCovidList = LIST_COVID_TYPE_ARRAY
  type: any = null
  isVOA = false
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public auth: AuthStore
  ) { }

  ngOnInit(): void {
    this.route.firstChild.params.subscribe(param => {
      this.type = param && param.id ? LIST_COVID_TYPE[`${param.id}`] : null
    })
    this.router.events.subscribe((val) => {
      // see also 
      if (val instanceof NavigationEnd) {
        this.route.firstChild.params.subscribe(param => {
          this.type = param && param.id ? LIST_COVID_TYPE[`${param.id}`] : null

        })
      }
    });

    const expansions = JSON.parse(localStorage.getItem('expansions'));
    if (expansions) {
      this.expansions = expansions
    }

    const exInit = this.routeUrl.find(m => m.url === this.router.url)
    if (exInit) {
      this.onOpened(exInit.key)
    }

    if ((this.auth.profile.department.key === CONFIG?.VOA_KEY) || (this.auth.profile.role.key >= 10)) this.isVOA = true

  }

  onOpened(i) {
    const isOpen = true;
    switch (i) {
      case 1:
        this.expansions.one = isOpen;
        break;
      case 2:
        this.expansions.two = isOpen;
        break;
      case 3:
        this.expansions.three = isOpen;
        break;
      case 4:
        this.expansions.four = isOpen;
        break;
      case 5:
        this.expansions.five = isOpen;
        break;
      case 6:
        this.expansions.six = isOpen;
        break;
      case 7:
        this.expansions.seven = isOpen;
        break;
      case 8:
        this.expansions.eight = isOpen;
        break;
      case 9:
        this.expansions.nine = isOpen;
        break;
      case 10:
        this.expansions.ten = isOpen;
        break;
    }
    localStorage.setItem('expansions', JSON.stringify(this.expansions));

  }
  onClosed(i) {
    const isOpen = false;
    switch (i) {
      case 1:
        this.expansions.one = isOpen;
        break;
      case 2:
        this.expansions.two = isOpen;
        break;
      case 3:
        this.expansions.three = isOpen;
        break;
      case 4:
        this.expansions.four = isOpen;
        break;
      case 5:
        this.expansions.five = isOpen;
        break;
      case 6:
        this.expansions.six = isOpen;
        break;
      case 7:
        this.expansions.seven = isOpen;
        break;
      case 8:
        this.expansions.eight = isOpen;
        break;
      case 9:
        this.expansions.nine = isOpen;
        break;
      case 10:
        this.expansions.ten = isOpen;
        break;
    }

    localStorage.setItem('expansions', JSON.stringify(this.expansions));

  }


}
