export const periodData = [
  { key: 1, name: "គិតត្រឹមខែនេះ" },
  { key: 2, name: "គិតត្រឹមត្រីមាសទីមួយ" },
  { key: 3, name: "គិតត្រឹមត្រីមាសទីពីរ" },
  { key: 4, name: "គិតត្រឹមឆមាសទីមួយ" },
  { key: 5, name: "គិតត្រឹមឆមាសទីពីរ" },
  { key: 6, name: "គិតត្រឹមប្រាំបួនខែ" },
  { key: 7, name: "គិតត្រឹមឆ្នាំនេះ" },
  { key: 8, name: "គិតត្រឹមឆ្នាំមុន" },
  { key: 9, name: "ជ្រើសរើសថ្ងៃខែដោយខ្លួនឯង" }
]

export const months = {
  "01": { key: '01', name: "មករា" },
  "02": { key: '02', name: "កុម្ភៈ" },
  "03": { key: '03', name: "មីនា" },
  "04": { key: '04', name: "មេសា" },
  "05": { key: '05', name: "ឧសភា" },
  "06": { key: '06', name: "មិថុនា" },
  "07": { key: '07', name: "កក្កដា" },
  "08": { key: '08', name: "សីហា" },
  "09": { key: '09', name: "កញ្ញា" },
  "10": { key: '10', name: "តុលា" },
  "11": { key: '11', name: "វិច្ឆិកា" },
  "12": { key: '12', name: "ធ្នូ" },
}

export const monthOfYear = [
  { key: '01', name: "មករា" },
  { key: '02', name: "កុម្ភៈ" },
  { key: '03', name: "មីនា" },
  { key: '04', name: "មេសា" },
  { key: '05', name: "ឧសភា" },
  { key: '06', name: "មិថុនា" },
  { key: '07', name: "កក្កដា" },
  { key: '08', name: "សីហា" },
  { key: '09', name: "កញ្ញា" },
  { key: '10', name: "តុលា" },
  { key: '11', name: "វិច្ឆិកា" },
  { key: '12', name: "ធ្នូ" },
]

export const days = [
  { key: "01", name: "1" },
  { key: "02", name: "2" },
  { key: "03", name: "3" },
  { key: "04", name: "4" },
  { key: "05", name: "5" },
  { key: "06", name: "6" },
  { key: "07", name: "7" },
  { key: "08", name: "8" },
  { key: "09", name: "9" },
  { key: "10", name: "10" },
  { key: "11", name: "11" },
  { key: "12", name: "12" },
  { key: "13", name: "13" },
  { key: "14", name: "14" },
  { key: "15", name: "15" },
  { key: "16", name: "16" },
  { key: "17", name: "17" },
  { key: "18", name: "18" },
  { key: "19", name: "19" },
  { key: "20", name: "20" },
  { key: "21", name: "21" },
  { key: "22", name: "22" },
  { key: "23", name: "23" },
  { key: "24", name: "24" },
  { key: "25", name: "25" },
  { key: "26", name: "26" },
  { key: "27", name: "27" },
  { key: "28", name: "28" },
  { key: "29", name: "29" },
  { key: "30", name: "30" },
  { key: "31", name: "31" },
]