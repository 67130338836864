<nav class="app-navbar-header" aria-label="Top Toolbar">
    <div class="nav-left">
        <div class="app-button brand" aria-label="FLIS" [routerLink]="['/']">
            <img src="../../../assets/images/GDI-LOGO.png" />
            <div class="app-name">
                <strong>ប្រព័ន្ធគ្រប់គ្រងអ្នកដំណើរលក្ខណៈតំបន់ និងទ្វេភាគី</strong>
            </div>
        </div>
    </div>
    <div class="flex1"></div>
    <div class="center-menu-nav">
        <a matRipple class="app-button " tooltipClass="tooltip" matTooltip="ទំព័រដើម" [routerLink]="['/']"
            routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
            <mat-icon>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="32" height="32">
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path
                        d="M19 21H5a1 1 0 0 1-1-1v-9H1l10.327-9.388a1 1 0 0 1 1.346 0L23 11h-3v9a1 1 0 0 1-1 1zM6 19h12V9.157l-6-5.454-6 5.454V19z" />
                </svg>
            </mat-icon>
        </a>

        <!-- <a matRipple class="app-button " tooltipClass="tooltip" matTooltip="ស្ថិតិសង្ខេប " [routerLink]="['/']"
            routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
            <mat-icon>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="32" height="32">
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path
                        d="M19 21H5a1 1 0 0 1-1-1v-9H1l10.327-9.388a1 1 0 0 1 1.346 0L23 11h-3v9a1 1 0 0 1-1 1zM6 19h12V9.157l-6-5.454-6 5.454V19z" />
                </svg>
            </mat-icon>
        </a> -->

        <!-- <a matRipple class="app-button" [routerLink]="['/inspect']" matTooltip="អធិការកិច្ច" routerLinkActive="active">
            <mat-icon>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path
                        d="M15 3h6v5h-2V5h-4V3zM9 3v2H5v3H3V3h6zm6 18v-2h4v-3h2v5h-6zm-6 0H3v-5h2v3h4v2zM3 11h18v2H3v-2z" />
                </svg>
            </mat-icon>
        </a> -->

        <a matRipple class="app-button" [routerLink]="['/map']" matTooltip="ផែនទី" routerLinkActive="active">
            <mat-icon>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path
                        d="M12 20.9l4.95-4.95a7 7 0 1 0-9.9 0L12 20.9zm0 2.828l-6.364-6.364a9 9 0 1 1 12.728 0L12 23.728zM12 13a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm0 2a4 4 0 1 1 0-8 4 4 0 0 1 0 8z" />
                </svg>
            </mat-icon>
        </a>

        <!-- <a matRipple *ngIf="auth?.profile?.role?.key > 2" class="app-button" [routerLink]="['/reports/search-alert-list']" matTooltip="មុខសញ្ញា" routerLinkActive="active">
            <mat-icon class="mat-18">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path
                        d="M20 22h-2v-2a3 3 0 0 0-3-3H9a3 3 0 0 0-3 3v2H4v-2a5 5 0 0 1 5-5h6a5 5 0 0 1 5 5v2zm-8-9a6 6 0 1 1 0-12 6 6 0 0 1 0 12zm0-2a4 4 0 1 0 0-8 4 4 0 0 0 0 8z" />
                </svg>
            </mat-icon>
        </a> -->

        <a matRipple class="app-button" [routerLink]="['/reports']" matTooltip="របាយការណ៍" routerLinkActive="active">
            <mat-icon>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path
                        d="M12 22C6.477 22 2 17.523 2 12c0-4.478 2.943-8.268 7-9.542v2.124A8.003 8.003 0 0 0 12 20a8.003 8.003 0 0 0 7.418-5h2.124c-1.274 4.057-5.064 7-9.542 7zm9.95-9H11V2.05c.329-.033.663-.05 1-.05 5.523 0 10 4.477 10 10 0 .337-.017.671-.05 1zM13 4.062V11h6.938A8.004 8.004 0 0 0 13 4.062z" />
                </svg>
            </mat-icon>
        </a>
        <a *ngIf="auth?.profile?.role | userRole" matRipple class="app-button" [routerLink]="['/apps']"
            matTooltip="គ្រប់គ្រង" routerLinkActive="active">
            <mat-icon>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path
                        d="M6.75 2.5A4.25 4.25 0 0 1 11 6.75V11H6.75a4.25 4.25 0 1 1 0-8.5zM9 9V6.75A2.25 2.25 0 1 0 6.75 9H9zm-2.25 4H11v4.25A4.25 4.25 0 1 1 6.75 13zm0 2A2.25 2.25 0 1 0 9 17.25V15H6.75zm10.5-12.5a4.25 4.25 0 1 1 0 8.5H13V6.75a4.25 4.25 0 0 1 4.25-4.25zm0 6.5A2.25 2.25 0 1 0 15 6.75V9h2.25zM13 13h4.25A4.25 4.25 0 1 1 13 17.25V13zm2 2v2.25A2.25 2.25 0 1 0 17.25 15H15z" />
                </svg>
            </mat-icon>
        </a>
        <!-- <a matRipple class="app-button" [routerLink]="['/statistic']" matTooltip="ស្ថិតិអ្នកដំណើរ" routerLinkActive="active">
            <mat-icon>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" 
                 stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                <path fill="none" d="M3 3v18h18"/>
                <path d="M18.7 14.3L15 10.5l-2.7 2.7L7 8"/>
            </svg>
            </mat-icon>
        </a> -->
        <!-- <a matRipple class="app-button" [routerLink]="['/face']" matTooltip="ការរកឃើញមុខ" routerLinkActive="active">
            <mat-icon>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M9.828 5l-2 2H4v12h16V7h-3.828l-2-2H9.828zM9 3h6l2 2h4a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h4l2-2zm3 15a5.5 5.5 0 1 1 0-11 5.5 5.5 0 0 1 0 11zm0-2a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z"/></svg>
            </mat-icon>
        </a> -->
    </div>
    <div class="nav-right">

        <!-- <form [formGroup]="form" class="g-search">
            <mat-select formControlName="option" [compareWith]="fs.compareWith" (selectionChange)="onChangeType($event)">
                <mat-option [value]="item" *ngFor="let item of OPTIONS_SEARCH">{{item.name}}</mat-option>
            </mat-select>
            <input formControlName="search" [matAutocomplete]="auto"
                [placeholder]="'ស្វែងរក'+store?.optionSearch?.name" />
            <button mat-button mat-icon-button>
                <mat-icon>search</mat-icon>
            </button>
        </form>

        <mat-autocomplete #auto="matAutocomplete">

            <mat-option *ngFor="let item of store?.searchData"
                [value]="item?.name?item?.name:item?.surname + ' ' + item?.givenName" class="map-auto-complete">
                <div class="map-sidenav-content-wrap">
                    <ng-container *ngIf="store?.optionSearch.key === 'business' ; else elseTemplate">
                        <div class="map-sidenav-content-item" (click)="_onPressBusiness(item)">
                            <mat-icon class="mat-18">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                    <path fill="none" d="M0 0h24v24H0z" />
                                    <path
                                        d="M12 20.9l4.95-4.95a7 7 0 1 0-9.9 0L12 20.9zm0 2.828l-6.364-6.364a9 9 0 1 1 12.728 0L12 23.728zM12 13a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm0 2a4 4 0 1 1 0-8 4 4 0 0 1 0 8z" />
                                </svg>
                            </mat-icon>
                            <div class="text">
                                {{item?.name?item?.name:item?.surname + ' ' + item?.givenName}}
                                <span class="dmaount">{{item?.districtData?.length}}</span>
                            </div>
                        </div>
                    </ng-container>
                    <ng-template #elseTemplate>
                        <div class="map-sidenav-content-item" (click)="_onPressLabor(item)">
                            <mat-icon class="mat-18">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                    <path fill="none" d="M0 0h24v24H0z" />
                                    <path
                                        d="M12 20.9l4.95-4.95a7 7 0 1 0-9.9 0L12 20.9zm0 2.828l-6.364-6.364a9 9 0 1 1 12.728 0L12 23.728zM12 13a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm0 2a4 4 0 1 1 0-8 4 4 0 0 1 0 8z" />
                                </svg>
                            </mat-icon>
                            <div class="text">
                                {{item?.name?item?.name:item?.surname + ' ' + item?.givenName}}
                                <span class="dmaount">{{item?.districtData?.length}}</span>
                            </div>
                        </div>
                    </ng-template>
                    
                

                </div>
            </mat-option>
        </mat-autocomplete> -->

        <div class="right-menu-nav">
            <!-- <a matRipple class="app-button" [matMenuTriggerFor]="notificationsMenu" aria-label="dmmf">
                <mat-icon>
                    notifications
                </mat-icon>
            </a>
            <mat-menu #notificationsMenu="matMenu">
                <div class="notify-lists">
                    <div class="notify-lists-header">
                        <h4>ជូនដំណឹង</h4>
                    </div>
                    <div class="notify-list-item" *ngFor="let item of store?.notifications">
                        <div class="img-box">
                            <img [src]="item?.photo_url" alt="">
                        </div>
                        <div class="list-text">
                            <strong>{{item?.title}}</strong>
                            <p>{{item?.body}}</p>
                            <span>{{item?.create_date?.toDate() | date}}</span>
                        </div>
                    </div>
                </div>
            </mat-menu> -->

            <a matRipple class="app-button" [matMenuTriggerFor]="userMenu" aria-label="dmmf">
                <mat-icon>
                    person
                </mat-icon>
            </a>
            <mat-menu #userMenu="matMenu">
                <!-- <mat-progress-bar *ngIf="auth?.loading && uploadPercent" mode="determinate" [value]="uploadPercent | async ">
                </mat-progress-bar> -->
                <div class="" style="min-width: auto; width: 360px;    padding: 40px 0;"
                    (click)="$event.stopPropagation();">

                    <div class="wellcome-user" style="text-align: center;">
                        <div class="empty-avatar" style="height: 86px; width: 86px; margin: 0 auto;">
                            <img [src]="auth?.profile?.photo?.downloadUrl" *ngIf="auth?.profile?.photo?.downloadUrl" />
                            <svg *ngIf="!auth?.profile?.photo?.downloadUrl" viewBox="0 0 99.6097412109375 99.6572265625"
                                version="1.1" xmlns="http://www.w3.org/2000/svg" classname=" glyph-box">
                                <g transform="matrix(1 0 0 1 -8.740283203125045 85.05859375)">
                                    <path
                                        d="M 58.5449 14.5508 C 85.791 14.5508 108.35 -8.00781 108.35 -35.2539 C 108.35 -62.4512 85.7422 -85.0586 58.4961 -85.0586 C 31.2988 -85.0586 8.74023 -62.4512 8.74023 -35.2539 C 8.74023 -8.00781 31.3477 14.5508 58.5449 14.5508 Z M 58.5449 -18.6035 C 43.8477 -18.6035 32.5684 -13.3789 27.5879 -7.51953 C 21.0449 -14.8438 17.0898 -24.5605 17.0898 -35.2539 C 17.0898 -58.252 35.4492 -76.7578 58.4961 -76.7578 C 81.543 -76.7578 100 -58.252 100.049 -35.2539 C 100.098 -24.5117 96.0938 -14.7949 89.502 -7.4707 C 84.5703 -13.3301 73.2422 -18.6035 58.5449 -18.6035 Z M 58.5449 -26.9043 C 67.9199 -26.8066 75.293 -34.8145 75.293 -45.3125 C 75.293 -55.1758 67.9199 -63.3789 58.5449 -63.3789 C 49.1699 -63.3789 41.748 -55.1758 41.7969 -45.3125 C 41.8457 -34.8145 49.1699 -27.002 58.5449 -26.9043 Z">
                                    </path>
                                </g>
                            </svg>
                            <div (click)="fileInput.click()" class="change-profile">
                                <mat-icon class="mat-18">photo_camera</mat-icon>
                            </div>
                        </div>

                        <input #fileInput type="file" multiple accept='image/*' (change)="onSelectedFile($event)"
                            style="display:none;" />

                        <div class="wellcome-text k-wellcome-text" style="margin-top: 10px;">
                            <strong>{{auth?.profile?.full_name}}</strong>
                        </div>

                        <div class="devsite-cpwd" (click)="changePassword()">
                            <mat-icon>verified_user</mat-icon>
                            <div class="devsite-list-text" style="padding-left: 10px;">
                                ផ្លាស់ប្តូរពាក្យសម្ងាត់
                            </div>
                        </div>
                    </div>
                    <div class="devsite-logout">
                        <!-- <mat-icon class="mat-18">
                            lock_open
                        </mat-icon> -->
                        <div class="devsite-list-text" (click)="auth?.signOut()">
                            <span>
                                ចាកចេញពីប្រព័ន្ធ
                            </span>
                        </div>
                    </div>

                </div>
            </mat-menu>

            <!-- <a matRipple class="app-button" [matMenuTriggerFor]="helpMenu" aria-label="dmmf">
                <mat-icon>
                    help
                </mat-icon>
            </a>
            <mat-menu #helpMenu="matMenu">
                <div class="dsnav-menu-header" style="padding: 24px;">
                    Under Construction
                </div>
            </mat-menu> -->
        </div>
    </div>

</nav>