import { Pipe, PipeTransform } from '@angular/core';
import { ConvertService } from '../services/convert.service';
import { MappingService, toKhmerNumber } from '../services/mapping.service';

@Pipe({
  name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform {

  transform(value: Date, ...args: unknown[]): unknown {
    if (value) {
      return MappingService.toLocalDateString(value)
    }
    return null;
  }

}


@Pipe({
  name: 'fromKeyToDate'
})
export class FromKeyToDatePipe implements PipeTransform {

  transform(dateKey: any): unknown {
    if (dateKey) {
      return ConvertService.numberToDate(dateKey);
    }
    return null;
  }

}


@Pipe({
  name: 'jointDate'
})
export class jointDatePipe implements PipeTransform {

  transform(date: any): unknown {
    if (date) {
      if (typeof date === 'object' && date.toDate) {
        return date.toDate()
      } else if (typeof date !== 'object') {
        return date
      } else {
        return null
      }
    }
    return null;
  }

}

@Pipe({
  name: 'PToKhmerNumber'
})
export class PToKhmerNumberPipe implements PipeTransform {

  transform(value: any): any {
    return toKhmerNumber(value);
  }
}

@Pipe({
  name: 'GroupByPost'
})
export class GroupByPostPipe implements PipeTransform {
  transform(item: Array<any>, key: string): any {
    if (item && key) {
      return item.filter((f:any) => f?.post?.key === key);
    }
    return [];
  }
}
