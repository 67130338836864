import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'vehicleTypeText'
})
export class VehicleTypeTextPipe implements PipeTransform {

  transform(text: any, isReport: boolean): any {
    if(!text) return '';
    
    const str = text.startsWith("រថយន្ត")
    let value = text
    if (str && isReport) {
      value = text.replace("រថយន្ត", " ");
    }

    if (!str && !isReport) {
      value = `រថយន្ត${text}`
    }

    return value
  }

}
