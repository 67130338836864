import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MobxAngularModule } from 'mobx-angular';
import { MaterialModule } from './ng-material/material.module';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DeleteComponent } from './components/delete/delete.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';
import { EditorModule } from '@tinymce/tinymce-angular';
import { ChartAndInspectComponent } from './components/chart-and-inspect/chart-and-inspect.component'
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { EmptyComponent } from './components/empty/empty.component';
import { DocRefPipe,DocKeyRefPipe } from './pipes/doc-ref.pipe';
import { DocDocRefPipe } from './pipes/doc-doc-ref.pipe';
import { MissionStatusPipe } from './pipes/mission-status.pipe';
import { ChatDialogComponent } from './components/chat-dialog/chat-dialog.component';
import { ConfirmApprovalComponent } from './components/confirm-approval/confirm-approval.component';
import {ChangePasswordComponent} from './components/change-password/change-password.component'
import { PresenceStatusPipe } from './pipes/presence-status.pipe';
import { PresenceStatusComponent } from './components/presence-status/presence-status.component';
import { KhCalendarPipe } from './pipes/kh-calendar.pipe';
import { EmptyDataComponent } from './components/empty-data/empty-data.component';
import { ConfirmSuccessComponent } from './components/confirm-success/confirm-success.component';
import { ConfirmDeleteComponent } from './components/confirm-delete/confirm-delete.component';
import { DayRemainingPipe } from './pipes/day-remaining.pipe';
import { FilterSumArrayPipe } from './pipes/filter-sum-array.pipe';
import { UserRolePipe } from './pipes/user-role.pipe';
import { ToDateKhmerPipe, DateOnlyPipe ,EraBEPipe,fileViewPipe, KhmerDatePipe, KhmerDayPipe, KhmerMonthPipe, KhmerYearPipe, ShowFilePipe, NumDateToDateDayPipe, NumDateToDateMonthPipe, ToDateKhmerFullPipe} from './pipes/date-only.pipe';
import { VehicleTypeTextPipe} from './pipes/vehicle-type-text.pipe';
import { jointDatePipe, FromKeyToDatePipe, DateFormatPipe, PToKhmerNumberPipe, GroupByPostPipe } from './pipes/date-format.pipe';
import { DateTimePipe } from './pipes/date-time.pipe';
import { NgxKjuaModule } from 'ngx-kjua';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';
import { ReportFilterPeriodComponent } from '../modules/reports/components/report-filter-period/report-filter-period.component';
import { ReportFilterPostComponent } from '../modules/reports/components/report-filter-post/report-filter-post.component';
import { ReportFilterTravelTypeComponent } from '../modules/reports/components/report-filter-travel-type/report-filter-travel-type.component';
import { LightboxModule } from 'ngx-lightbox';


const components = [
  PageNotFoundComponent,
  DeleteComponent,
  SpinnerComponent,
  ChartAndInspectComponent,
  EmptyComponent,
  DocRefPipe,
  DocDocRefPipe,
  DocKeyRefPipe,
  MissionStatusPipe,
  ChangePasswordComponent,
  KhCalendarPipe,
  EmptyDataComponent,
  DayRemainingPipe,
  FilterSumArrayPipe,
  UserRolePipe,
  ConfirmSuccessComponent,
  DateFormatPipe,
  FromKeyToDatePipe,
  jointDatePipe,
  DateOnlyPipe,
  ChatDialogComponent,
  PresenceStatusPipe,
  PresenceStatusComponent,
  KhCalendarPipe,
  DayRemainingPipe,
  fileViewPipe,
  VehicleTypeTextPipe,
  ShowFilePipe,
  KhmerYearPipe,
  KhmerMonthPipe,
  KhmerDayPipe,
  KhmerDatePipe,
  EraBEPipe,
  ToDateKhmerPipe,
  NumDateToDateDayPipe,
  NumDateToDateMonthPipe,
  ConfirmDeleteComponent,
  ReportFilterPeriodComponent,
  ReportFilterPostComponent,
  ReportFilterTravelTypeComponent,
  DateTimePipe,
  ConfirmApprovalComponent,
  ToDateKhmerFullPipe,
  PToKhmerNumberPipe,
  GroupByPostPipe
];
const modules = [
  RouterModule,
  FormsModule,
  ReactiveFormsModule,
  CommonModule,
  MaterialModule,
  MobxAngularModule,
  GoogleMapsModule,
  HttpClientModule,
  HttpClientJsonpModule,
  EditorModule,
  NgxChartsModule,
  NgxKjuaModule,
  VirtualScrollerModule,
  MaterialModule,
  LightboxModule
];

@NgModule({
  declarations: [
    ...components,

  ],
  imports: [
    ...modules,
  ],
  exports: [
    ...components,
    ...modules,

  ],
  providers: []
})
export class SharedModule { }
