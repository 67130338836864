import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AuthStore } from './core/stores/auth.store';

@Component({
  selector: 'swotify-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit {
  title = 'swotify';
  loading: boolean = true;

  constructor(private auth: AuthStore) { }
  
  async ngOnInit() {
    await this.auth.fetchCanActive(res => {
      this.loading = false;
    })
  }
}
