import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { HomeLayoutComponent } from './layouts/home-layout/home-layout.component';
import { DashboardLayoutComponent } from './layouts/dashboard-layout/dashboard-layout.component';
import { PageNotFoundComponent } from './shared/components/page-not-found/page-not-found.component';

import { AuthGuard } from './core/guards/auth.guard';
import { SecureInnerPagesGuard } from './core/guards/secure-inner-pages.guard';
import { ReportLayoutComponent } from './layouts/report-layout/report-layout.component';
import { MapViewComponent } from './modules/map-view/pages/map-view/map-view.component';
import { StatisticPassengerComponent } from './modules/statistic/pages/statistic-passenger/statistic-passenger.component';

const routes: Routes = [
  {
    path: 'auth', canActivate: [SecureInnerPagesGuard],
    component: AuthLayoutComponent,
    loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: '',
    canActivate: [AuthGuard],
    component: DashboardLayoutComponent,
    loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule)
  },

  // {
  //   path: 'apps',
  //   canActivate: [AuthGuard],
  //   component: HomeLayoutComponent,
  //   loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule)
  // },

  // {
  //   path: 'apps',
  //   canActivate: [AuthGuard],
  //   component: HomeLayoutComponent,
  //   loadChildren: () => import('./modules/geo-data/geo-data.module').then(m => m.GeoDataModule)
  // },
  // {
  //   path: 'apps',
  //   canActivate: [AuthGuard],
  //   component: HomeLayoutComponent,
  //   loadChildren: () => import('./modules/nationality/nationality.module').then(m => m.NationalityModule)
  // },
  // {
  //   path: 'apps',
  //   canActivate: [AuthGuard],
  //   component: HomeLayoutComponent,
  //   loadChildren: () => import('./modules/business-type/business-type.module').then(m => m.BusinessTypeModule)
  // },
  // {
  //   path: 'apps',
  //   canActivate: [AuthGuard],
  //   component: HomeLayoutComponent,
  //   loadChildren: () => import('./modules/visa-type/visa-type.module').then(m => m.VisaTypeModule)
  // },
  // {
  //   path: 'inspect',
  //   canActivate: [AuthGuard],
  //   component: DashboardLayoutComponent,
  //   loadChildren: () => import('./modules/inspect/inspect.module').then(m => m.InspectModule)
  // },
  // {
  //   path: 'apps',
  //   canActivate: [AuthGuard],
  //   component: HomeLayoutComponent,
  //   loadChildren: () => import('./modules/inspection/inspection.module').then(m => m.InspectionModule)
  // },
  // {
  //   path: 'apps',
  //   canActivate: [AuthGuard],
  //   component: HomeLayoutComponent,
  //   loadChildren: () => import('./modules/owner/owner.module').then(m => m.OwnerModule)
  // },
  // {
  //   path: 'apps',
  //   canActivate: [AuthGuard],
  //   component: HomeLayoutComponent,
  //   loadChildren: () => import('./modules/business/business.module').then(m => m.BusinessModule)
  // },


  // {
  //   path: 'apps',
  //   canActivate: [AuthGuard],
  //   component: DashboardLayoutComponent,
  //   loadChildren: () => import('./modules/business-profile/business-profile.module').then(m => m.BusinessProfileModule)
  // },
  {
    path: 'apps',
    canActivate: [AuthGuard],
    component: HomeLayoutComponent,
    loadChildren: () => import('./modules/officer/officer.module').then(m => m.OfficerModule)
  },

  {
    path: 'map',
    canActivate: [AuthGuard],
    component: DashboardLayoutComponent, children: [
      { path: '', component: MapViewComponent }
    ]
  },
  {
    path: 'statistic',
    canActivate: [AuthGuard],
    component: DashboardLayoutComponent, children: [
      { path: '', component:StatisticPassengerComponent }
    ]
  },
  {
    path: 'reports',
    canActivate: [AuthGuard],
    component: ReportLayoutComponent,
    loadChildren: () => import('./modules/reports/reports.module').then(m => m.ReportsModule)
  },
  

  // Fallback when no prior routes is matched
  { path: 'page-not-found', component: PageNotFoundComponent },
  { path: '**', redirectTo: '/page-not-found', pathMatch: 'full' },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
