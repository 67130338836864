import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import momentkh from '@thyrith/momentkh'
import { ConvertService, toDateKey } from '../services/convert.service';
import { toNumber } from '../services/mapping.service';

//https://github.com/ThyrithSor/momentkh
//https://www.fontsquirrel.com/tools/webfont-generator
@Pipe({
  name: 'toDateKhmerFull'
})
export class ToDateKhmerFullPipe implements PipeTransform {

  transform(value: Date): any {
    if (!value) return;
    const newMoment: any = momentkh(moment)
    const dateToNum = toDateKey(value)
    return `ថ្ងៃ${newMoment(dateToNum, 'YYYYMMDD').toLunarDate('W')} ${newMoment(dateToNum, 'YYYYMMDD').toLunarDate('d')}${newMoment(dateToNum, 'YYYYMMDD').toLunarDate('N')}
      ខែ${newMoment(dateToNum, 'YYYYMMDD').toLunarDate('m')} ឆ្នាំ${newMoment(dateToNum, 'YYYYMMDD').toLunarDate('a')} ${newMoment(dateToNum, 'YYYYMMDD').toLunarDate('e')}
      ព.ស. ${newMoment(dateToNum, 'YYYYMMDD').toLunarDate('b')}
      `;
  }
}

@Pipe({
  name: 'toDateKhmer'
})
export class ToDateKhmerPipe implements PipeTransform {

  transform(value: Date, type: string): any {
    if (!value) return;
    const newMoment: any = momentkh(moment)
    const dateToNum = toDateKey(value)
    return newMoment(dateToNum, 'YYYYMMDD').toLunarDate(type);
  }
}

@Pipe({
  name: 'dateOnly'
})
export class DateOnlyPipe implements PipeTransform {

  transform(value: Date, args?: any): any {
    return moment(value).format('DD-MM-YYYY');
  }

}
@Pipe({
  name: 'NumDateToDateDay'
})
export class NumDateToDateDayPipe implements PipeTransform {

  transform(value: number): any {
    const date = ConvertService.numberToDate(toNumber(value))
    return moment(date).format('DD-MM-YY');
  }

}

@Pipe({
  name: 'NumDateToDateMonth'
})
export class NumDateToDateMonthPipe implements PipeTransform {

  transform(value: number): any {

    const date = ConvertService.numberToDateMonth(toNumber(value))
    return moment(date).format('MM-YYYY');
  }

}

@Pipe({
  name: 'fileView'
})
export class fileViewPipe implements PipeTransform {

  transform(files: any, type: string): any {
    let defaultImg;
    if (type === 'VEHICLE-1' || type === 'VEHICLE-2') {
      defaultImg = '../../../assets/images/default_vehicle.png';
    } else {
      defaultImg = '../../../assets/images/profile_1.jpg';
    }
    const url = files.find(f => f.type_key === type)
    return url?.downloadUrl || defaultImg;
  }

}
@Pipe({
  name: 'showfile'
})
export class ShowFilePipe implements PipeTransform {

  transform(files: any, type: string): any {
    return files.find(f => f.type_key === type) ? true : false
  }

}

@Pipe({
  name: 'khmerYearOnly'
})
export class KhmerYearPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (!value) return;
    const d = moment(value).locale('km').format('Y');
    return d
  }

}

@Pipe({
  name: 'khmerMonthOnly'
})
export class KhmerMonthPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (!value) return;
    const d = moment(value).locale('km').format('MMMM');
    return d
  }

}


@Pipe({
  name: 'khmerDayOnly'
})
export class KhmerDayPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (!value) return;
    const d = moment(value).locale('km').format('D');
    return d
  }
}

@Pipe({
  name: 'khmerDateOnly'
})
export class KhmerDatePipe implements PipeTransform {

  transform(value: any, type?: any): any {
    if (!value) return;
    let d = null;
    if (type === 'day') {
      d = moment(value).locale('km').format('D');
    }
    if (type === 'month') {
      d = moment(value).locale('km').format('MMMM');
    }
    if (type === 'year') {
      d = moment(value).locale('km').format('Y');
    }
    return d
  }

}
@Pipe({
  name: 'eraBE'
})
export class EraBEPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (!value) return;
    const d = moment(value).format('y');
    const val = moment(544 + Number(d), "YYYY").locale("km").format("YYYY")
    return val
  }

}
