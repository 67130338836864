<div class="empty-block">
    <div class="center-block-empty">
        <div class="empty-img">
            <img src="https://mobilecenter.azureedge.net/generated/img/no-apps-db6363dd644196e2291b243bc210e1b0.svg"
                alt="empty">
        </div>
        <div class="empty-text">
            <p class="empty-state-title">
                ពុំមានទិន្នន័យប្រភេទអាជីវកម្មទេ
            </p>
            <p class="empty-state-desc">
                សូមបញ្ជូលទិន្នន័យប្រភេទអាជីវកម្ម
            </p>
        </div>
    </div>
</div>