import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'missionStatus'
})
export class MissionStatusPipe implements PipeTransform {

  transform(key: number): any {
    let value = '';
    switch (key) {
      case 1:
        value = "កំពុងអនុវត្ត"
        break;
      case 2:
        value = "មិនទាន់អនុវត្ត"
        break;
      case 1:
        value = "បានអនុវត្តរួច"
        break;
      default:
        break;
    }
    return value
  }
}


