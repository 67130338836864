
<form [formGroup]="form" class="dialog-content-wrapper">
    <div class="dialog-header">
        <h2 mat-dialog-title> ផ្លាស់ប្តូរពាក្យសម្ងាត់ </h2>
        <div class="space1"></div>
        <button mat-button mat-icon-button (click)="dialogRef.close()">
            <mat-icon class="mat-18">clear</mat-icon>
        </button>
    </div>

    <mat-dialog-content class="form-wrapper">
        <div class="form-group ">
            <mat-form-field appearance="outline">
                <mat-label>លេខសំងាត់​បច្ចុប្បន្ន</mat-label>
                <input type="password" #focusInput placeholder="Enter your current password" matInput
                    formControlName="oldPassword">
                <mat-error *ngIf="form.controls['oldPassword'].hasError('required')">
                    សូមបញ្ចូលពាក្យសម្ងាត់បច្ចុប្បន្នរបស់អ្នក។
                </mat-error>
                <mat-error *ngIf="form.controls['oldPassword'].hasError('minlength')">
                    លេខសម្ងាត់ត្រូវតែមានយ៉ាងហោចណាស់ ៦ តួអក្សរ។
                </mat-error>
            </mat-form-field>
        </div>
        <div class="form-group ">
            <mat-form-field appearance="outline">
                <mat-label>លេខសំងាត់​ថ្មី</mat-label>
                <input type="password" placeholder="Enter min 6 char" matInput
                    formControlName="password">
                <mat-error *ngIf="form.controls['password'].hasError('required')">
                    សូមបញ្ចូលលេខសំងាត់​ថ្មីរបស់អ្នក។
                </mat-error>
                <mat-error *ngIf="form.controls['password'].hasError('minlength')">
                    លេខសម្ងាត់ត្រូវតែមានយ៉ាងហោចណាស់ ៦ តួអក្សរ។
                </mat-error>
            </mat-form-field>
        </div>
        <div class="form-group ">
            <mat-form-field appearance="outline">
                <mat-label>បញ្ជាក់លេខសំងាត់​</mat-label>
                <input type="password" placeholder="Enter new password again" matInput formControlName="confirm">
                <mat-error *ngIf="form.controls['confirm'].hasError('required')">
                    សូមបញ្ចូលបញ្ជាក់លេខសំងាត់​ីរបស់អ្នក។
                </mat-error>
                <mat-error *ngIf="form.controls['confirm'].hasError('minlength')">
                    លេខសម្ងាត់ត្រូវតែមានយ៉ាងហោចណាស់ ៦ តួអក្សរ។
                </mat-error>
            </mat-form-field>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
        <div class="flex1"></div>
        <a mat-button mat-dialog-close class="cancel-btn">បោះបង់</a>
        <button class="submit-btn" mat-button type="submit" cdkFocusInitial (click)="onSave(form.value)">រក្សាទុក</button>
    </mat-dialog-actions>
</form>