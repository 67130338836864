import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { toJS } from 'mobx';
import { debounceTime, switchMap, tap } from 'rxjs/operators';
import { FirebaseService } from 'src/app/core/services/firebase.service';
import { FireStorageService } from 'src/app/core/services/fireStorage.service';
import { AuthStore } from 'src/app/core/stores/auth.store';
import { ChartStore } from 'src/app/core/stores/chart.store';
import { ChangePasswordComponent } from 'src/app/shared/components/change-password/change-password.component';

@Component({
  selector: 'dashboard-nav',
  templateUrl: './dashboard-nav.component.html',
  styleUrls: ['./dashboard-nav.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DashboardNavComponent implements OnInit {
  uploadPercent: any;
  form: FormGroup;
  option: AbstractControl;
  search: AbstractControl;

  OPTIONS_SEARCH = [
    { key: 'business', name: 'សហគ្រាស' },
    { key: 'labors', name: 'ពលករ' }
  ]

  constructor(
    public auth: AuthStore,
    private dialog: MatDialog,
    public storage: FireStorageService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    public store: ChartStore,
    public fs: FirebaseService,
  ) { }

  async ngOnInit(): Promise<void> {
    await this.auth.fetchUserDoc()
    this.store.fetchNotifications()
    this.store.optionSearch = this.OPTIONS_SEARCH[0];
    this.form = this.fb.group({
      option: [this.OPTIONS_SEARCH[0], [Validators.required]],
      search: [null, [Validators.required]]
    })
    this.search = this.form.controls['search'];
    this.option = this.form.controls['option'];
    this.store.filterListing(null, this.option.value, data => { })
    this.search.valueChanges
      .pipe(
        debounceTime(300),
        tap(() => (false)),
        switchMap(value => this.store.filterListing(value, this.option.value, data => {
        }))
      ).subscribe(res => {
      });
  }
  _onPressBusiness(f) {
    const sparams = this.route.snapshot.queryParams
    this.router.navigate(['/reports/report-one'], {
      queryParams:
      {
        inspection: sparams.inspection ? sparams.inspection : '',
        business: f.key,
        fromdate: sparams.fromdate ? sparams.fromdate : '',
        todate: sparams.todate ? sparams.todate : '',
        province: sparams.province ? sparams.province : '',

      }
    });
  }

  _onPressLabor(f) {
    // const sparams = this.route.snapshot.queryParams
    this.router.navigate(['/apps/labor-profile/' + f.key]);
  }

  changePassword() {
    let dialogRef = this.dialog.open(ChangePasswordComponent, {
      data: null,
      panelClass: 'cs-dialog-wrapper',
      width: '35vw',
      height: '96vh',
      disableClose: true,
      role: 'dialog',
    });
    dialogRef.updatePosition({ top: '16px', right: '16px', bottom: '16px' });
    dialogRef.afterClosed().subscribe(result => { });
  }
  onChangeType(item) {
    this.store.optionSearch = item.source._value;
    this.store.filterListing(null, this.option.value, data => { })
    // const sparams = this.route.snapshot.queryParams
    // this.router.navigate(['/map'], {
    //   queryParams:
    //   {
    //     type: this.selectedSearchType.name,
    //     search: this.search.value,
    //     mission: sparams.mission,
    //   }
    // });

  }
  async onSelectedFile(event) {
    if (event) {
      const soundPath = `avatar/${event.target.files[0].name}`;
      const soundTask = this.storage.uploadFile(event, soundPath);
      this.uploadPercent = soundTask.percentageChanges();
      const downloadURLSound = (await soundTask).ref.getDownloadURL();
      downloadURLSound.then(downloadURL => {
        this.auth.changeAvatar(downloadURL, event.target.files[0].name, this.auth.user)
      })
    }
  }

  ngOnDestroy() {
    const { userProfile } = this.auth;
    userProfile && userProfile.unsubscribe();
  }

}
