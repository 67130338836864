<div class="dialog-content-wrapper">
  <div class="dialog-header">
    <h2 mat-dialog-title>{{data?.title}}</h2>
    <div class="space1"></div>
    <button mat-icon-button (click)="dialogRef.close(false)">
      <mat-icon class="mat-18">clear</mat-icon>
    </button>
  </div>
  <mat-dialog-content class="mat-typography">
    <div class="aside-warning">
      <i class="material-icons">warning</i>
      <span>
        {{data?.memo}}
      </span>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="false">បោះបង់</button>
    <button mat-button class="enbutton primary" (click)="dialogRef.close('yes')">យល់ព្រម</button>
  </mat-dialog-actions>
</div>