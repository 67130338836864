import { observable, computed, action, autorun, toJS } from "mobx";
import { Injectable } from "@angular/core";
import { ApiService } from '../../../shared/services/api.service';
import { periodData } from 'src/app/core/dummy/report';
import { ConvertService, toDayOfMonth, toKhDay, toKhMonth, toKhYear, toMonthKh, toYearOfPeriod } from 'src/app/shared/services/convert.service';
import { MappingService, sortBy, toNumber } from 'src/app/shared/services/mapping.service';

import { PASSENGER_TYPE, RESULT_QUICK_TEST, TRAVEL_TYPE } from "src/app/core/dummy/status";
import { ReportService } from "../../reports/services/report.service";
@Injectable({ providedIn: "root" })

export class StatisticPassenger {
    @observable public loading = false;
    @observable public process = false;
    @observable public fromDate: any = null;
    @observable public toDate: any = null;
    @observable public periodText: string = null;
    @observable public periodTextDaily: string = null;
    @observable public periodMonthDaily: string = null;

    @observable public fromDateNum = null;
    @observable public toDateNum = null;

    @observable public selectedPost = null;
    @observable public selectedTravel = null;

    @observable public reportTravelInOut: any = [];
    @observable public statisticKhmer: any = null;
    @observable public statisticThai: any = null;

    constructor(private ds: ReportService, public api: ApiService) { }

    getPeriod(fromDate, toDate) {
      this.fromDate = ConvertService.numberToDate(fromDate);
      this.toDate = ConvertService.numberToDate(toDate);
      this.periodText = `ចាប់ពីថ្ងៃទី${toKhDay(this.fromDate)} ខែ${toKhMonth(this.fromDate)} ឆ្នាំ${toKhYear(this.fromDate)} ដល់ថ្ងៃទី ${toKhDay(this.toDate)} ខែ${toKhMonth(this.toDate)} ឆ្នាំ${toKhYear(this.toDate)}`
      this.periodTextDaily = `ថ្ងៃទី${toKhDay(this.fromDate)} ខែ${toKhMonth(this.fromDate)} ឆ្នាំ${toKhYear(this.fromDate)}`
      this.periodMonthDaily = `ចាប់ពីខែ${toKhMonth(this.fromDate)} ឆ្នាំ${toKhYear(this.fromDate)} ដល់ខែ${toKhMonth(this.toDate)} ឆ្នាំ${toKhYear(this.toDate)}`
  }
  @action
  async fetchData(collection, callback) {
      this.loading = true;
      this.ds.collectionRef(collection).valueChanges().subscribe(data => {
          callback(data)
          this.loading = false;
      })
  }
  @action
  async fetchStatisticKhmer(queryParams) {
    this.loading = true;
    this.selectedPost = null
    const { postKey, travelKey, fromdate, todate } = queryParams;
    this.selectedTravel = travelKey ? TRAVEL_TYPE.find(m => m.key === toNumber(travelKey)) : TRAVEL_TYPE[0]
    this.fromDateNum = fromdate ? fromdate : ConvertService.dateKey()
    this.toDateNum = todate ? todate : ConvertService.dateKey()
    this.getPeriod(this.fromDateNum, this.toDateNum)
    let link = `${this.api.baseUri}fetchStatistic?fromDate=${this.fromDateNum}&toDate=${this.toDateNum}`

    if (this.selectedTravel) {
        link = `${link}&travelKey=${this.selectedTravel.key}`
    }
    if (postKey) {
        link = `${link}&postKey=${postKey}`
        const postData = await this.ds.docRef("posts", postKey).get().toPromise()
        this.selectedPost = MappingService.pushToObject(postData)
    }
    this.api.get(link).then(async (res) => {
        if (res && res.data) {
            const data = res.data
            this.statisticKhmer = data
        }
        this.loading = false;
    });
}

@action
async fetchStatisticThai(queryParams) {
  this.loading = true;
  this.selectedPost = null
  const { postKey, travelKey, fromdate, todate } = queryParams;
  this.selectedTravel = travelKey ? TRAVEL_TYPE.find(m => m.key === toNumber(travelKey)) : TRAVEL_TYPE[0]
  this.fromDateNum = fromdate ? fromdate : ConvertService.dateKey()
  this.toDateNum = todate ? todate : ConvertService.dateKey()
  this.getPeriod(this.fromDateNum, this.toDateNum)
  let link = `${this.api.baseUri}fetchThaiStatistic?fromDate=${this.fromDateNum}&toDate=${this.toDateNum}`

  if (this.selectedTravel) {
      link = `${link}&travelKey=${this.selectedTravel.key}`
  }
  if (postKey) {
      link = `${link}&postKey=${postKey}`
      const postData = await this.ds.docRef("posts", postKey).get().toPromise()
      this.selectedPost = MappingService.pushToObject(postData)
  }
  this.api.get(link).then(async (res) => {
      
      if (res && res.data) {
          const data = res.data
          this.statisticThai = data
      }
      this.loading = false;
  });
}

}

