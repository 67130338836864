<div class="map-overview-wrapper">

    <div class="map-sidenav" [formGroup]="form" [class.full-view]="isDetail">
        <div class="map-sidenav-content">
            <div class="directions-button-shown">
                <div style="position: relative;">
                    <div class="searchbox-directions-container-left">
                        <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                            <mat-icon>menu</mat-icon>
                        </button>
                        <mat-menu #menu="matMenu">
                            <button class="sct-menu" mat-menu-item *ngFor="let item of searchType"
                                [class.active]="item?.key === selectedSearchType?.key" (click)="onChangeType(item)">
                                <mat-icon>location_on</mat-icon>
                                <span>{{item?.text}}</span>
                            </button>
                        </mat-menu>
                    </div>

                    <div class="map-search-wrap">
                        <input type="text" [matAutocomplete]="auto" [formControl]="search"
                            [placeholder]="selectedSearchType?.text">
                        <div class="searchbox-searchbutton-container">
                            <button mat-icon-button>
                                <mat-icon class="mat-18" style="color: #999;">search</mat-icon>
                            </button>
                        </div>
                    </div>

                </div>
                <a mat-button *ngIf="isDetail" (click)="backToResult()" class="bresult">
                    <mat-icon class="mat-18">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path d="M10.828 12l4.95 4.95-1.414 1.414L8 12l6.364-6.364 1.414 1.414z" /></svg>
                    </mat-icon>
                    <span>Back</span>
                </a>
            </div>
            <!-- 
            <button mat-button (click)="toggleMore()" *ngIf="!isMore && store?.data?.length > 0" style="width: 100%;">
                <mat-icon class="mat-18">keyboard_arrow_down</mat-icon>
                See all, search result
            </button> -->
        </div>
        <mat-autocomplete #auto="matAutocomplete">
            <mat-option *ngFor="let item of store?.data"
                [value]="item?.name?item?.name:item?.surname + ' ' + item?.givenName" class="map-auto-complete">
                <div class="map-sidenav-content-wrap">
                    <div class="map-sidenav-content-item" (click)="missionClick(item)">
                        <mat-icon class="mat-18">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                <path fill="none" d="M0 0h24v24H0z" />
                                <path
                                    d="M12 20.9l4.95-4.95a7 7 0 1 0-9.9 0L12 20.9zm0 2.828l-6.364-6.364a9 9 0 1 1 12.728 0L12 23.728zM12 13a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm0 2a4 4 0 1 1 0-8 4 4 0 0 1 0 8z" />
                            </svg>
                        </mat-icon>
                        <div class="text">
                            {{item?.name?item?.name:item?.surname + ' ' + item?.givenName}}
                            <span class="dmaount">{{item?.districtData?.length}}</span>
                        </div>
                    </div>
                </div>
            </mat-option>
        </mat-autocomplete>

        <ng-container *ngIf="isDetail">
            <div class="sdivider" style="margin: 0px;"></div>

            <div class="detail-map-data" *ngIf="selectedSearchType?.key === 1">
                <div class="flex-data">
                    <strong>បេសកកម្ម:</strong>
                    {{selectedMission?.name}}
                </div>

                <div class="flex-data">
                    <strong style="padding-top: 8px;">គោលដៅ:</strong>
                    <div class="location-lists">

                        <div class="location-list-item" *ngFor="let item of selectedMission?.districtData">
                            <mat-icon>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                    <path fill="none" d="M0 0h24v24H0z" />
                                    <path
                                        d="M12 20.9l4.95-4.95a7 7 0 1 0-9.9 0L12 20.9zm0 2.828l-6.364-6.364a9 9 0 1 1 12.728 0L12 23.728zM12 13a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm0 2a4 4 0 1 1 0-8 4 4 0 0 1 0 8z" />
                                </svg>
                            </mat-icon>

                            {{item?.name}}
                        </div>
                    </div>
                </div>
            </div>
            <ng-container *ngIf="selectedSearchType?.key === 2">
                <div class="detail-map-data">
                    <div class="avatar" *ngIf="selectedMission?.photoFile?.downloadUrl">
                        <img [src]="selectedMission?.photoFile?.downloadUrl" alt="">
                    </div>
                    <strong style="margin-bottom: 12px;">ព័ត៌មានអាជីវកម្ម</strong>

                    <div class="flex-data">
                        <strong>ឈ្មោះ:</strong>
                        {{selectedMission?.name}}
                    </div>

                    <div class="flex-data">
                        <strong>អាសយដ្ឋាន:</strong>
                        <span *ngIf="selectedMission?.provinceData?.name">
                            {{selectedMission?.provinceData?.name}},
                        </span>
                        <span *ngIf="selectedMission?.districtData?.name">
                            {{selectedMission?.districtData?.name}},
                        </span>
                        <span *ngIf="selectedMission?.communeData?.name">
                            {{selectedMission?.communeData?.name}},
                        </span>
                        <span *ngIf="selectedMission?.villageData?.name">
                            {{selectedMission?.villageData?.name}},
                        </span>
                        <span *ngIf="selectedMission?.homeNo">
                            {{selectedMission?.homeNo}},
                        </span>
                        <span *ngIf="selectedMission?.streetNo">
                            {{selectedMission?.streetNo}}
                        </span>
                    </div>
                </div>

                <div class="sdivider" style="margin: 0px;"></div>
                <mat-tab-group animationDuration="0ms" class="map-tap-container">
                    <mat-tab>
                        <ng-template mat-tab-label>
                            <div class="as-tab-title">
                                <mat-icon class="example-tab-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                        <path fill="none" d="M0 0h24v24H0z" />
                                        <path
                                            d="M5 9a1 1 0 0 1 1 1 6.97 6.97 0 0 1 4.33 1.5h2.17c1.333 0 2.53.58 3.354 1.5H19a5 5 0 0 1 4.516 2.851C21.151 18.972 17.322 21 13 21c-2.79 0-5.15-.603-7.06-1.658A.998.998 0 0 1 5 20H2a1 1 0 0 1-1-1v-9a1 1 0 0 1 1-1h3zm1.001 3L6 17.022l.045.032C7.84 18.314 10.178 19 13 19c3.004 0 5.799-1.156 7.835-3.13l.133-.133-.12-.1a2.994 2.994 0 0 0-1.643-.63L19 15h-2.111c.072.322.111.656.111 1v1H8v-2l6.79-.001-.034-.078a2.501 2.501 0 0 0-2.092-1.416L12.5 13.5H9.57A4.985 4.985 0 0 0 6.002 12zM4 11H3v7h1v-7zm14-6a3 3 0 1 1 0 6 3 3 0 0 1 0-6zm0 2a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-7-5a3 3 0 1 1 0 6 3 3 0 0 1 0-6zm0 2a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
                                    </svg>
                                </mat-icon>
                                ម្ចាស់អាជីវកម្ម
                            </div>
                        </ng-template>
                        <ng-container *ngFor="let item of store?.businessOwnerdata">
                            <div class="listing-data">
                                <div class="list-avatar" *ngIf="item?.photoFile?.downloadUrl">
                                    <img [src]="item?.photoFile?.downloadUrl" alt="">
                                </div>
                                <div class="data-text">

                                    <div class="flex-data">
                                        <strong>ឈ្មោះ:</strong>
                                        {{item?.lastName + ' ' + item?.firstName}}
                                    </div>
                                    <div class="flex-data">
                                        <strong>ភេទ:</strong>
                                        {{item?.gender?.text}}
                                    </div>

                                    <!-- <div class="flex-data">
                                        <strong>ថ្ងៃខែ​ឆ្នាំ​កំណើត:</strong>
                                        <span *ngIf="item?.dob">{{item?.dob?.toDate() | date}}</span>
                                    </div> -->

                                    <div class="flex-data">
                                        <strong>សញ្ជាតិ:</strong>
                                        {{item?.nationality?.nationality}}
                                    </div>
                                    <div class="flex-data">
                                        <strong>លេខ​លិខិតឆ្លងដែន:</strong>
                                        {{item?.passportNumber}}
                                    </div>
                                </div>
                            </div>

                            <div class="sdivider" style="margin: 8px 24px;"></div>
                        </ng-container>
                    </mat-tab>
                    <mat-tab>
                        <ng-template mat-tab-label>
                            <div class="as-tab-title">
                                <mat-icon class="example-tab-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                        <path fill="none" d="M0 0h24v24H0z" />
                                        <path
                                            d="M5 9a1 1 0 0 1 1 1 6.97 6.97 0 0 1 4.33 1.5h2.17c1.333 0 2.53.58 3.354 1.5H19a5 5 0 0 1 4.516 2.851C21.151 18.972 17.322 21 13 21c-2.79 0-5.15-.603-7.06-1.658A.998.998 0 0 1 5 20H2a1 1 0 0 1-1-1v-9a1 1 0 0 1 1-1h3zm1.001 3L6 17.022l.045.032C7.84 18.314 10.178 19 13 19c3.004 0 5.799-1.156 7.835-3.13l.133-.133-.12-.1a2.994 2.994 0 0 0-1.643-.63L19 15h-2.111c.072.322.111.656.111 1v1H8v-2l6.79-.001-.034-.078a2.501 2.501 0 0 0-2.092-1.416L12.5 13.5H9.57A4.985 4.985 0 0 0 6.002 12zM4 11H3v7h1v-7zm14-6a3 3 0 1 1 0 6 3 3 0 0 1 0-6zm0 2a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-7-5a3 3 0 1 1 0 6 3 3 0 0 1 0-6zm0 2a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
                                    </svg>
                                </mat-icon>
                                បញ្ជីត្រួតពិនិត្យ
                            </div>
                        </ng-template>
                        <ng-container *ngFor="let item of store?.businessLaborInspectiondata">
                            <div class="listing-data">
                                <div class="list-avatar"
                                    *ngIf="(item?.workerKey | docKeyRef:'labors':'photoFile' | async)?.downloadUrl">
                                    <img [src]="(item?.workerKey | docKeyRef:'labors':'photoFile' | async)?.downloadUrl"
                                        alt="">
                                </div>
                                <div class="data-text">
                                    <div class="flex-data">
                                        <strong>ឈ្មោះ:</strong>
                                        {{item?.workerData?.full_name}}
                                    </div>
                                    <div class="flex-data">
                                        <strong>ភេទ:</strong>
                                        {{item?.workerData?.gender?.text}}
                                    </div>
                                    <div class="flex-data">
                                        <strong>អាយុ:</strong>
                                        {{item?.workerData?.age}}
                                    </div>
                                    <div class="flex-data">
                                        <strong>សញ្ជាតិ:</strong>
                                        {{item?.workerData?.nationality?.nationality}}
                                    </div>
                                    <div class="flex-data">
                                        <strong>ប្រភេទទិដ្ឋាការ:</strong>
                                        {{item?.workerData?.type?.name}}
                                    </div>
                                </div>
                            </div>
                            <div class="sdivider" style="margin: 8px 24px;"></div>
                        </ng-container>




                    </mat-tab>
                    <mat-tab>
                        <ng-template mat-tab-label>
                            <div class="as-tab-title">
                                <mat-icon class="example-tab-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                        <path fill="none" d="M0 0h24v24H0z" />
                                        <path
                                            d="M5 9a1 1 0 0 1 1 1 6.97 6.97 0 0 1 4.33 1.5h2.17c1.333 0 2.53.58 3.354 1.5H19a5 5 0 0 1 4.516 2.851C21.151 18.972 17.322 21 13 21c-2.79 0-5.15-.603-7.06-1.658A.998.998 0 0 1 5 20H2a1 1 0 0 1-1-1v-9a1 1 0 0 1 1-1h3zm1.001 3L6 17.022l.045.032C7.84 18.314 10.178 19 13 19c3.004 0 5.799-1.156 7.835-3.13l.133-.133-.12-.1a2.994 2.994 0 0 0-1.643-.63L19 15h-2.111c.072.322.111.656.111 1v1H8v-2l6.79-.001-.034-.078a2.501 2.501 0 0 0-2.092-1.416L12.5 13.5H9.57A4.985 4.985 0 0 0 6.002 12zM4 11H3v7h1v-7zm14-6a3 3 0 1 1 0 6 3 3 0 0 1 0-6zm0 2a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-7-5a3 3 0 1 1 0 6 3 3 0 0 1 0-6zm0 2a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
                                    </svg>
                                </mat-icon>
                                ទីតាំង
                            </div>
                        </ng-template>
                        <ng-container *ngFor="let item of store?.businessLocationsdata">
                            <div class="listing-data">
                                <div class="data-text">
                                    <div class="flex-data">
                                        <strong>លេខ​ផ្ទះ:</strong>
                                        {{item?.homeNo}}
                                    </div>
                                    <div class="flex-data">
                                        <strong>លេខ​ផ្លូវ:</strong>
                                        #{{item?.streetNo}}

                                    </div>
                                    <div class="flex-data">
                                        {{item?.province | docKeyRef:"geo_provinces":"name" | async}}
                                        {{item?.district | docKeyRef:"geo_districts":"name" | async}}
                                        {{item?.commune | docKeyRef:"geo_communes":"name" | async}}
                                        {{item?.village | docKeyRef:"geo_villages":"name" | async}}
                                    </div>
                                </div>
                            </div>
                            <div class="sdivider" style="margin: 8px 24px;"></div>
                        </ng-container>

                    </mat-tab>
                </mat-tab-group>
            </ng-container>
            <ng-container *ngIf="selectedSearchType?.key === 3">
                <div class="detail-map-data">
                    <div class="avatar" *ngIf="selectedMission?.photoFile?.downloadUrl">
                        <img [src]="selectedMission?.photoFile?.downloadUrl" alt="">
                    </div>

                    <strong style="margin-bottom: 12px;">ប្រវត្តិរូបសង្ខេប</strong>

                    <div class="flex-data">
                        <strong>ឈ្មោះ:</strong>
                        {{selectedMission?.surname + ' ' + selectedMission?.givenName}}
                    </div>
                    <div class="flex-data">
                        <strong>ភេទ:</strong>
                        {{selectedMission?.gender?.text}}
                    </div>

                    <div class="flex-data">
                        <strong>ថ្ងៃខែ​ឆ្នាំ​កំណើត:</strong>
                        {{selectedMission?.dateOfBirth?.toDate() | date}}
                    </div>

                    <div class="flex-data">
                        <strong>សញ្ជាតិ:</strong>
                        {{selectedMission?.nationality?.nationality}}
                    </div>
                    <div class="flex-data">
                        <strong>លេខ​លិខិតឆ្លងដែន:</strong>
                        {{selectedMission?.passportNumber}}
                    </div>
                </div>
                <div class="sdivider" style="margin: 0px;"></div>

                <mat-tab-group animationDuration="0ms" class="map-tap-container">
                    <mat-tab>
                        <ng-template mat-tab-label>
                            <div class="as-tab-title">
                                <mat-icon class="example-tab-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                        <path fill="none" d="M0 0h24v24H0z" />
                                        <path
                                            d="M5 9a1 1 0 0 1 1 1 6.97 6.97 0 0 1 4.33 1.5h2.17c1.333 0 2.53.58 3.354 1.5H19a5 5 0 0 1 4.516 2.851C21.151 18.972 17.322 21 13 21c-2.79 0-5.15-.603-7.06-1.658A.998.998 0 0 1 5 20H2a1 1 0 0 1-1-1v-9a1 1 0 0 1 1-1h3zm1.001 3L6 17.022l.045.032C7.84 18.314 10.178 19 13 19c3.004 0 5.799-1.156 7.835-3.13l.133-.133-.12-.1a2.994 2.994 0 0 0-1.643-.63L19 15h-2.111c.072.322.111.656.111 1v1H8v-2l6.79-.001-.034-.078a2.501 2.501 0 0 0-2.092-1.416L12.5 13.5H9.57A4.985 4.985 0 0 0 6.002 12zM4 11H3v7h1v-7zm14-6a3 3 0 1 1 0 6 3 3 0 0 1 0-6zm0 2a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-7-5a3 3 0 1 1 0 6 3 3 0 0 1 0-6zm0 2a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
                                    </svg>
                                </mat-icon>
                                អាជីវកម្ម
                            </div>
                        </ng-template>
                        <div class="listing-data" *ngFor="let item of store?.laborBusinessdata">
                            <div class="list-avatar" *ngIf="item?.photoFile?.downloadUrl">
                                <img [src]="item?.photoFile?.downloadUrl" alt="">
                            </div>
                            <div class="data-text">
                                <div class="flex-data">
                                    <strong>ឈ្មោះ:</strong>
                                    {{item?.name}}
                                </div>
                                <div class="flex-data">
                                    <strong>អាសយដ្ឋាន:</strong>
                                    <span *ngIf="item?.provinceData?.name">
                                        {{item?.provinceData?.name}},
                                    </span>
                                    <span *ngIf="item?.districtData?.name">
                                        {{item?.districtData?.name}},
                                    </span>
                                    <span *ngIf="item?.communeData?.name">
                                        {{item?.communeData?.name}},
                                    </span>
                                    <span *ngIf="item?.villageData?.name">
                                        {{item?.villageData?.name}},
                                    </span>
                                    <span *ngIf="item?.homeNo">
                                        {{item?.homeNo}},
                                    </span>
                                    <span *ngIf="item?.streetNo">
                                        {{item?.streetNo}}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </mat-tab>
                    <mat-tab>
                        <ng-template mat-tab-label>
                            <div class="as-tab-title">

                                <mat-icon class="example-tab-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                        <path fill="none" d="M0 0h24v24H0z" />
                                        <path
                                            d="M5 9a1 1 0 0 1 1 1 6.97 6.97 0 0 1 4.33 1.5h2.17c1.333 0 2.53.58 3.354 1.5H19a5 5 0 0 1 4.516 2.851C21.151 18.972 17.322 21 13 21c-2.79 0-5.15-.603-7.06-1.658A.998.998 0 0 1 5 20H2a1 1 0 0 1-1-1v-9a1 1 0 0 1 1-1h3zm1.001 3L6 17.022l.045.032C7.84 18.314 10.178 19 13 19c3.004 0 5.799-1.156 7.835-3.13l.133-.133-.12-.1a2.994 2.994 0 0 0-1.643-.63L19 15h-2.111c.072.322.111.656.111 1v1H8v-2l6.79-.001-.034-.078a2.501 2.501 0 0 0-2.092-1.416L12.5 13.5H9.57A4.985 4.985 0 0 0 6.002 12zM4 11H3v7h1v-7zm14-6a3 3 0 1 1 0 6 3 3 0 0 1 0-6zm0 2a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-7-5a3 3 0 1 1 0 6 3 3 0 0 1 0-6zm0 2a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
                                    </svg>
                                </mat-icon>
                                ឯកសារ
                            </div>
                        </ng-template>
                        <div class="listing-data" *ngFor="let item of store?.laborDocumentsdata">
                            <div class="data-text">
                                <div class="flex-data">
                                    <strong>លេខ​ឯកសារ:</strong>
                                    {{item?.documentNo}}
                                </div>
                                <div class="flex-data">
                                    <strong>កាលបរិច្ឆេទផុតកំណត់ឯកសារ:</strong>
                                    {{item?.documentExpiredDate?.toDate() | date}}
                                </div>
                                <div class="flex-data">
                                    <strong>ប្រភេទ​ឯកសារ:</strong>
                                    {{item?.documentType?.name}}
                                </div>
                            </div>
                        </div>
                    </mat-tab>
                    <mat-tab>
                        <ng-template mat-tab-label>
                            <div class="as-tab-title">

                                <mat-icon class="example-tab-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                        <path fill="none" d="M0 0h24v24H0z" />
                                        <path
                                            d="M5 9a1 1 0 0 1 1 1 6.97 6.97 0 0 1 4.33 1.5h2.17c1.333 0 2.53.58 3.354 1.5H19a5 5 0 0 1 4.516 2.851C21.151 18.972 17.322 21 13 21c-2.79 0-5.15-.603-7.06-1.658A.998.998 0 0 1 5 20H2a1 1 0 0 1-1-1v-9a1 1 0 0 1 1-1h3zm1.001 3L6 17.022l.045.032C7.84 18.314 10.178 19 13 19c3.004 0 5.799-1.156 7.835-3.13l.133-.133-.12-.1a2.994 2.994 0 0 0-1.643-.63L19 15h-2.111c.072.322.111.656.111 1v1H8v-2l6.79-.001-.034-.078a2.501 2.501 0 0 0-2.092-1.416L12.5 13.5H9.57A4.985 4.985 0 0 0 6.002 12zM4 11H3v7h1v-7zm14-6a3 3 0 1 1 0 6 3 3 0 0 1 0-6zm0 2a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-7-5a3 3 0 1 1 0 6 3 3 0 0 1 0-6zm0 2a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
                                    </svg>
                                </mat-icon>
                                ប័ណ្ណការងារ
                            </div>

                        </ng-template>
                        <div class="listing-data" *ngFor="let item of store?.laborCardsdata">
                            <a class="data-text" style="word-break: break-word; font-size: 13px;line-height: normal;"
                                [href]="item?.laborCardId"
                                target="_blank">
                                {{item?.keyword}}
                            </a>
                        </div>
                    </mat-tab>
                </mat-tab-group>
                <div class="sdivider" style="margin: 0px;"></div>

            </ng-container>
        </ng-container>
    </div>
    <div class="map-side-content">

        <!-- <ng-conteiner *ngFor="let item of store?.data">
            <div style="margin-top:100px" *ngFor="let m of item?.districtData; let i = index" [latitude]="m?.location?.latitude"
                [longitude]="m?.location?.longitude">
                <div class="m-layer" (click)="clickedMarker(infowindow,m)">
                    {{m?.location?.latitude}}
                </div>
            </div>
        </ng-conteiner> -->
        <agm-map [latitude]="lat" [longitude]="lng" [usePanning]="true" [usePanning]="true"
            (mapReady)="getMapInstance($event)" [zoom]="zoom" [backgroundColor]="'#f1f3f4'" [mapTypeId]="'terrain'"
            [scrollwheel]="true" [mapTypeId]="'roadmap'">
            <ng-container *ngIf="selectedSearchType.key === 1">
                <agm-overlay *ngFor="let item of selectedMission?.districtData; let i = index"
                    [latitude]="item?.location?.latitude" [longitude]="item?.location?.longitude">
                    <div class="m-layer" (click)="clickedMarker(infowindow,item)">
                        {{item?.name}}
                    </div>

                    <agm-info-window #infowindow>
                        <div class="map-info-card">
                            <div class="card listing-cart">
                                <div class="listing-card-body">
                                    <div class="card-title" style="flex: 1;">
                                        <div class="item-for">
                                            {{item?.name}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </agm-info-window>
                </agm-overlay>

            </ng-container>
            <ng-container *ngIf="selectedSearchType.key === 2">
                <agm-overlay [latitude]="selectedMission?.map?.latitude" [longitude]="selectedMission?.map?.longitude">

                    <div class="m-layer" (click)="clickedMarker(infowindow,item)">
                        {{selectedMission?.name}}
                    </div>

                    <agm-info-window #infowindow>
                        <div class="map-info-card">
                            <div class="card listing-cart">
                                <div class="listing-card-body">
                                    <div class="card-title" style="flex: 1;">
                                        <div class="item-for">
                                            <div class="detail-map-data">
                                                <div class="avatar" *ngIf="selectedMission?.photoFile?.downloadUrl">
                                                    <img [src]="selectedMission?.photoFile?.downloadUrl" alt="">
                                                </div>
                                                <strong style="margin-bottom: 12px;">ព័ត៌មានអាជីវកម្ម</strong>

                                                <div class="flex-data">
                                                    <strong>ឈ្មោះ:</strong>
                                                    {{selectedMission?.name}}
                                                </div>

                                                <div class="flex-data">
                                                    <strong>អាសយដ្ឋាន:</strong>
                                                    <span *ngIf="selectedMission?.provinceData?.name">
                                                        {{selectedMission?.provinceData?.name}},
                                                    </span>
                                                    <span *ngIf="selectedMission?.districtData?.name">
                                                        {{selectedMission?.districtData?.name}},
                                                    </span>
                                                    <span *ngIf="selectedMission?.communeData?.name">
                                                        {{selectedMission?.communeData?.name}},
                                                    </span>
                                                    <span *ngIf="selectedMission?.villageData?.name">
                                                        {{selectedMission?.villageData?.name}},
                                                    </span>
                                                    <span *ngIf="selectedMission?.homeNo">
                                                        {{selectedMission?.homeNo}},
                                                    </span>
                                                    <span *ngIf="selectedMission?.streetNo">
                                                        {{selectedMission?.streetNo}}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </agm-info-window>

                </agm-overlay>
            </ng-container>

            <ng-container *ngIf="selectedSearchType.key === 3">
                <agm-overlay *ngFor="let item of store?.laborBusinessdata" [latitude]="item?.map?.latitude"
                    [longitude]="item?.map?.longitude">
                    <div class="m-layer" (click)="clickedMarker(infowindow,item)">
                        {{item?.name}}
                    </div>
                    <agm-info-window #infowindow>
                        <div class="map-info-card">
                            <div class="card listing-cart">
                                <div class="listing-card-body">
                                    <div class="card-title" style="flex: 1;">
                                        <div class="item-for">
                                            <div class="listing-data">
                                                <div class="list-avatar" *ngIf="item?.photoFile?.downloadUrl">
                                                    <img [src]="item?.photoFile?.downloadUrl" alt="">
                                                </div>
                                                <div class="data-text">
                                                    <div class="flex-data">
                                                        <strong>ឈ្មោះ:</strong>
                                                        {{item?.name}}
                                                    </div>
                                                    <div class="flex-data">
                                                        <strong>អាសយដ្ឋាន:</strong>
                                                        <span *ngIf="item?.provinceData?.name">
                                                            {{item?.provinceData?.name}},
                                                        </span>
                                                        <span *ngIf="item?.districtData?.name">
                                                            {{item?.districtData?.name}},
                                                        </span>
                                                        <span *ngIf="item?.communeData?.name">
                                                            {{item?.communeData?.name}},
                                                        </span>
                                                        <span *ngIf="item?.villageData?.name">
                                                            {{item?.villageData?.name}},
                                                        </span>
                                                        <span *ngIf="item?.homeNo">
                                                            {{item?.homeNo}},
                                                        </span>
                                                        <span *ngIf="item?.streetNo">
                                                            {{item?.streetNo}}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </agm-info-window>
                </agm-overlay>
            </ng-container>
        </agm-map>
    </div>
</div>