import { Component, OnInit } from '@angular/core';
import { FormGroup, AbstractControl, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { debounceTime, switchMap, tap } from 'rxjs/operators';
import { MAP_OPTIONS } from 'src/app/constant/map.constant';
import { AuthStore } from 'src/app/core/stores/auth.store';
import { ConvertService } from 'src/app/shared/services/convert.service';
import { MappingService } from 'src/app/shared/services/mapping.service';
import { MapViewStore } from '../../stores/mapView.store';

@Component({
  selector: 'app-map-view',
  templateUrl: './map-view.component.html',
  styleUrls: ['./map-view.component.scss']
})
export class MapViewComponent implements OnInit {
  placeholder = 'ស្វែងរកបេសកកម្ម'
  zoom = 8;
  lat: number = 11.552528038335291;
  lng: number = 104.92437902984693;
  isMore: boolean = false;
  data;
  loading: boolean = false;
  isDetail: boolean = false;
  isFilter: boolean = false;

  previous;
  itemClick;
  selectedMission: any;

  searchType = [
    { key: 3, text: 'ស្វែងរកអ្នកដំណើរ', name: 'labors' },
    // { key: 2, text: 'ស្វែងរកអាជីវកម្ម', name: 'business' },
    // { key: 1, text: 'ស្វែងរកបេសកកម្ម', name: 'inspections' },

  ]
  selectedSearchType;
  form: FormGroup;
  search: AbstractControl;
  inspectionKey;
  map;
  constructor(
    public auth: AuthStore,
    public store: MapViewStore,
    public router: Router,
    public route: ActivatedRoute,
    public fb: FormBuilder,
  ) { }

  ngOnInit(): void {


    this.selectedSearchType = this.searchType[0]
    this.form = this.fb.group({
      search: [null]
    });
    this.search = this.form.controls["search"];


    this.route.queryParams.subscribe(async queryParams => {

      const { search, type, mission } = queryParams
      if (search) {
        this.form.patchValue({
          search: search,
        });
      }
      if (type) {
        this.selectedSearchType = await this.searchType.filter(f => f.name === type)[0];
      }
      // this.store.filterListing(search, this.selectedSearchType, this.inspectionKey ? this.inspectionKey : null, data => {
        // if (data) {
        //   this.isMore = true
        // } else {
        //   this.isMore = false
        // }
      // });

      // this.store.fetchEnviroment(data => {
      //   this.inspectionKey = data.inspectionKey;
      //   if (mission) {
      //     this.store.filterListingDoc(mission, this.selectedSearchType, this.inspectionKey ? this.inspectionKey : null, data => {
      //       if (data) {
      //         this.isMore = true
      //         this.isDetail = true
      //         this.selectedMission = data
      //         if (this.selectedSearchType.key === 3) {
      //           this.store.fetchLaborDetail(data.key);
      //         }
      //         if (this.selectedSearchType.key === 2) {
      //           this.store.fetchBusinessDetail(data.key);
      //         }
      //         if (this.selectedSearchType.key === 1) {
      //           this.store.fetchBusinessDetail(data.key);
      //         }

      //       } else {
      //         this.isDetail = false
      //         this.isMore = false
      //         this.selectedMission = null
      //       }
      //     });
      //   }
      // })
    })

    this.search.valueChanges
      .pipe(
        debounceTime(300),
        tap(() => (false)),
        switchMap(value => this.store.filterListing(value, this.selectedSearchType, this.inspectionKey ? this.inspectionKey : null, data => {
          this.isFilter = true;
          if (data) {
            this.isMore = true
          } else {
            this.isMore = false
          }
        }))
      ).subscribe(res => {
      });
  }

  onfocus() {
    this.isFilter = true;
  }

  onfocusOut() {
    this.isFilter = false;
  }


  toggleMore() {
    this.isMore = !this.isMore;
  }

  public getMapInstance(map: any): void { this.map = map; }


  backToResult() {
    this.isDetail = !this.isDetail;
    this.form.reset()
    this.router.navigate(['/map'], {
      queryParams:
      {
        // type: this.selectedSearchType.name,
        // search: this.search.value,
      }
    });
  }

  onChangeType(item) {
    this.selectedSearchType = item;
    this.store.data = [];
    const sparams = this.route.snapshot.queryParams
    this.router.navigate(['/map'], {
      queryParams:
      {
        type: this.selectedSearchType.name,
        search: this.search.value,
        mission: sparams.mission,
      }
    });

  }

  missionClick(item) {
    this.selectedMission = item;
    if (this.selectedSearchType.key === 1) {
      this.lat = this.selectedMission.districtData[0].location.latitude;
      this.lng = this.selectedMission.districtData[0].location.longitude;
      this.map.setCenter({ lat: this.lat, lng: this.lng });
    }
    if (this.selectedSearchType.key === 2) {
      this.lat = this.selectedMission.map.latitude;
      this.lng = this.selectedMission.map.longitude;
    }
    if (this.selectedSearchType.key === 3) {
      const { businessRef } = this.selectedMission;
      if (businessRef) {
        businessRef.get().then(doc => {
          const data = doc.data()
          this.lat = data.map.latitude;
          this.lng = data.map.longitude;
          this.map.setCenter({ lat: this.lat, lng: this.lng });
        })
      }
    }

    this.router.navigate(['/map'], {
      queryParams:
      {
        type: this.selectedSearchType.name,
        search: item.name ? item.name : item.surname + ' ' + item.givenName,
        mission: item.key,
      }
    });
    this.map.setCenter({ lat: this.lat, lng: this.lng });

    this.isDetail = true;
    this.isFilter = false;
    this.zoom = 13;
  }


  clickedMarker(infowindow, m) {
    this.itemClick = m;
    if (this.previous) {
      this.previous.close();
    }
    this.previous = infowindow;
  }
}
