import { AbstractControl } from '@angular/forms';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { firestore } from 'firebase/app';
import { months, periodData } from '../../core/dummy/report';
import { toNumber } from './mapping.service';
import momentkh from '@thyrith/momentkh'

@Injectable({
  providedIn: 'root'
})
export class ConvertService {

  constructor() { }

  static getCrossMonth(dateKey: number) {

    const yearKey = toNumber(`${dateKey}`.slice(0, 4))
    const monthKey = toNumber(`${dateKey}`.slice(4, 6))
    const dayKey = toNumber(`${dateKey}`.slice(6, 8))

    let month = monthKey
    let year = yearKey
    if (dayKey >= 18){
      month = monthKey + 1
      month = month > 12 ? 1 : month
    }
    const monthR = `${month}`.length === 1 ? `0${toNumber(month)}` : `${month}`

    if (monthKey == 12 && dayKey >= 18){
      year = yearKey + 1
    }

    return `${year}${monthR}`
    
  }

  static getDateOfLastYearNew() {
    const current = toNumber(moment(new Date()).subtract(1, 'years').format('YYYY'))
    const last = current - 1;
    return {
      form_date: toNumber(`${last}1120`),
      to_date: toNumber(`${current}1119`),
    };
  }
  static getDateSecondSemester() {
    const current = toNumber(moment(new Date()).format('YYYY'))
    const last = current;
    return {
      form_date: toNumber(`${last}0520`),
      to_date: toNumber(`${current}1119`),
    };
  }
  static getDateFirstQuarter() {
    const current = toNumber(moment(new Date()).format('YYYY'))
    const last = current - 1;
    return {
      form_date: toNumber(`${last}1120`),
      to_date: toNumber(`${current}0219`),
    };
  }

  static getDateSecondQuarter() {
    const current = toNumber(moment(new Date()).format('YYYY'))
    const last = current;
    return {
      form_date: toNumber(`${last}0220`),
      to_date: toNumber(`${current}0519`),
    };
  }

  static getDateFirstSemester() {
    const current = toNumber(moment(new Date()).format('YYYY'))
    const last = current - 1;
    return {
      form_date: toNumber(`${last}1120`),
      to_date: toNumber(`${current}0519`),
    };
  }
  static getDateCurrentMonth() {
    const current = moment(new Date()).format('YYYYMM')
    const last = toNumber(moment(new Date()).subtract(1, 'months').format('YYYYMM'))
    return {
      form_date: toNumber(`${last}20`),
      to_date: toNumber(`${current}19`),
    };
  }
  static getDateNineMonth() {
    const current = toNumber(moment(new Date()).format('YYYY'))
    const last = current - 1;
    return {
      form_date: toNumber(`${last}1120`),
      to_date: toNumber(`${current}0819`),
    };
  }

  static getDateOfYearNew() {
    const current = toNumber(moment(new Date()).format('YYYY'))
    const last = current - 1;
    return {
      form_date: toNumber(`${last}1120`),
      to_date: toNumber(`${current}1119`),
    };
  }
  static toCapitalize(value) {
    let string = null;
    if (value) string = value.toUpperCase().toString().trim();
    return string;
  }

  static toDate(sqlDate: any) {
    const date = moment(sqlDate);
    return date;
  }
  static toCalendar(date: any) {
    return moment(date).days()
  }

  static fromPeriodToDate(dateString: string) {
    return moment(dateString, 'YYYY-MM-DD').toDate();
  }
  static toPeriodToDate(dateString: Date) {
    return moment(dateString).toDate();
  }

  static numberToDate(date: number) {
    return moment(date, 'YYYYMMDD').toDate();
  }

  static numberToDateMonth(date: number) {
    return moment(date, 'YYYYMM').toDate();
  }

  static numberToStartDay(date: number) {
    return moment(date, 'YYYYMMDD').startOf('day').toDate();
  }

  static numberToEndDay(date: number) {
    return moment(date, 'YYYYMMDD').endOf('day').toDate();
  }

  static toDayKey(date: Date) {
    const dateKey = moment(date).format('DD');
    return dateKey.toString();
  }

  static toOnlyMonthKey(date: Date) {
    const dateKey = moment(date).format('MM');
    return dateKey.toString();
  }

  static getDaySchedule(days) {
    var daysInput = Object.keys(days).map(function (key) {
      return { name: key, value: days[key] };
    });

    daysInput = daysInput.filter(m => m.value === true)
    daysInput.sort((a: any, b: any) => {
      var day1 = a.name
      var day2 = b.name
      if (daysOfWeek[day1] < daysOfWeek[day2]) {
        return -1;
      } else if (daysOfWeek[day1] > daysOfWeek[day2]) {
        return 1;
      } else {
        return 0;
      }
    });
    let result = '';
    daysInput.forEach(item => {
      if (item.name === 'monday')
        result = result + 'Monday / '
      else if (item.name.toLowerCase() === 'tuesday')
        result = result + 'Tuesday / '
      else if (item.name.toLowerCase() === 'friday')
        result = result + 'Friday / '
      else if (item.name.toLowerCase() === 'saturday')
        result = result + 'Saturday / '
      else if (item.name.toLowerCase() === 'sunday')
        result = result + 'Sunday / '
      else if (item.name.toLowerCase() === 'thursday')
        result = result + 'Thursday / '
      else
        result = result + 'Wednesday / '
    })
    if (result.length > 3)
      result = result.substring(0, result.length - 3)
    return result;
  }

  getAge(DOB) {
    var today = new Date();
    var birthDate = new Date(DOB);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age = age - 1;
    }
    return age;
  }
  static toFloatFixed2(value) {
    if (value === null || value === "" || value === undefined) {
      return 0;
    }
    if (Number(value) === NaN) return 0;
    return Number(value.toFixed(2));
  }

  static toNumber(value) {
    if (value === null || value === "" || value === undefined) {
      return 0;
    }
    if (Number(value) === NaN) return 0;
    return Number(value);
  }
  static toNull(value) {
    if (value === null || value === "" || value === undefined) {
      return null;
    }
    return value;
  }
  static isNullUndefinedEmpty(value) {
    if (value === null || value === "" || value === undefined) {
      return true;
    }
    return false;
  }
  static toNullInputValue(input: AbstractControl) {
    if (input === undefined || undefined === null) return null;
    const value = input.value;
    if (value === null || value === "" || value === undefined) {
      return null;
    }
    return value;
  }

  static age18() {
    return moment().add(-18, 'year').toDate();
  }

  static yearSuffix() {
    return moment().format('YY').toString();
  }
  static addWeek(interval: number) {
    return moment().add(interval, 'week').toDate()
  }

  static addMonth(interval: number) {
    return moment().add(interval, 'month').toDate()
  }

  static addYear(interval: number) {
    return moment().add(interval, 'year').toDate()
  }

  static addYearDate(date: any,interval: number) {
    return moment(date).add(interval, 'year').toDate()
  }

  static addDateDay(date: any, interval: number) {
    return moment(date).add(interval, 'days').toDate()
  }

  static generate_sample_invoiceNo(config) {
    const number = moment().format('YYMMDD').toString() + (config + 1).toString();
    return number;
  }

  static toMonthKey(date: Date) {
    const dateKey = moment(date).format('YYYYMM');
    return dateKey.toString();
  }

  static toYearKey(date: Date) {
    const dateKey = moment(date).format('YYYY');
    return dateKey.toString();
  }

  static yearKey() {
    return Number(moment().format('YYYY'))
  }

  static yearNumberKey(date: Date) {
    return Number(moment(date).format('YYYY'))
  }


  static monthNumberKey(date: Date) {
    return Number(moment(date).format('MM'))
  }

  static pageKey() {
    return Number(moment().format('YYYYMMDDHHmmss'))
  }

  static toDateKey(date: Date) {
    return Number(moment(date).format('YYYYMMDD'))
  }

  static toDateStringKey(date: Date) {
    return String(moment(date).format('YYYYMMDD'))
  }

  static dateKey() {
    return Number(moment().format('YYYYMMDD'))
  }

  static dateThreeOneKey(day, month, year) {
    const date = `${year}${month}${day}`;
    return this.toDateSting(moment(date).toDate());
  }

  static toDateSting(date: any) {
    return moment(date).format('YYYY-MM-DD');
  }

  static dateLastMonth(month: number) {
    return moment(new Date()).subtract(month, 'months').startOf('month');
  }

  static getDefaultDateReport(day: number) {
    const yearMonth = moment(new Date).format('YYYYMM');
    const yearMonthDay = `${yearMonth}${day}`;
    const toDate = moment(yearMonthDay).toDate();
    return {
      form_date: this.toDateSting(moment(toDate).subtract(3, 'months').add(1, 'days')),
      to_date: this.toDateSting(toDate),
    };
  }

  static getDateOfMonth(day: number, months) {
    const yearMonthFrom = moment(new Date).subtract(months, 'months').format('YYYYMM');
    const yearMonthDay = `${yearMonthFrom}${day + 1}`;
    const yearMonth = moment(new Date).format('YYYYMM');
    const toDate = moment(`${yearMonth}${day}`).toDate();
    return {
      form_date: this.toDateSting(yearMonthDay),
      to_date: this.toDateSting(toDate),
    };
  }


  static getCurrentYear() {
    const year = moment(new Date).format('YYYY');
    return year;
  }

  static hourToNumber(time) {
    return Number(moment(time, 'hh:mm a').format('HH').toString() + '.' + moment(time, 'hh:mm a').format('mm').toString());
  }

  static toHour(time) {
    return moment(time, 'hh:mm a').format('hh:mm a')
  }

  static generate_testing_invoiceNo(config) {
    var number = moment().format('YYMMDD').toString() + (config.invoice_shufit + 1).toString();
    return number;
  }

  static generate_puc_id(config) {
    var number = moment().format('YY').toString() + (config.puc_id + 1).toString();
    return number;
  }

  static addDays(totalDay: number) {
    return moment().add(totalDay, 'days').toDate();
  }

  toCapitalize(value) {
    let string = null;
    if (value) string = value.toUpperCase().toString().trim();
    return string;
  }

}
export function dateKeyToDate(dateKey: string | number) {
  return (moment(dateKey, 'YYYYMMDD').toDate())
}
export const daysOfWeek = {
  "monday": 1,
  "tuesday": 2,
  "wednesday": 3,
  "thursday": 4,
  "friday": 5,
  "saturday": 6,
  "sunday": 7
}

export function startMonthFromDate(date: any) {
  return moment(date).startOf('month').toDate();
}
export function endMonthFromDate(date: any) {
  return moment(date).endOf('month').toDate();
}

export function startYearFromDate(date: any) {
  return moment(date).startOf('year').toDate();
}
export function endYearFromDate(date: any) {
  return moment(date).endOf('year').toDate();
}

export function numberToDate(date: number) {
  return moment(date, 'YYYYMMDD').toDate();
}

export function subtractWeek(interval: number) {
  return moment().subtract(interval, 'week').toDate()
}

export function toDateKey(date: Date) {
  return Number(moment(date).format('YYYYMMDD'))
}

export function toKhmerDate(date: Date, type: string): any {
  if (!date) return
  const newMoment: any = momentkh(moment)
  const dateToNum = toDateKey(date)
  return newMoment(dateToNum, 'YYYYMMDD').toLunarDate(type);
}

export function toKhDay(date: any) {
  if (!date) return
  return moment(date).locale('km').format('DD');
}

export function toKhMonth(date: any) {
  if (!date) return
  return moment(date).locale('km').format('MMMM');
}

export function toKhYear(date: any) {
  if (!date) return
  return moment(date).locale('km').format('Y');
}

export function toDayOfMonth(date: any) {
  const index = moment(date).format("DD");
  return index;
}

export function toMonthOfYear(date: Date) {
  const index = moment(date).format("MM");
  return index;
}

export function toYearOfPeriod(date: any) {
  const index = moment(date).format("YYYY");
  return index;
}

export function sum(data: Array<any>, field: string) {
  if (!data) return 0;
  return data.reduce((a, b) => a + Number(b[field]), 0)
}


export function sumArray(data: Array<any>, field: string) {
  if (!data) return 0;
  return data.reduce((a, b) => a + Number(b[field]), 0)
}



export function pushToArray(snapshot: firestore.QuerySnapshot) {
  if (snapshot.empty) return [];
  return snapshot.docs.map(m => ({ ...m.data() }));
}

export function toMonthKh(date: any) {
  const index = moment(date).format("MM");
  return months[index].name
}

export function getDateReport(optionKey) {
  const items = periodData.filter(m => m.key === optionKey)[0];
  switch (toNumber(items.key)) {
    case 1:
      const fromDate1 = ConvertService.getDateCurrentMonth().form_date
      const toDate1 = ConvertService.getDateCurrentMonth().to_date
      return {
        fromDate: fromDate1,
        toDate: toDate1
      }
    case 2:
      const fromDate2 = ConvertService.getDateFirstQuarter().form_date
      const toDate2 = ConvertService.getDateFirstQuarter().to_date
      return {
        fromDate: fromDate2,
        toDate: toDate2
      }
    case 3:
      const fromDate3 = ConvertService.getDateSecondQuarter().form_date
      const toDate3 = ConvertService.getDateSecondQuarter().to_date
      return {
        fromDate: fromDate3,
        toDate: toDate3
      }
    case 4:
      const fromDate4 = ConvertService.getDateFirstSemester().form_date
      const toDate4 = ConvertService.getDateFirstSemester().to_date
      return {
        fromDate: fromDate4,
        toDate: toDate4
      }
    case 5:
      const fromDate5 = ConvertService.getDateSecondSemester().form_date
      const toDate5 = ConvertService.getDateSecondSemester().to_date
      return {
        fromDate: fromDate5,
        toDate: toDate5
      }
    case 6:
      const fromDate6 = ConvertService.getDateNineMonth().form_date
      const toDate6 = ConvertService.getDateNineMonth().to_date
      return {
        fromDate: fromDate6,
        toDate: toDate6
      }
    case 7:
      const fromDate7 = ConvertService.getDateOfYearNew().form_date
      const toDate7 = ConvertService.getDateOfYearNew().to_date
      return {
        fromDate: fromDate7,
        toDate: toDate7
      }
    case 8:
      const fromDate8 = ConvertService.getDateOfLastYearNew().form_date
      const toDate8 = ConvertService.getDateOfLastYearNew().to_date
      return {
        fromDate: fromDate8,
        toDate: toDate8
      }
    default:
      break;
  }

}


export const createKeywords = name => {
  const arrName = [];
  let curName = '';
  let nextName = '';
  name.trim().split(/[ .\-_ ]/).forEach(letter => {
    curName += letter;
    arrName.push(ConvertService.toCapitalize(letter));
    arrName.push(ConvertService.toCapitalize(curName));
  });

  name.split('').forEach(letter => {
    nextName += letter;
    arrName.push(ConvertService.toCapitalize(nextName));
  });

  return arrName;
}

export const generateKeywords = names => {
  const [keyword] = names;
  const suffix = keyword.length > 0 ? `${keyword}` : '';
  const keywordName = createKeywords(`${suffix}`);
  return [
    ...new Set([
      '~N/A~',
      // ConvertService.toCapitalize(keyword),
      ...keywordName
    ])
  ];
}
