
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, AbstractControl, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { debounceTime, switchMap, tap } from 'rxjs/operators';
import { AuthStore } from 'src/app/core/stores/auth.store';
import { MappingService } from 'src/app/shared/services/mapping.service';
import { StatisticPassenger } from '../../stores/StatisticPassenger.store';


@Component({
  selector: 'app-statistic-filter-post',
  templateUrl: './statistic-filter-post.component.html',
  styleUrls: ['./statistic-filter-post.component.scss']
})
export class StatisticFilterPostComponent implements OnInit {
  @Input() reportType: any;
  @Input() type: any;

  form: FormGroup;
  search: AbstractControl
  data;
  constructor(
    public store: StatisticPassenger,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private auth: AuthStore

  ) { }

  async ngOnInit(): Promise<void> {
    this.form = this.fb.group({
      search: [null]
    });
    this.search = this.form.controls["search"];

    await this.auth.fetchUserDoc()

    const { profile } = this.auth
    this.route.queryParams.subscribe(async queryParams => {
      this.store.fetchData('posts', data => {
        this.data = data.filter(m => m.status.key === 1)

        if (profile) {
          const { role, post, post_province } = profile
          switch (role.key) {
            case 1:
              this.data = data.filter(m => m.province.key === post_province.key)
              break;
            case 2:
              this.data = data.filter(m => m.key === post.key)
              break;
            case 3:
              this.data = data.filter(m => m.key === post.key)
              break;

            default:
              break;
          }
        }

        this.data = MappingService.orderBy(this.data, "province.id")
      });
    })

    // this.search.valueChanges
    //   .pipe(
    //     debounceTime(300),
    //     tap(() => (false)),
    //     switchMap(value => this.store.autofilter('posts', value, data => {
    //       this.data = data.filter(m => m.status.key === 1)
    //       this.data = MappingService.orderBy(this.data, "province.id")
    //     }))
    //   ).subscribe(res => {
    //   });
  }

  displayItem(item: any): string {
    return item ? item.name : item;
  }

  _onPress(filterAll) {
    const sparams = this.route.snapshot.queryParams
    this.router.navigate([`/statistic/${this.type ? `/${this.type}` : ''}`], {
      queryParams:
      {
        travelKey: sparams.travelKey ? sparams.travelKey : null,
        postKey: filterAll ? null : this.search.value.key,
        fromdate: sparams.fromdate ? sparams.fromdate : null,
        todate: sparams.todate ? sparams.todate : null,
      }
    });
  }

}
