import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, AbstractControl, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { CONFIG } from 'src/app/core/dummy/app';
import { AuthStore } from 'src/app/core/stores/auth.store';
import { IProvince } from 'src/app/modules/geo-data/interfaces/geo';
import { MappingService } from 'src/app/shared/services/mapping.service';
import { ReportStore } from '../../stores/report.store';

@Component({
  selector: 'app-report-filter-post',
  templateUrl: './report-filter-post.component.html',
  styleUrls: ['./report-filter-post.component.scss']
})
export class ReportFilterPostComponent implements OnInit {
  @Input() voa: any;
  @Input() reportType: any;
  @Input() type: any;

  form: FormGroup;
  search: AbstractControl
  data: any[] = []
  filteredPostOptions: Observable<IProvince[]>;

  constructor(
    public store: ReportStore,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private auth: AuthStore

  ) { }

  async ngOnInit(): Promise<void> {
    this.form = this.fb.group({
      search: [null, [MappingService.validSelected.bind(this)]],
    });
    this.search = this.form.controls["search"];

    await this.auth.fetchUserDoc()

    const { profile } = this.auth
    this.route.queryParams.subscribe(async queryParams => {
      this.store.fetchData('posts', data => {
        this.data = data.filter(m => m.status.key === 1)
        if (profile) {
          const { role, post, post_province, department } = profile
          switch (role.key) {
            case 1:
              this.data = data.filter(m => m.province.key === post_province.key)
              break;
            case 2:
              this.data = data.filter(m => m.key === post.key)
              break;
            case 3:
              this.data = data.filter(m => m.key === post.key)
              break;
            case 4:
              this.data = data.filter(m => m.department.key === department.key)
              break;
            case 5:
              this.data = data.filter(m => m.department.key === department.key)
              break;
            default:
              break;
          }
        }

        this.data = MappingService.orderBy(this.data, "province.id")
        if (this.voa)
          this.data = this.data.filter(m => m.department && m.department.key === CONFIG.N2_KEY)

        this.filteredPostOptions = MappingService.autoComplete(this.search, this.data, 'name');
      });
    })

    // this.search.valueChanges
    //   .pipe(
    //     debounceTime(300),
    //     tap(() => (false)),
    //     switchMap(value => this.store.autofilter('posts', value, data => {
    //       this.data = data.filter(m => m.status.key === 1)
    //       this.data = MappingService.orderBy(this.data, "province.id")
    //     }))
    //   ).subscribe(res => {
    //   });
  }

  displayItem(item: any): string {
    return item ? item.name : item;
  }

  _onPress(filterAll) {
    const sparams = this.route.snapshot.queryParams
    this.router.navigate([`/reports/${this.reportType}${this.type ? `/${this.type}` : ''}`], {
      queryParams:
      {
        travelKey: sparams.travelKey ? sparams.travelKey : null,
        postKey: filterAll ? null : this.search.value.key,
        fromdate: sparams.fromdate ? sparams.fromdate : null,
        todate: sparams.todate ? sparams.todate : null,
      }
    });
  }

}
