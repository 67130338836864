<form [formGroup]="form" class="filter-penal" [formGroup]="form" style="width: 360px;">
    <h4>ជ្រើសរើសតាមប៉ុស្តិ៍</h4>
    <div class="filter-box">
        <button mat-button (click)="_onPress(true)" class="filter-panel-item">
            ប៉ុស្តិ៍ទាំងអស់
        </button>
    </div>
    <div class="form-wrapper" style="padding: 0;">
        <div class="form-group">
            <mat-form-field appearance="outline">
                <mat-label>ប៉ុស្តិ៍</mat-label>
                <input [matAutocomplete]="autoPost" required formControlName="search" matInput placeholder="ប៉ុស្តិ៍">
                <mat-autocomplete [displayWith]="displayItem" autoActiveFirstOption #autoPost="matAutocomplete">
                    <mat-option *ngFor="let item of filteredPostOptions | async" [value]="item">
                        {{item?.name}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </div>
    </div>

    <div class="price-footer">
        <button mat-button (click)="_onPress(false)" class="btn-apply">បង្ហាញទិន្នន័យ</button>
    </div>
    <app-spinner *ngIf="store?.process"></app-spinner>
</form>