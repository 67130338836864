import { Component, Input, OnInit } from '@angular/core';
import { toJS } from 'mobx';
import { single } from 'src/app/core/dummy/app';

@Component({
  selector: 'app-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss']
})
export class PieChartComponent implements OnInit {
  @Input() data: any;
  
  single: any[] =[];
  view: [number,number] = [450, 400];

  // options
  gradient: boolean = false;
  showLegend: boolean = true;
  showLabels: boolean = true;
  isDoughnut: boolean = false;
  legendPosition: string = 'below';

  colorScheme = {
    domain: ['#00b4d8', '#ff9e00', '#C7B42C', '#AAAAAA']
  };

  constructor() {
    Object.assign(this, { single });
  }

  result = []
  ngOnInit() {
      if(this.data && this.data.length){
        this.result = this.data.map(m=>{
          return{
            name: m.name,
            value: m.total,
          }
        })
      }
      // console.log('data2',toJS(this.data2));
      
      // if(this.data2 && this.data2.length){
      //   this.result = this.data2.map(m=>{
      //     return{
      //       name: m.name,
      //       value: m.total,
      //     }
      //   })
      //   console.log( toJS(this.result),'result2');
      // }
      
  }

  onSelect(data): void {
    console.log('Item clicked', JSON.parse(JSON.stringify(data)));
  }

  onActivate(data): void {
    console.log('Activate', JSON.parse(JSON.stringify(data)));
  }

  onDeactivate(data): void {
    console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }

}
