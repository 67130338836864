import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthStore } from 'src/app/core/stores/auth.store';
import { DeleteComponent } from 'src/app/shared/components/delete/delete.component';

@Component({
  selector: 'dashboard-layout',
  templateUrl: './dashboard-layout.component.html',
  styleUrls: ['./dashboard-layout.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DashboardLayoutComponent implements OnInit {
  isMore: boolean = false;
  inspectKey: any = null;

  constructor(
    public dialog: MatDialog,
    private auth: AuthStore
  ) { }

  async ngOnInit() {
    const item: any = await this.auth.fetchConfig();
    this.inspectKey = item && item.inspectionKey
  }

  toggleMore() {
    this.isMore = !this.isMore;
    const dialogRef = this.dialog.open(DeleteComponent, {
      data: null,
      panelClass: 'cs-dialog-wrapper',
      width: '35vw',
      height: '96vh',
      disableClose: false,
      role: 'dialog',
    });
    dialogRef.updatePosition({ top: '16px', right: '16px', bottom: '16px' });
    dialogRef.afterClosed().subscribe(result => { });

  }

}
