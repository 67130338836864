<div class="sticky-content">
    <h3 class="navigation-title">ផែនការ</h3>
    <div class="inspect-card" *ngIf="store?.inspect">
        <h4>{{store?.inspect?.name}}</h4>
        <p>{{store?.inspect?.startDateKh}}</p>
    </div>
    <div class="sdivider m-tb"></div>
    <div class="insp-g rows">
        <h3 class="navigation-title">កំពុងចុះត្រួតពិនិត្យ</h3>
        <span class="badge" *ngIf="store?.missions?.length>0">{{store?.missions?.length}}</span>
    </div>
    <div *ngIf="store?.missions?.length>0" class="navigation-menu ch-item">
        <div class="navigation-menu-item" *ngFor="let item of store?.missions; let i = index">
            <div class="m-content lg-avatar">
                <div class="m-text">
                    {{item?.name}}
                </div>
            </div>
        </div>
    </div>
    <div class="sdivider m-tb"></div>
    <h3 class="navigation-title">គណនី</h3>
    <div class="navigation-menu ch-item">
        <div class="navigation-menu-item" *ngFor="let item of store?.officers; let i = index">
            <div class="m-content lg-avatar">
                <div class="pwstatus">
                    <mat-icon class="mat-18" style="background: #ccc;">
                        <img [src]="item?.photo?.downloadUrl||NO_AVATAR">
                    </mat-icon>
                    <app-presence-status [uid]="item?.key"></app-presence-status>
                </div>
                <div class="m-text">
                    {{item?.fullName}}
                </div>
            </div>
        </div>
    </div>
</div>