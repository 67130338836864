import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { PHOTO_LIB } from 'src/app/constant/photo.constant';
import { AuthStore } from 'src/app/core/stores/auth.store';
import { ChartStore } from 'src/app/core/stores/chart.store';
import { ChatDialogComponent } from '../chat-dialog/chat-dialog.component';

@Component({
  selector: 'chart-and-inspect',
  templateUrl: './chart-and-inspect.component.html',
  styleUrls: ['./chart-and-inspect.component.scss']
})
export class ChartAndInspectComponent implements OnInit {
  inspectKey: any;
  subscribeInspectRef: any;
  NO_AVATAR = PHOTO_LIB.EMPTY;
  constructor(
    public store: ChartStore,
    public dialog: MatDialog,

    private route: ActivatedRoute,
    private auth: AuthStore) { }


  async ngOnInit() {
    const config: any = await this.auth.fetchConfig();
    this.route.params.subscribe(params => {
      const { id } = params;
      this.inspectKey = id || (config && config.inspectionKey)
      this.store.fetchSelectedInspect(this.inspectKey)
      this.auth.fetchUser(res => {
        const { profile } = this.auth;
        const { permission, provinceKey } = profile;
        this.store.fetchOfficer(permission ? permission.key : null, provinceKey)
        this.store.fetchOfficerMission(permission ? permission.key : null, provinceKey, this.inspectKey)
      })
    })
  }

  onChat(item){
    const dialogRef = this.dialog.open(ChatDialogComponent, {
      data: item,
      panelClass: 'cs-dialog-wrapper',
      // width: '300px',
      // height: '360px',
      disableClose: false,
      role: 'dialog',
      // hasBackdrop:false,
    });
    dialogRef.updatePosition({ top: '', right: '16px', bottom: '16px' });
    dialogRef.afterClosed().subscribe(result => { });
  }

  ngOnDestroy() {
    const { fetchOfficerRef, fetchSelectedInspectRef, fetchOfficerMissionRef } = this.store;
    fetchOfficerRef && fetchOfficerRef.unsubscribe();
    fetchSelectedInspectRef && fetchSelectedInspectRef.unsubscribe();
    fetchOfficerMissionRef && fetchOfficerMissionRef.unsubscribe();
  }

}
