import { Router } from '@angular/router';
import { observable, action, computed, } from 'mobx';
import { Injectable, NgZone } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import 'firebase/firestore';
import { auth, User } from 'firebase/app';
import { Observable, Subscription } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { MappingService } from 'src/app/shared/services/mapping.service';
import { FirebaseService } from '../services/firebase.service';
import * as firebase from 'firebase/app';
import { UserAccountService } from 'src/app/modules/officer/services/userAccount.service';
import { PresenceService } from 'src/app/shared/services/presence.service';

@Injectable({ providedIn: 'root' })
export class AuthStore {
  @observable remember: boolean = false;
  @observable isLogged: boolean = false;
  @observable process: boolean = false;
  @observable loading: boolean = true;
  @observable userData: any = null;
  @observable profile: any = null;

  @observable user$: Observable<User>;
  @observable user: User;
  @observable serverDateTime: any = null;

  constructor(
    private router: Router,
    public authService: AuthService,
    public ngZone: NgZone,
    private db: AngularFirestore,
    private fs: FirebaseService,
    private ds: UserAccountService,
    private presence: PresenceService,
  ) { }

  @action
  async fetchEnv() {
    const doc = await this.fs.envRef().get().toPromise();
    return MappingService.pushToObject(doc);
  }

  @action
  async fetchEnvCallback(callback) {
    this.fs.envRef().valueChanges().subscribe(doc=>{
      callback(doc)
    })
  }

  @action
  async fetchConfig() {
    const doc = await this.fs.configRef().get().toPromise();
    return MappingService.pushToObject(doc);
  }

  @action
  async fetchUserProfile() {
    const doc = await this.userRef().get().toPromise();
    this.profile = MappingService.pushToObject(doc);
    return this.profile;
  }

  @action
  userRef() {
    return this.db.collection('users').doc(this.user.uid)
  }

  @action
  fetchUser(callback?: (profile: any) => void) {
    this.authService.authRef().authState.subscribe(async user => {
      if (!user) {
        this.signOut();
        return;
      }
      this.user = user;
      const doc = await this.userRef().get().toPromise();
      this.profile = MappingService.pushToObject(doc);

      if (callback) callback(this.profile);
    })
  }

  @action
  changeAvatar(fileUrl, fileName, user) {
    this.loading = true;
    this.ds.userCollectionRef().doc(user.uid).update({
      photoURL: fileUrl,
      photoName: fileName,
      photo: {
        downloadUrl: fileUrl,
        fileName: fileName,
      }
    })
    // this.ds.employeeCollectionRef().doc(user.uid).update({
    //   photoURL: fileUrl,
    //   photoName: fileName,
    //   photo: {
    //     downloadUrl: fileUrl,
    //     fileName: fileName,
    //   }
    // })
    this.loading = false

  }

  @action
  changePassword(oldPassword: any, newPassword: any, callback) {
    const user = firebase.auth().currentUser;
    this.authService.authRef().signInWithEmailAndPassword(user.email, oldPassword).then((account) => {
      user.updatePassword(newPassword).then(() => {
        callback(true, null)
      }).catch(error => {
        alert(error)
        callback(false, error)
      })
    }).catch(error => {
      alert(error)
      callback(false, error)
    })
  }


  @action
  onUnsubscribeCanActiveRef = () => {
    if (this.fetchCanActiveAuthRef) this.fetchCanActiveAuthRef()
    return Promise.resolve()
  }


  @observable userProfile: Subscription = null;
  fetchCanActiveAuthRef: any;
  @action
  async fetchCanActive(callback: (profile: any) => void) {
    this.loading = true;
    let _flag = ""
    await this.onUnsubscribeCanActiveRef()
    this.fetchCanActiveAuthRef = this.authService.authRef().authState.subscribe(async user => {
      this.user = user;
      if (user && user.uid !== _flag) {
        this.presence.getPresence(user.uid).subscribe((doc: any) => {
          this.serverDateTime = new Date(doc.timestamp)
        })
        this.userData = {
          key: user.uid,
          uid: user.uid,
          email: user.email,
          displayName: user.displayName,
          photoURL: user.photoURL,
          emailVerified: user.emailVerified
        };
        localStorage.setItem('user', JSON.stringify(this.userData));
        JSON.parse(localStorage.getItem('user'));
        this.userProfile && this.userProfile.unsubscribe()
        this.userProfile = this.userRef().valueChanges().subscribe(data => {
          this.profile = data;
          callback && callback(this.userData)
        })
        this.loading = false;
      } else {
        localStorage.setItem('user', null);
        JSON.parse(localStorage.getItem('user'));
        this.loading = false;
        callback && callback(null)
      }
      _flag = (user && user.uid) || ""
    })
  }

  @action
  async fetchUserDoc() {
    this.loading = true;
    const userDoc = (await this.authService.currentUserRef());
    if (!userDoc) {
      this.signOut()
      this.loading = false;
      return;
    }
    if (userDoc) {
      const doc = await this.ds.userRef().doc(userDoc.uid).get().toPromise();
      this.profile = MappingService.pushToObject(doc);
    }
    this.loading = false;
    return this.profile;
  }

  // @action
  // signIn(email, password, callback) {
  //   this.loading = true;
  //   this.authService.authRef().signInWithEmailAndPassword(`${email}@immigration.gov.kh`, password)
  //     .then(async (result) => {
  //       if (result && result.user) {
  //         await this.fetchUserDoc()
  //         this.setUserData(result.user);
  //         callback(true, result.user)
  //       }
  //       this.loading = false;
  //     }).catch((error) => {
  //       this.loading = false;
  //       callback(false, error)
  //       window.alert(error.message)
  //     })
  // }

  @action
  signIn(email, password) {
    return this.authService.authRef().signInWithEmailAndPassword(`${email}@immigration.gov.kh`, password)
      .then((result) => {
        this.ngZone.run(() => {
          this.router.navigate(['/']);
        });
        this.setUserData(result.user);
      }).catch((error) => {
        window.alert(error.message)
      })
  }


  // Sign up with email/password
  @action
  signUp(email, password) {
    return this.authService.authRef().createUserWithEmailAndPassword(email, password)
      .then((result) => {
        this.setUserData(result.user);
        // this.sendVerificationMail();

      }).catch((error) => {
        window.alert(error.message)
      })
  }

  // Send email verfificaiton when new user sign up
  @action
  sendVerificationMail() {
    return this.user.sendEmailVerification()
      .then(() => {
        this.router.navigate(['verify-email-address']);
      })
  }
  // Reset Forggot password
  @action
  forgotPassword(passwordResetEmail) {
    return this.authService.authRef().sendPasswordResetEmail(passwordResetEmail)
      .then(() => {
        window.alert('Password reset email sent, check your inbox.');
      }).catch((error) => {
        window.alert(error)
      })
  }

  // Returns true when user is looged in and email is verified
  @computed
  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('user'));
    // return (user !== null && user.emailVerified !== false) ? true : false;
    return user !== null ? true : false;
  }

  // Sign in with Google
  @action
  googleAuth() {
    return this.authLogin(new auth.GoogleAuthProvider()).then(() => {
      // this.ngZone.run(() => {
      this.router.navigate(['/']);
      // })
    });
  }

  // Auth logic to run auth providers
  @action
  authLogin(provider) {
    return this.authService.authRef().signInWithPopup(provider)
      .then((result) => {
        this.setUserData(result.user);
      }).catch((error) => {
        window.alert(error)
      })
  }

  /* Setting up user data when sign in with username/password,
  sign up with username/password and sign in with social auth
  provider in Firestore database using AngularFirestore + AngularFirestoreDocument service */
  @action
  setUserData(user) {
    const userRef: AngularFirestoreDocument<any> = this.authService.userRef().doc(user.uid);
    const userData: any = {
      key: user.uid,
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
      photoURL: user.photoURL,
      emailVerified: user.emailVerified
    }
    return userRef.set(userData, {
      merge: true
    })
  }

  // Sign out
  @action
  signOut() {
    this.userProfile && this.userProfile.unsubscribe()
    return this.authService.authRef().signOut().then(() => {
      localStorage.removeItem('user');
      this.router.navigate(['auth']);
    })
  }


}
