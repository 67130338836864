import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'userRole'
})
export class UserRolePipe implements PipeTransform {

  transform(role: any): any {
    let value = false
    if (role) {
      switch (role.key) {
        case 0:
          value = true
          break;
        case 5:
          value = true
          break;
        case 10:
          value = true
          break;
        case 99:
          value = true
          break;
        default:
          break;
      }
    }
    return value;
  }

}
