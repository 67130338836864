<dashboard-nav></dashboard-nav>

<main class="main-dashboard main-dashboard-report" *mobxAutorun>
    <div class="navigation exp-doc">
        <div class="ac-ln-background"></div>
        <div class="sticky-content">

            <div class="sdivider" style="margin: 0px;"></div>

            <mat-accordion class="mat-nav-bar">
                <mat-expansion-panel [expanded]="expansions.one" (closed)="onClosed(1)" (opened)="onOpened(1)">
                    <mat-expansion-panel-header collapsedHeight="78" expandedHeight="78"
                        [class.active]="expansions.one">
                        <mat-panel-title>
                            <strong>អ្នកបើកបរ និងអ្នករួមដំណើរ (ខ្មែរ)</strong>
                        </mat-panel-title>
                        <mat-panel-description *ngIf="!expansions.one">
                            ស្ថិតិចេញ-ចូល, បោះពុម្ព QR កូដ, របាយការណ៍
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <ul class="navigation-menu" style="padding: 0;">

                        <li class="navigation-menu-item">
                            <a class="m-content" [routerLink]="['/reports/passenger-listing']"
                                routerLinkActive="active">
                                <mat-icon class="mat-18">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                        <path fill="none" d="M0 0h24v24H0z" />
                                        <path
                                            d="M21 19h2v2H1v-2h2V4a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v15h4v-8h-2V9h3a1 1 0 0 1 1 1v9zM5 5v14h8V5H5zm2 6h4v2H7v-2zm0-4h4v2H7V7z" />
                                    </svg>
                                </mat-icon>
                                <div class="m-text">
                                    បញ្ជីអ្នកបើកបរនិង អ្នករួមដំណើរ (ខ្មែរ)
                                </div>
                            </a>
                        </li>

                        <li class="navigation-menu-item">
                            <a class="m-content" [routerLink]="['/reports/travel-in-out']" routerLinkActive="active">
                                <mat-icon class="mat-18">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                        <path fill="none" d="M0 0h24v24H0z" />
                                        <path
                                            d="M3 3h18a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm17 8H4v8h16v-8zm0-2V5H4v4h16zm-6 6h4v2h-4v-2z" />
                                    </svg>
                                </mat-icon>
                                <div class="m-text">
                                    ស្ថិតិមធ្យោបាយអ្នកបើកបរ និងអ្នករួមដំណើរ (ខ្មែរ) ចេញ-ចូល
                                </div>
                            </a>
                        </li>

                        <li class="navigation-menu-item">
                            <a class="m-content" [routerLink]="['/reports/travel-in-out-report1']"
                                routerLinkActive="active">
                                <mat-icon class="mat-18">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                        <path fill="none" d="M0 0h24v24H0z" />
                                        <path
                                            d="M3 3h18a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm17 8H4v8h16v-8zm0-2V5H4v4h16zm-6 6h4v2h-4v-2z" />
                                    </svg>
                                </mat-icon>
                                <div class="m-text">
                                    ស្ថិតិមធ្យោបាយអ្នកបើកបរ និងអ្នករួមដំណើរ (ខ្មែរ) ចេញ-ចូលតាមថ្ងៃ
                                </div>
                            </a>
                        </li>

                        <li class="navigation-menu-item">
                            <a class="m-content" [routerLink]="['/reports/travel-in-out-report2']"
                                routerLinkActive="active">
                                <mat-icon class="mat-18">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                        <path fill="none" d="M0 0h24v24H0z" />
                                        <path
                                            d="M3 3h18a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm17 8H4v8h16v-8zm0-2V5H4v4h16zm-6 6h4v2h-4v-2z" />
                                    </svg>
                                </mat-icon>
                                <div class="m-text">
                                    ស្ថិតិមធ្យោបាយអ្នកបើកបរ និងអ្នករួមដំណើរ (ខ្មែរ) ចេញ-ចូលតាមខែ
                                </div>
                            </a>
                        </li>

                        <!-- <li class="navigation-menu-item">
                            <a class="m-content" [routerLink]="['/reports/passenger-traveler-listing']"
                                routerLinkActive="active">
                                <mat-icon class="mat-18">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                        <path fill="none" d="M0 0h24v24H0z" />
                                        <path
                                            d="M21 19h2v2H1v-2h2V4a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v15h4v-8h-2V9h3a1 1 0 0 1 1 1v9zM5 5v14h8V5H5zm2 6h4v2H7v-2zm0-4h4v2H7V7z" />
                                    </svg>
                                </mat-icon>
                                <div class="m-text">
                                    បញ្ជីអ្នកបើកបរនិង អ្នករួមដំណើរ
                                </div>
                            </a>
                        </li> -->


                        <li class="navigation-menu-item">
                            <a class="m-content" [routerLink]="['/reports/print-qr-code']" routerLinkActive="active">
                                <mat-icon class="mat-18">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                        <path fill="none" d="M0 0h24v24H0z" />
                                        <path
                                            d="M2 5l7-3 6 3 6.303-2.701a.5.5 0 0 1 .697.46V19l-7 3-6-3-6.303 2.701a.5.5 0 0 1-.697-.46V5zm14 14.395l4-1.714V5.033l-4 1.714v12.648zm-2-.131V6.736l-4-2v12.528l4 2zm-6-2.011V4.605L4 6.319v12.648l4-1.714z" />
                                    </svg>
                                </mat-icon>
                                <div class="m-text">
                                    បោះពុម្ព QR កូដ (អ្នកដំណើរខ្មែរ)
                                </div>
                            </a>
                        </li>

                    </ul>
                </mat-expansion-panel>

                <!-- <mat-expansion-panel [expanded]="expansions.three" (closed)="onClosed(3)" (opened)="onOpened(3)">
                    <mat-expansion-panel-header collapsedHeight="78" expandedHeight="78"
                        [class.active]="expansions.three">
                        <mat-panel-title>
                            <strong>អ្នកបានធ្វើតេស្តរហ័ស (ខ្មែរ)</strong>
                        </mat-panel-title>
                        <mat-panel-description *ngIf="!expansions.three">
                            ស្ថិតិតេស្តរហ័ស, បញ្ជីត្រូវស្រាវជ្រាវ, របាយការណ៍
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <ul class="navigation-menu" style="padding: 0;">

                        <li class="navigation-menu-item">
                            <a class="m-content" [routerLink]="['/reports/report-test-covid-totay']"
                                routerLinkActive="active">
                                <mat-icon class="mat-18">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                        <path fill="none" d="M0 0h24v24H0z" />
                                        <path
                                            d="M21 19h2v2H1v-2h2V4a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v15h4v-8h-2V9h3a1 1 0 0 1 1 1v9zM5 5v14h8V5H5zm2 6h4v2H7v-2zm0-4h4v2H7V7z" />
                                    </svg>
                                </mat-icon>
                                <div class="m-text">
                                    បញ្ជីរអ្នកបានធ្វើតេស្តរហ័ស
                                    <br />
                                    ប្រចាំថ្ងៃ
                                </div>
                            </a>
                        </li>

                        <li class="navigation-menu-item">
                            <a class="m-content" [routerLink]="['/reports/report-test-covid-need-research-totay']"
                                routerLinkActive="active">
                                <mat-icon class="mat-18">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                        <path fill="none" d="M0 0h24v24H0z" />
                                        <path
                                            d="M21 19h2v2H1v-2h2V4a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v15h4v-8h-2V9h3a1 1 0 0 1 1 1v9zM5 5v14h8V5H5zm2 6h4v2H7v-2zm0-4h4v2H7V7z" />
                                    </svg>
                                </mat-icon>
                                <div class="m-text">
                                    បញ្ជីត្រូវស្រាវជ្រាវ
                                    <br />
                                    ក្រោយ៧២ម៉ោង
                                </div>
                            </a>
                        </li>

                        <li class="navigation-menu-item">
                            <a class="m-content" [routerLink]="['/reports/report-test-covid-result-research-totay']"
                                routerLinkActive="active">
                                <mat-icon class="mat-18">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                        <path fill="none" d="M0 0h24v24H0z" />
                                        <path
                                            d="M21 19h2v2H1v-2h2V4a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v15h4v-8h-2V9h3a1 1 0 0 1 1 1v9zM5 5v14h8V5H5zm2 6h4v2H7v-2zm0-4h4v2H7V7z" />
                                    </svg>
                                </mat-icon>
                                <div class="m-text">
                                    បញ្ជីលទ្ធផលស្រាវជ្រាវ
                                    <br />
                                    ប្រចាំថ្ងៃ
                                </div>
                            </a>
                        </li>

                        <li class="navigation-menu-item">
                            <a class="m-content" [routerLink]="['/reports/report-test-covid-research-left']"
                                routerLinkActive="active">
                                <mat-icon class="mat-18">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                        <path fill="none" d="M0 0h24v24H0z" />
                                        <path
                                            d="M21 19h2v2H1v-2h2V4a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v15h4v-8h-2V9h3a1 1 0 0 1 1 1v9zM5 5v14h8V5H5zm2 6h4v2H7v-2zm0-4h4v2H7V7z" />
                                    </svg>
                                </mat-icon>
                                <div class="m-text">
                                    បញ្ជីត្រូវស្រាវជ្រាវ
                                    <br />
                                    សេសសល់
                                </div>
                            </a>
                        </li>

                    </ul>
                </mat-expansion-panel> -->

                <mat-expansion-panel [expanded]="expansions.two" (closed)="onClosed(2)" (opened)="onOpened(2)">
                    <mat-expansion-panel-header collapsedHeight="78" expandedHeight="78"
                        [class.active]="expansions.two">
                        <mat-panel-title>
                            <strong>អ្នកបើកបរ និងអ្នករួមដំណើរ (ជនបរទេស)</strong>
                        </mat-panel-title>
                        <mat-panel-description *ngIf="!expansions.two">
                            ស្ថិតិចេញ-ចូល, បោះពុម្ព QR កូដ, របាយការណ៍
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <ul class="navigation-menu" style="padding: 0;">

                        <li class="navigation-menu-item">
                            <a class="m-content" [routerLink]="['/reports/thai-passenger-listing']"
                                routerLinkActive="active">
                                <mat-icon class="mat-18">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                        <path fill="none" d="M0 0h24v24H0z" />
                                        <path
                                            d="M21 19h2v2H1v-2h2V4a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v15h4v-8h-2V9h3a1 1 0 0 1 1 1v9zM5 5v14h8V5H5zm2 6h4v2H7v-2zm0-4h4v2H7V7z" />
                                    </svg>
                                </mat-icon>
                                <div class="m-text">
                                    បញ្ជីអ្នកបើកបរនិង អ្នករួមដំណើរ (ជនបរទេស)
                                </div>
                            </a>
                        </li>

                        <li class="navigation-menu-item">
                            <a class="m-content" [routerLink]="['/reports/thai-travel-in-out']"
                                routerLinkActive="active">
                                <mat-icon class="mat-18">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                        <path fill="none" d="M0 0h24v24H0z" />
                                        <path
                                            d="M3 3h18a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm17 8H4v8h16v-8zm0-2V5H4v4h16zm-6 6h4v2h-4v-2z" />
                                    </svg>
                                </mat-icon>
                                <div class="m-text">
                                    ស្ថិតិមធ្យោបាយអ្នកបើកបរ និងអ្នករួមដំណើរ (ជនបរទេស) ចូល-ចេញ
                                </div>
                            </a>
                        </li>

                        <li class="navigation-menu-item">
                            <a class="m-content" [routerLink]="['/reports/thai-travel-in-out-report1']"
                                routerLinkActive="active">
                                <mat-icon class="mat-18">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                        <path fill="none" d="M0 0h24v24H0z" />
                                        <path
                                            d="M3 3h18a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm17 8H4v8h16v-8zm0-2V5H4v4h16zm-6 6h4v2h-4v-2z" />
                                    </svg>
                                </mat-icon>
                                <div class="m-text">
                                    ស្ថិតិមធ្យោបាយអ្នកបើកបរ និងអ្នករួមដំណើរ (ជនបរទេស) ចូល-ចេញតាមថ្ងៃ
                                </div>
                            </a>
                        </li>

                        <li class="navigation-menu-item">
                            <a class="m-content" [routerLink]="['/reports/thai-travel-in-out-report2']"
                                routerLinkActive="active">
                                <mat-icon class="mat-18">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                        <path fill="none" d="M0 0h24v24H0z" />
                                        <path
                                            d="M3 3h18a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm17 8H4v8h16v-8zm0-2V5H4v4h16zm-6 6h4v2h-4v-2z" />
                                    </svg>
                                </mat-icon>
                                <div class="m-text">
                                    ស្ថិតិមធ្យោបាយអ្នកបើកបរ និងអ្នករួមដំណើរ (ជនបរទេស) ចូល-ចេញតាមខែ
                                </div>
                            </a>
                        </li>


                        <li class="navigation-menu-item">
                            <a class="m-content" [routerLink]="['/reports/thai-print-qr-code']"
                                routerLinkActive="active">
                                <mat-icon class="mat-18">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                        <path fill="none" d="M0 0h24v24H0z" />
                                        <path
                                            d="M2 5l7-3 6 3 6.303-2.701a.5.5 0 0 1 .697.46V19l-7 3-6-3-6.303 2.701a.5.5 0 0 1-.697-.46V5zm14 14.395l4-1.714V5.033l-4 1.714v12.648zm-2-.131V6.736l-4-2v12.528l4 2zm-6-2.011V4.605L4 6.319v12.648l4-1.714z" />
                                    </svg>
                                </mat-icon>
                                <div class="m-text">
                                    បោះពុម្ព QR កូដ (អ្នកដំណើរជនបរទេស)
                                </div>
                            </a>
                        </li>

                    </ul>
                </mat-expansion-panel>

                <mat-expansion-panel [expanded]="expansions.four" (closed)="onClosed(4)" (opened)="onOpened(4)">
                    <mat-expansion-panel-header collapsedHeight="78" expandedHeight="78"
                        [class.active]="expansions.four">
                        <mat-panel-title>
                            <strong>បញ្ជីអ្នកមានប័ណ្ណចាក់វ៉ាក់សាំង</strong>
                        </mat-panel-title>
                        <mat-panel-description *ngIf="!expansions.four">
                            ស្ថិតិចូល, របាយការណ៍, ស្វែងរក
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <ul class="navigation-menu" style="padding: 0;">

                        <li class="navigation-menu-item">
                            <a class="m-content" [routerLink]="['/reports/foreigner-traveler-listing']"
                                routerLinkActive="active">
                                <mat-icon class="mat-18">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                        <path fill="none" d="M0 0h24v24H0z" />
                                        <path
                                            d="M21 19h2v2H1v-2h2V4a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v15h4v-8h-2V9h3a1 1 0 0 1 1 1v9zM5 5v14h8V5H5zm2 6h4v2H7v-2zm0-4h4v2H7V7z" />
                                    </svg>
                                </mat-icon>
                                <div class="m-text">
                                    បញ្ជីអ្នកមានប័ណ្ណចាក់វ៉ាក់សាំង
                                </div>
                            </a>
                        </li>
                        <li class="navigation-menu-item">
                            <a class="m-content" [routerLink]="['/reports/search-foreigner-traveler']"
                                routerLinkActive="active">
                                <mat-icon class="mat-18">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="#000000" stroke-width="1.5" stroke-linecap="square"
                                        stroke-linejoin="arcs">
                                        <circle cx="11" cy="11" r="8"></circle>
                                        <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                                    </svg>
                                </mat-icon>
                                <div class="m-text">
                                    ស្វែងរកអ្នកមានប័ណ្ណវ៉ាក់សាំង
                                </div>
                            </a>
                        </li>

                    </ul>
                </mat-expansion-panel>

                <mat-expansion-panel [expanded]="expansions.five" (closed)="onClosed(5)" (opened)="onOpened(5)">
                    <mat-expansion-panel-header collapsedHeight="78" expandedHeight="78"
                        [class.active]="expansions.five">
                        <mat-panel-title>
                            <strong>បញ្ជីគណនីរបស់មន្ត្រី</strong>
                        </mat-panel-title>
                        <mat-panel-description *ngIf="!expansions.five">
                            ស្ថិតិចូល, របាយការណ៍
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <ul class="navigation-menu" style="padding: 0;">

                        <li class="navigation-menu-item">
                            <a class="m-content" [routerLink]="['/reports/report-user-listing']"
                                routerLinkActive="active">
                                <mat-icon class="mat-18">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                        <path fill="none" d="M0 0h24v24H0z" />
                                        <path
                                            d="M21 19h2v2H1v-2h2V4a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v15h4v-8h-2V9h3a1 1 0 0 1 1 1v9zM5 5v14h8V5H5zm2 6h4v2H7v-2zm0-4h4v2H7V7z" />
                                    </svg>
                                </mat-icon>
                                <div class="m-text">
                                    បញ្ជីគណនីរបស់មន្ត្រី
                                </div>
                            </a>
                        </li>

                    </ul>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="expansions.six" (closed)="onClosed(6)" (opened)="onOpened(6)"
                    *ngIf="isVOA">
                    <mat-expansion-panel-header collapsedHeight="78" expandedHeight="78"
                        [class.active]="expansions.six">
                        <mat-panel-title>
                            <strong>បញ្ជីអ្នកដំណើរមកដល់កម្ពុជា (VOA)</strong>
                        </mat-panel-title>
                        <mat-panel-description *ngIf="!expansions.six">
                            ស្ថិតិចូល, របាយការណ៍, ស្វែងរក
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <ul class="navigation-menu" style="padding: 0;">

                        <li class="navigation-menu-item">
                            <a class="m-content" [routerLink]="['/reports/report-voa-listing']"
                                routerLinkActive="active">
                                <mat-icon class="mat-18">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                        <path fill="none" d="M0 0h24v24H0z" />
                                        <path
                                            d="M21 19h2v2H1v-2h2V4a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v15h4v-8h-2V9h3a1 1 0 0 1 1 1v9zM5 5v14h8V5H5zm2 6h4v2H7v-2zm0-4h4v2H7V7z" />
                                    </svg>
                                </mat-icon>
                                <div class="m-text">
                                    បញ្ជីអ្នកដំណើរមកដល់កម្ពុជា (VOA)
                                </div>
                            </a>
                        </li>
                        <li class="navigation-menu-item">
                            <a class="m-content" [routerLink]="['/reports/search-voa-movements']"
                                routerLinkActive="active">
                                <mat-icon class="mat-18">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="#000000" stroke-width="1.5" stroke-linecap="square"
                                        stroke-linejoin="arcs">
                                        <circle cx="11" cy="11" r="8"></circle>
                                        <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                                    </svg>
                                </mat-icon>
                                <div class="m-text">
                                    ស្វែងរកអ្នកដំណើរមកដល់កម្ពុជា (VOA)
                                </div>
                            </a>
                        </li>

                    </ul>
                </mat-expansion-panel>

            </mat-accordion>

            <!-- <ul class="navigation-menu nav-exp" style="margin-top: 16px; margin-bottom: 16px;">

                <li class="navigation-menu-item">
                    <a class="m-content" [routerLink]="['/reports/passenger-statistic-daily']"
                        routerLinkActive="active">
                        <mat-icon class="mat-18">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                <path fill="none" d="M0 0h24v24H0z" />
                                <path
                                    d="M3 3h18a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm17 8H4v8h16v-8zm0-2V5H4v4h16zm-6 6h4v2h-4v-2z" />
                            </svg>
                        </mat-icon>
                        <div class="m-text">
                            បញ្ចូលស្ថិតិសុខភាព
                        </div>
                    </a>
                </li>

                <li class="navigation-menu-item">
                    <a class="m-content" [routerLink]="['/reports/report-one']" routerLinkActive="active">
                        <mat-icon class="mat-18">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                <path fill="none" d="M0 0h24v24H0z" />
                                <path
                                    d="M21 19h2v2H1v-2h2V4a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v15h4v-8h-2V9h3a1 1 0 0 1 1 1v9zM5 5v14h8V5H5zm2 6h4v2H7v-2zm0-4h4v2H7V7z" />
                            </svg>
                        </mat-icon>
                        <div class="m-text">
                            របាយការណ៍ប្រចាំថ្ងៃ
                        </div>
                    </a>
                </li>

                <li class="navigation-menu-item">
                    <a [routerLink]="['/reports/report-two']" routerLinkActive="active" class="m-content">
                        <mat-icon class="mat-18">

                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                <path fill="none" d="M0 0h24v24H0z" />
                                <path
                                    d="M21 19h2v2H1v-2h2V4a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v15h4v-8h-2V9h3a1 1 0 0 1 1 1v9zM5 5v14h8V5H5zm2 6h4v2H7v-2zm0-4h4v2H7V7z" />
                            </svg>
                        </mat-icon>
                        <div class="m-text">
                            បញ្ជីរាយនាមអ្នកដំណើរ
                        </div>
                    </a>
                </li>

                <li class="navigation-menu-item">
                    <a class="m-content" [routerLink]="['/reports/report-three']" routerLinkActive="active">
                        <mat-icon class="mat-18">

                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                <path fill="none" d="M0 0h24v24H0z" />
                                <path
                                    d="M21 19h2v2H1v-2h2V4a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v15h4v-8h-2V9h3a1 1 0 0 1 1 1v9zM5 5v14h8V5H5zm2 6h4v2H7v-2zm0-4h4v2H7V7z" />
                            </svg>
                        </mat-icon>
                        <div class="m-text">
                            ស្ថិតិចុះត្រួតពិនិត្យ ក្រុមហ៊ុន រោងចក្រ សហគ្រាស
                        </div>
                    </a>
                </li>

                <li class="navigation-menu-item">
                    <a class="m-content" [routerLink]="['/reports/report-four']" routerLinkActive="active">
                        <mat-icon class="mat-18">

                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                <path fill="none" d="M0 0h24v24H0z" />
                                <path
                                    d="M21 19h2v2H1v-2h2V4a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v15h4v-8h-2V9h3a1 1 0 0 1 1 1v9zM5 5v14h8V5H5zm2 6h4v2H7v-2zm0-4h4v2H7V7z" />
                            </svg>
                        </mat-icon>
                        <div class="m-text">
                            ស្ថិតិចុះត្រួតពិនិត្យ សហគ្រាស គ្រឹះស្ថាននានា
                        </div>
                    </a>
                </li>

                <li class="navigation-menu-item">
                    <a class="m-content" [routerLink]="['/reports/report-six']" routerLinkActive="active">
                        <mat-icon class="mat-18">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                <path fill="none" d="M0 0h24v24H0z" />
                                <path
                                    d="M21 19h2v2H1v-2h2V4a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v15h4v-8h-2V9h3a1 1 0 0 1 1 1v9zM5 5v14h8V5H5zm2 6h4v2H7v-2zm0-4h4v2H7V7z" />
                            </svg>
                        </mat-icon>
                        <div class="m-text">
                            ចំនួនសញ្ជាតិពលករបរទេសត្រូវថាពិន័យដូចគ្នាបានការងារ
                        </div>
                    </a>
                </li>

            </ul> -->

        </div>
    </div>
    <div class="main-content" style="height: calc(100vh - 60px); padding-right: 30px;">
        <router-outlet></router-outlet>
    </div>
    <!-- <div class="complementary">
        <chart-and-inspect></chart-and-inspect>
    </div> -->
</main>