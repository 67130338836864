import { Component, Input, OnInit } from '@angular/core';
import { toJS } from 'mobx';
import { multi_2 } from 'src/app/core/dummy/app';

@Component({
  selector: 'app-second-grouped-vertical-bar-chart',
  templateUrl: './second-grouped-vertical-bar-chart.component.html',
  styleUrls: ['./second-grouped-vertical-bar-chart.component.scss']
})
export class SecondGroupedVerticalBarChartComponent implements OnInit {
  
  @Input() secondData: any;
  multi_2: any[]=[];
  view: [number,number] = [1500, 610];

  // options
  showXAxis: boolean = true;
  showYAxis: boolean = true;
  gradient: boolean = false;
  showLegend: boolean = true;
  showXAxisLabel: boolean = true;
  // xAxisLabel: string = 'Country';
  showYAxisLabel: boolean = true;
  yAxisLabel: string = '';
  legendTitle: string = 'Years';

  colorScheme = {
    domain: ['#4f772d', '#fb8b24', '#335c67','#718355']
  };

  result=[]
  constructor() { 
    Object.assign(this, { multi_2 })
    
  }

  ngOnInit(): void {

    
    if(this.secondData && this.secondData.length){
      this.result = this.secondData.map(m=>{
        return{
          name: `${m.postName} `,
          series: m.data
        }
      })
      
    }
  }
  onSelect(data): void {
    console.log('Item clicked', JSON.parse(JSON.stringify(data)));
  }

  onActivate(data): void {
    console.log('Activate', JSON.parse(JSON.stringify(data)));
  }

  onDeactivate(data): void {
    console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }
}
