<section class="avenue-messenger">
    <div class="menu">
        <!-- <div class="items">
            <span>
                <a href="#" title="Minimize">&mdash;</a><br>
                <a href="#" title="End Chat">&#10005;</a>
            </span>
        </div> -->
        <div class="button" (click)="dialogRef.close('yes')">
            <mat-icon class="mat-18">close</mat-icon>
        </div>
    </div>
    <div class="agent-face">
        <div class="half">
            <img class="agent circle" [src]="data?.photo?.downloadUrl||NO_AVATAR" alt="Jesse Tino"></div>
    </div>
    <div class="chat">
        <div class="chat-title">
            <h1>
                {{data?.fullName}}
            </h1>
        </div>
        <div class="messages">
            <div class="messages-content">
                <div class="message new">
                    <figure class="avatar">
                        <img [src]="data?.photo?.downloadUrl||NO_AVATAR">
                    </figure>Hi there, I'm Jesse and you?
                    <div class="timestamp">10:30</div>
                    <div class="checkmark-sent-delivered">✓</div>
                    <div class="checkmark-read">✓</div>
                </div>
                <div class="message message-personal new">Hi
                    <div class="timestamp">10:35</div>
                    <div class="checkmark-sent-delivered">✓</div>
                    <div class="checkmark-read">✓</div>
                </div>
            </div>
        </div>
        <div class="message-box">
            <textarea type="text" class="message-input" placeholder="Type message..."></textarea>
            <button type="submit" class="message-submit">Send</button>
        </div>
    </div>
</section>