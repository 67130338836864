<dashboard-nav></dashboard-nav>

<main class="main-dashboard main-dashboard-a">
    <div class="navigation exp-doc">
        <div class="ac-ln-background"></div>
        <div class="sticky-content">

            <!-- <ul class="navigation-menu nav-exp" style="margin-top: 16px; margin-bottom: 16px;">
                <li class="navigation-menu-item">
                    <a class="m-content" [routerLink]="['/apps/inspections']" routerLinkActive="active">
                        <mat-icon class="mat-18">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                <path fill="none" d="M0 0h24v24H0z" />
                                <path
                                    d="M10 10.111V1l11 6v14H3V7l7 3.111zm2-5.742v8.82l-7-3.111V19h14V8.187L12 4.37z" />
                            </svg>
                        </mat-icon>
                        <div class="m-text">
                            ផែនការ
                        </div>
                    </a>
                </li>

                <li class="navigation-menu-item">
                    <a [routerLink]="['/apps/business']" routerLinkActive="active" class="m-content">
                        <mat-icon class="mat-18">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                <path fill="none" d="M0 0h24v24H0z" />
                                <path
                                    d="M21 19h2v2H1v-2h2V4a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v15h4v-8h-2V9h3a1 1 0 0 1 1 1v9zM5 5v14h8V5H5zm2 6h4v2H7v-2zm0-4h4v2H7V7z" />
                            </svg>
                        </mat-icon>
                        <div class="m-text">
                            សហគ្រាស
                        </div>
                    </a>
                </li>

                <li class="navigation-menu-item">
                    <a class="m-content" [routerLink]="['/apps/owners']" routerLinkActive="active">
                        <mat-icon class="mat-18">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                <path fill="none" d="M0 0h24v24H0z" />
                                <path
                                    d="M3.783 2.826L12 1l8.217 1.826a1 1 0 0 1 .783.976v9.987a6 6 0 0 1-2.672 4.992L12 23l-6.328-4.219A6 6 0 0 1 3 13.79V3.802a1 1 0 0 1 .783-.976zM5 4.604v9.185a4 4 0 0 0 1.781 3.328L12 20.597l5.219-3.48A4 4 0 0 0 19 13.79V4.604L12 3.05 5 4.604zM12 11a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5zm-4.473 5a4.5 4.5 0 0 1 8.946 0H7.527z" />
                            </svg>
                        </mat-icon>
                        <div class="m-text">
                            ម្ចាស់សហគ្រាស
                        </div>
                    </a>
                </li>
            </ul> -->

            <div class="sdivider" style="margin: 0px;"></div>

            <mat-accordion class="mat-nav-bar">
                <mat-expansion-panel [expanded]="expansions.one" (closed)="onClosed(1)" (opened)="onOpened(1)">
                    <mat-expansion-panel-header collapsedHeight="78" expandedHeight="78"
                        [class.active]="expansions.one">
                        <mat-panel-title>
                            <strong>រៀបចំទិន្នន័យ</strong>
                        </mat-panel-title>
                        <mat-panel-description *ngIf="!expansions.one">
                            មន្ត្រីសមត្ថកិច្ច, ប្រភេទយានជំនិះ, ទិន្នន័យខេត្ត/ក្រុង, សញ្ជាតិ
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <ul class="navigation-menu" style="padding: 0;">

                        <li class="navigation-menu-item">
                            <div class="m-content" [routerLink]="['/apps/vehicle_type']" routerLinkActive="active">
                                <mat-icon class="mat-18">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                        <path fill="none" d="M0 0h24v24H0z" />
                                        <path
                                            d="M6.5 11.5a4.5 4.5 0 1 1 0-9 4.5 4.5 0 0 1 0 9zm.5 10a4.5 4.5 0 1 1 0-9 4.5 4.5 0 0 1 0 9zm10-10a4.5 4.5 0 1 1 0-9 4.5 4.5 0 0 1 0 9zm0 10a4.5 4.5 0 1 1 0-9 4.5 4.5 0 0 1 0 9zM6.5 9.5a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5zm.5 10a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5zm10-10a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5zm0 10a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z" />
                                    </svg>
                                </mat-icon>
                                <div class="m-text">
                                    ប្រភេទយានជំនិះ
                                </div>
                            </div>
                        </li>
                        <!-- <li class="navigation-menu-item">
                            <div class="m-content" [routerLink]="['/apps/reasons']" routerLinkActive="active">
                                <mat-icon class="mat-18">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                        <path fill="none" d="M0 0h24v24H0z" />
                                        <path
                                            d="M3 3h18a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm17 8H4v8h16v-8zm0-2V5H4v4h16zm-6 6h4v2h-4v-2z" />
                                    </svg>
                                </mat-icon>
                                <div class="m-text">
                                    មូលហេតុមិនបានយកសំណាក
                                </div>
                            </div>
                        </li> -->
                        <li class="navigation-menu-item">
                            <div class="m-content" [routerLink]="['/apps/prefix-vehicles']" routerLinkActive="active">
                                <mat-icon class="mat-18">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                        <path fill="none" d="M0 0h24v24H0z" />
                                        <path
                                            d="M2 5l7-3 6 3 6.303-2.701a.5.5 0 0 1 .697.46V19l-7 3-6-3-6.303 2.701a.5.5 0 0 1-.697-.46V5zm14 14.395l4-1.714V5.033l-4 1.714v12.648zm-2-.131V6.736l-4-2v12.528l4 2zm-6-2.011V4.605L4 6.319v12.648l4-1.714z" />
                                    </svg>
                                </mat-icon>
                                <div class="m-text">
                                    ប្រភេទស្លាកលេខ
                                </div>
                            </div>
                        </li>

                        <li class="navigation-menu-item">
                            <div class="m-content" [routerLink]="['/apps/group-vehicles']" routerLinkActive="active">
                                <mat-icon class="mat-18">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                        <path fill="none" d="M0 0h24v24H0z" />
                                        <path
                                            d="M21 19h2v2H1v-2h2V4a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v15h4v-8h-2V9h3a1 1 0 0 1 1 1v9zM5 5v14h8V5H5zm2 6h4v2H7v-2zm0-4h4v2H7V7z" />
                                    </svg>
                                </mat-icon>
                                <div class="m-text">
                                    ក្រុមស្លាកលេខ
                                </div>
                            </div>
                        </li>

                        <li class="navigation-menu-item">
                            <div class="m-content" [routerLink]="['/apps/visa-types']" routerLinkActive="active">
                                <mat-icon class="mat-18">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                        <path fill="none" d="M0 0h24v24H0z" />
                                        <path
                                            d="M5 16v6H3V3h9.382a1 1 0 0 1 .894.553L14 5h6a1 1 0 0 1 1 1v11a1 1 0 0 1-1 1h-6.382a1 1 0 0 1-.894-.553L12 16H5zM5 5v9h8.236l1 2H19V7h-6.236l-1-2H5z" />
                                    </svg>
                                </mat-icon>
                                <div class="m-text">
                                    ប្រភេទទិដ្ឋាការ
                                </div>
                            </div>
                        </li>

                        <li class="navigation-menu-item">
                            <div class="m-content" [routerLink]="['/apps/user-account']" routerLinkActive="active">
                                <mat-icon class="mat-18">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                        <path fill="none" d="M0 0h24v24H0z" />
                                        <path
                                            d="M20 22h-2v-2a3 3 0 0 0-3-3H9a3 3 0 0 0-3 3v2H4v-2a5 5 0 0 1 5-5h6a5 5 0 0 1 5 5v2zm-8-9a6 6 0 1 1 0-12 6 6 0 0 1 0 12zm0-2a4 4 0 1 0 0-8 4 4 0 0 0 0 8z" />
                                    </svg>
                                </mat-icon>
                                <div class="m-text">
                                    មន្ត្រីអន្តោប្រវេសន៍
                                </div>
                            </div>
                        </li>

                        <li class="navigation-menu-item">
                            <div class="m-content" [routerLink]="['/apps/post-office']" routerLinkActive="active">
                                <mat-icon class="mat-18">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                        <path fill="none" d="M0 0h24v24H0z" />
                                        <path
                                            d="M21 19h2v2H1v-2h2V4a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v15h4v-8h-2V9h3a1 1 0 0 1 1 1v9zM5 5v14h8V5H5zm2 6h4v2H7v-2zm0-4h4v2H7V7z" />
                                    </svg>
                                </mat-icon>
                                <div class="m-text">
                                    ប៉ុស្តិ៍ច្រកទ្វារ
                                </div>
                            </div>
                        </li>

                        <!-- <li class="navigation-menu-item" *ngIf="auth?.profile?.role?.key >= 5">
                            <div class="m-content" [routerLink]="['/apps/system-option']" routerLinkActive="active">
                                <mat-icon class="mat-18">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                        <path fill="none" d="M0 0h24v24H0z" />
                                        <path
                                            d="M2 5l7-3 6 3 6.303-2.701a.5.5 0 0 1 .697.46V19l-7 3-6-3-6.303 2.701a.5.5 0 0 1-.697-.46V5zm14 14.395l4-1.714V5.033l-4 1.714v12.648zm-2-.131V6.736l-4-2v12.528l4 2zm-6-2.011V4.605L4 6.319v12.648l4-1.714z" />
                                    </svg>
                                </mat-icon>
                                <div class="m-text">
                                    លក្ខខណ្ឌប្រព័ន្ធ
                                </div>
                            </div>
                        </li> -->

                        <li class="navigation-menu-item" *ngIf="auth?.profile?.role?.key >= 5">
                            <div class="m-content" [routerLink]="['/apps/vietnam-province']" routerLinkActive="active">
                                <mat-icon class="mat-18">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                        <path fill="none" d="M0 0h24v24H0z" />
                                        <path
                                            d="M2 5l7-3 6 3 6.303-2.701a.5.5 0 0 1 .697.46V19l-7 3-6-3-6.303 2.701a.5.5 0 0 1-.697-.46V5zm14 14.395l4-1.714V5.033l-4 1.714v12.648zm-2-.131V6.736l-4-2v12.528l4 2zm-6-2.011V4.605L4 6.319v12.648l4-1.714z" />
                                    </svg>
                                </mat-icon>
                                <div class="m-text">
                                    ខេត្តវៀតណាម
                                </div>
                            </div>
                        </li>

                        <li class="navigation-menu-item" *ngIf="auth?.profile?.role?.key >= 5">
                            <div class="m-content" [routerLink]="['/apps/vietnam-district']" routerLinkActive="active">
                                <mat-icon class="mat-18">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                        <path fill="none" d="M0 0h24v24H0z" />
                                        <path
                                            d="M2 5l7-3 6 3 6.303-2.701a.5.5 0 0 1 .697.46V19l-7 3-6-3-6.303 2.701a.5.5 0 0 1-.697-.46V5zm14 14.395l4-1.714V5.033l-4 1.714v12.648zm-2-.131V6.736l-4-2v12.528l4 2zm-6-2.011V4.605L4 6.319v12.648l4-1.714z" />
                                    </svg>
                                </mat-icon>
                                <div class="m-text">
                                    ស្រុកវៀតណាម
                                </div>
                            </div>
                        </li>

                        <!-- 
                        <li class="navigation-menu-item">
                            <div class="m-content" [routerLink]="['/apps/user-role']" routerLinkActive="active">
                                <mat-icon class="mat-18">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                        <path fill="none" d="M0 0h24v24H0z" />
                                        <path
                                            d="M2 5l7-3 6 3 6.303-2.701a.5.5 0 0 1 .697.46V19l-7 3-6-3-6.303 2.701a.5.5 0 0 1-.697-.46V5zm14 14.395l4-1.714V5.033l-4 1.714v12.648zm-2-.131V6.736l-4-2v12.528l4 2zm-6-2.011V4.605L4 6.319v12.648l4-1.714z" />
                                    </svg>
                                </mat-icon>
                                <div class="m-text">
                                    តួនាទី
                                </div>
                            </div>
                        </li>
                        <li class="navigation-menu-item">
                            <div class="m-content" [routerLink]="['/apps/geo']" routerLinkActive="active">
                                <mat-icon class="mat-18">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                        <path fill="none" d="M0 0h24v24H0z" />
                                        <path
                                            d="M5 16v6H3V3h9.382a1 1 0 0 1 .894.553L14 5h6a1 1 0 0 1 1 1v11a1 1 0 0 1-1 1h-6.382a1 1 0 0 1-.894-.553L12 16H5zM5 5v9h8.236l1 2H19V7h-6.236l-1-2H5z" />
                                    </svg>
                                </mat-icon>
                                <div class="m-text">
                                    ទិន្នន័យខេត្ត/ក្រុង
                                </div>
                            </div>
                        </li>
                        <li class="navigation-menu-item">
                            <div class="m-content" [routerLink]="['/apps/nationality']" routerLinkActive="active">
                                <mat-icon class="mat-18">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                        <path fill="none" d="M0 0h24v24H0z" />
                                        <path
                                            d="M21 19h2v2H1v-2h2V4a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v15h4v-8h-2V9h3a1 1 0 0 1 1 1v9zM5 5v14h8V5H5zm2 6h4v2H7v-2zm0-4h4v2H7V7z" />
                                    </svg>
                                </mat-icon>
                                <div class="m-text">
                                    សញ្ជាតិ
                                </div>
                            </div>
                        </li>
                        <li class="navigation-menu-item">
                            <div class="m-content" [routerLink]="['/apps/visa-type']" routerLinkActive="active">
                                <mat-icon class="mat-18">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                        <path fill="none" d="M0 0h24v24H0z" />
                                        <path
                                            d="M3 3h18a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm17 8H4v8h16v-8zm0-2V5H4v4h16zm-6 6h4v2h-4v-2z" />
                                    </svg>
                                </mat-icon>
                                <div class="m-text">
                                    ប្រភេទទិដ្ឋការ
                                </div>
                            </div>
                        </li> -->
                    </ul>
                </mat-expansion-panel>
            </mat-accordion>

            <!-- <mat-accordion class="mat-nav-bar">
                <mat-expansion-panel [expanded]="expansions.two" (closed)="onClosed(2)" (opened)="onOpened(2)">
                    <mat-expansion-panel-header collapsedHeight="78" expandedHeight="78"
                        [class.active]="expansions.two">
                        <mat-panel-title>
                            <strong>របាយការណ៍</strong>
                        </mat-panel-title>
                        <mat-panel-description *ngIf="!expansions.two">
                            មន្ត្រីសមត្ថកិច្ច, ទិន្នន័យខេត្ត/ក្រុង, សញ្ជាតិ
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <ul class="navigation-menu" style="padding: 0;">
                        <li class="navigation-menu-item">
                            <div class="m-content">
                                <mat-icon class="mat-18">
                                    <img src="https://static.xx.fbcdn.net/rsrc.php/v3/yj/r/tSXYIzZlfrS.png?_nc_eui2=AeFKj_7Kpy0jnptEq60u78GvY2wdbxH-OX5jbB1vEf45fnrU5cgVmMpo8POVoktEbMB4s7YiF7SfYVSo0iy8MJrr"
                                        alt="">
                                </mat-icon>
                                <div class="m-text">
                                    មន្ត្រីសមត្ថកិច្ច
                                </div>
                            </div>
                        </li>

                        <li class="navigation-menu-item">
                            <div class="m-content">
                                <mat-icon class="mat-18">
                                    <img src="https://static.xx.fbcdn.net/rsrc.php/v3/yj/r/0gH3vbvr8Ee.png?_nc_eui2=AeEon5i6V8zh5T_FhZlq4VBxqWeiF2guI5upZ6IXaC4jm9dANZSpgPSiBLTDdwqfunScC7ImpQJmlgTjfYslfqgK"
                                        alt="">
                                </mat-icon>
                                <div class="m-text">
                                    ទិន្នន័យខេត្ត/ក្រុង
                                </div>
                            </div>
                        </li>

                        <li class="navigation-menu-item">
                            <div class="m-content">
                                <mat-icon class="mat-18">
                                    <img src="https://static.xx.fbcdn.net/rsrc.php/v3/y-/r/FhOLTyUFKwf.png?_nc_eui2=AeF6M3wQ42D3i2nk6qEEOqzXqF4NV7eRtieoXg1Xt5G2J6fU36VP8cWne8R7rdtJ6JTtFYrMf-N1j7TqbfWpsPPr"
                                        alt="">
                                </mat-icon>
                                <div class="m-text">
                                    សញ្ជាតិ
                                </div>
                            </div>
                        </li>
                    </ul>
                </mat-expansion-panel>
            </mat-accordion> -->

        </div>
    </div>
    <!-- <div class="main-content" style="height: calc(100vh - 60px);"> -->
    <div class="main-content">
        <router-outlet></router-outlet>
    </div>
    <!-- <div class="complementary">
        <chart-and-inspect></chart-and-inspect>
    </div> -->
</main>